/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React from "react";
import CardWithForm from "./CardsWithForm";
import { Input, Form, Typography, Table } from "antd";
import { matrixTableData4 } from "../data";
import { iopColumns } from "../handlers/tableColumns";
import { matrixTableStyle, StyledTable } from "../styles";

const IOPCard: React.FC = () => (
  <CardWithForm
    title="Intraocular Pressure"
    formItems={
      <>
        <Form.Item name="Method" label="IOP Method">
          <Input.TextArea rows={2} placeholder="Method" />
        </Form.Item>
      </>
    }
    additionalContent={
      <StyledTable
        columns={iopColumns.map((col) => ({
          ...col,
          onCell: () => ({
            style: { whiteSpace: "nowrap" },
          }),
        }))}
        dataSource={matrixTableData4}
        pagination={false}
        rowKey="key"
        size="small"
        css={matrixTableStyle}
      />
    }
  />
);

export default IOPCard;
