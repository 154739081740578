import { RouteObject } from "react-router-dom";
import { ROUTES } from "constants/routeConstants";
import { RouteComponents } from "./routeComponents";
import PrivateRoute from "components/PrivateRoute";

const createRoute = (path: string, Component: JSX.Element, isPrivate = false): RouteObject => ({
  path,
  element: isPrivate ? <PrivateRoute element={Component} /> : Component,
});

// Authentication Routes
const getAuthRoutes = (): RouteObject[] => [
  createRoute(ROUTES.AUTH_ROUTES.LOGIN, <RouteComponents.LoginPage />),
  createRoute(ROUTES.AUTH_ROUTES.RESET_PASSWORD, <RouteComponents.ResetPasswordPage />),
];

// Dashboard Routes
const getDashboardRoutes = (): RouteObject[] => [
  createRoute(ROUTES.DASHBOARD, <RouteComponents.Dashboard />, true),
];

// Appointment Routes
const getAppointmentRoutes = (): RouteObject[] => [
  createRoute(ROUTES.APPOINTMENT, <RouteComponents.AppointmentPage />, true),
];

// Patient Records Routes
const getPatientRecordsRoutes = (): RouteObject[] => [
  createRoute(ROUTES.PATIENT_RECORDS.BASE, <RouteComponents.PatientRecord />, true),
  createRoute(ROUTES.PATIENT_RECORDS.CREATE, <RouteComponents.PatientRecordForm />, true),
  createRoute(ROUTES.PATIENT_RECORDS.UPDATE, <RouteComponents.PatientRecordForm />, true),
  createRoute(ROUTES.PATIENT_RECORDS.DETAIL, <RouteComponents.PatientRecordDetailsPage />, true),
];

// Subscription Routes
const getSubscriptionRoutes = (): RouteObject[] => [
  createRoute(ROUTES.SUBSCRIPTION.BASE, <RouteComponents.SubscriptionPage />, true),
  createRoute(ROUTES.SUBSCRIPTION.CREATE, <RouteComponents.SubscriptionForm />, true),
  createRoute(ROUTES.SUBSCRIPTION.UPDATE, <RouteComponents.SubscriptionForm />, true),
  createRoute(ROUTES.SUBSCRIPTION.DETAILS, <RouteComponents.SubscriptionCard />, true),
];

// Consultation Routes
const getConsultationRoutes = (): RouteObject[] => [
  createRoute(ROUTES.CONSULTATION_ROUTES.BASE, <RouteComponents.ConsultationPage />, true),
  createRoute(
    ROUTES.CONSULTATION_ROUTES.DETAIL(":id"),
    <RouteComponents.ConsultationDetailsPage />,
    true
  ),
];

// Inventory Routes (Grouped Together)
const getInventoryRoutes = (): RouteObject[] => [
  // Inventory Sundry Routes
  createRoute(ROUTES.INVENTORY.SUNDRY.BASE, <RouteComponents.SundryListPage />, true),
  createRoute(ROUTES.INVENTORY.SUNDRY.DETAILS, <RouteComponents.SundryDetailsPage />, true),
  createRoute(ROUTES.INVENTORY.SUNDRY.CREATE, <RouteComponents.SundryForm />, true),
  // Inventory Frame Routes
  createRoute(ROUTES.INVENTORY.FRAME.BASE, <RouteComponents.FrameListPage />, true),
  createRoute(ROUTES.INVENTORY.FRAME.CREATE, <RouteComponents.FrameForm />, true),
  createRoute(ROUTES.INVENTORY.FRAME.DETAILS, <RouteComponents.FrameDetailsPage />, true),
  // Inventory Report Routes
  createRoute(ROUTES.INVENTORY.ADJUSTMENT_REPORT, <RouteComponents.AdjustmentReport />, true),
  createRoute(ROUTES.INVENTORY.INVOICE_REPORT, <RouteComponents.InvoiceReport />, true),
  createRoute(ROUTES.INVENTORY.FRAME_STOCK_REPORT, <RouteComponents.FrameStockReport />, true),
  createRoute(ROUTES.INVENTORY.SUNDRY_STOCK_REPORT, <RouteComponents.SundryStockReport />, true),
  createRoute(ROUTES.INVENTORY.FRAME_APPROVAL, <RouteComponents.FrameApprovalPage />, true),
  createRoute(
    ROUTES.INVENTORY.FRAME_ORDERING_FROM_USAGE,
    <RouteComponents.FrameOrderingFromUsage />,
    true
  ),
  createRoute(
    ROUTES.INVENTORY.FRAME_BARCODE_BY_PURCHASE_DATE,
    <RouteComponents.FrameBarcodeReportByPurchaseDate />,
    true
  ),
  createRoute(ROUTES.INVENTORY.LENS_BARCODE_REPORT, <RouteComponents.LensBarcodeReport />, true),
  createRoute(
    ROUTES.INVENTORY.LENS_PRICE_LIST_REPORT,
    <RouteComponents.LensPriceListReport />,
    true
  ),
  createRoute(ROUTES.INVENTORY.FRAME_STOCKTAKE, <RouteComponents.FrameStocktakePage />, true),
];

// Master Setup Routes (Grouped Together)
const getMasterSetupRoutes = (): RouteObject[] => [
  // Supplier Routes
  createRoute(ROUTES.MASTER_SETUP.SUPPLIER.BASE, <RouteComponents.SupplierListPage />, true),
  createRoute(ROUTES.MASTER_SETUP.SUPPLIER.DETAILS, <RouteComponents.SupplierDetailsPage />, true),
  createRoute(ROUTES.MASTER_SETUP.SUPPLIER.CREATE, <RouteComponents.SupplierForm />, true),
  // Payment Type Routes
  createRoute(ROUTES.MASTER_SETUP.PAYMENT_TYPE.BASE, <RouteComponents.PaymentTypeList />, true),
  // Frame Group Routes
  createRoute(ROUTES.MASTER_SETUP.FRAME_GROUP.BASE, <RouteComponents.FrameGroupList />, true),
  // Lens Code Routes
  createRoute(ROUTES.MASTER_SETUP.LENS_CODE.BASE, <RouteComponents.LensCodeSpectacleList />, true),
  createRoute(
    ROUTES.MASTER_SETUP.LENS_CODE.CREATE,
    <RouteComponents.LensCodeSpectacleForm />,
    true
  ),
  createRoute(
    ROUTES.MASTER_SETUP.LENS_CODE.DETAILS,
    <RouteComponents.LensCodeSpectacleDetails />,
    true
  ),
  createRoute(ROUTES.MASTER_SETUP.FRAME_TYPE.BASE, <RouteComponents.FrameTypeList />, true),
  createRoute(ROUTES.MASTER_SETUP.WORKING_HOURS.BASE, <RouteComponents.WorkingHoursConfig />, true),
  createRoute(
    ROUTES.MASTER_SETUP.APPOINTMENT_TYPE.BASE,
    <RouteComponents.AppointmentTypeList />,
    true
  ),
  createRoute(
    ROUTES.MASTER_SETUP.APPOINTMENT_TYPE.CREATE,
    <RouteComponents.AppointmentTypeForm />,
    true
  ),
  createRoute(
    ROUTES.MASTER_SETUP.PRACTITIONER_SETUP.BASE,
    <RouteComponents.PractitionerSetup />,
    true
  ),
];

// User Management Routes
const getUserRoutes = (): RouteObject[] => [
  createRoute(ROUTES.USER.BASE, <RouteComponents.UserList />, true),
];

// Support Routes
const getSupportRoutes = (): RouteObject[] => [
  createRoute(ROUTES.HELP_AND_SUPPORT, <RouteComponents.HelpAndSupport />, true),
  createRoute(
    ROUTES.SUPPORT.SUPPORT_TICKET_TYPE.BASE,
    <RouteComponents.SupportTicketTypeList />,
    true
  ),
];

// Financial Routes
const getFinancialRoutes = (): RouteObject[] => [
  createRoute(ROUTES.FINANCIAL_ROUTES.PETTY_CASH.BASE, <RouteComponents.PettyCashPage />, true),
  createRoute(ROUTES.FINANCIAL_ROUTES.BANKING.BASE, <RouteComponents.BankingPage />, true),
  createRoute(
    ROUTES.FINANCIAL_ROUTES.BULK_BILLING_ACCOUNTS.BASE,
    <RouteComponents.BulkBillingAccountsPage />,
    true
  ),
  createRoute(
    ROUTES.FINANCIAL_ROUTES.BULK_BILLING_PAYMENT.BASE,
    <RouteComponents.BulkBillingPaymentPage />,
    true
  ),
  createRoute(
    ROUTES.FINANCIAL_ROUTES.BULK_BILLING_PAYMENT.DETAILS,
    <RouteComponents.BulkBillingPaymentFormPage />,
    true
  ),
  createRoute(
    ROUTES.FINANCIAL_ROUTES.OUTSTANDING_BULK_BILLING_ACCOUNTS.BASE,
    <RouteComponents.OutstandingBulkBillingAccounts />,
    true
  ),
  createRoute(
    ROUTES.FINANCIAL_ROUTES.SUPPLIER_INVOICE_CREDIT_REPORT.BASE,
    <RouteComponents.SupplierInvoiceCreditReport />,
    true
  ),
  createRoute(
    ROUTES.FINANCIAL_ROUTES.BANKING_RECONCILIATION.BASE,
    <RouteComponents.BankReconciliation />,
    true
  ),
  createRoute(
    ROUTES.FINANCIAL_ROUTES.BILLING_SUMMARY_YEARLY_GRAPH.BASE,
    <RouteComponents.BillingSummary />,
    true
  ),
  createRoute(
    ROUTES.FINANCIAL_ROUTES.SSF_BILLING_ITEM_SUMMARY.BASE,
    <RouteComponents.SSFBillingItemSummary />,
    true
  ),
  createRoute(
    ROUTES.FINANCIAL_ROUTES.BILLING_SEARCH_BY_ITEM_NUMBER.BASE,
    <RouteComponents.BillingSearchByItemNumber />,
    true
  ),
  createRoute(
    ROUTES.FINANCIAL_ROUTES.PAYMENT_SUMMARY.BASE,
    <RouteComponents.PaymentSummaryPage />,
    true
  ),
];

export const routeConfigs = {
  authRoutes: getAuthRoutes(),
  dashboardRoutes: getDashboardRoutes(),
  appointmentRoutes: getAppointmentRoutes(),
  patientRecordsRoutes: getPatientRecordsRoutes(),
  subscriptionRoutes: getSubscriptionRoutes(),
  consultationRoutes: getConsultationRoutes(),
  inventoryRoutes: getInventoryRoutes(),
  masterSetupRoutes: getMasterSetupRoutes(),
  userRoutes: getUserRoutes(),
  supportRoutes: getSupportRoutes(),
  financialRoutes: getFinancialRoutes(),
};
