/** @jsxImportSource @emotion/react */
import React from "react";
import { Form } from "antd";
import { useLogin } from "services/AuthService/useLogin";
import LoginForm from "./LoginForm";
import AuthPageLayout from "components/AuthLayout";

const LoginPage: React.FC = () => {
  const [form] = Form.useForm();
  const loginMutation = useLogin();

  const handleSubmit = async () => {
    const loginPayload = await form.validateFields();
    loginMutation.mutate(loginPayload);
  };

  return (
    <AuthPageLayout
      title="Login"
      description="Welcome Back, Please login to your account."
      form={
        <LoginForm form={form} isSubmitting={loginMutation.isPending} onFinish={handleSubmit} />
      }
    />
  );
};

export default LoginPage;
