import { coreClient } from "utils/httpClient";
import { API_ENDPOINTS } from "constants/apiEndpoints";
import { PaymentTypeResponeInterface, PaymentTypeInterface } from "./types";

const apiClient = coreClient;

export const paymentTypeApi = {
  getAll: async (): Promise<PaymentTypeResponeInterface> => {
    const response = await apiClient.get<PaymentTypeResponeInterface>(
      API_ENDPOINTS.PAYMENT_TYPE.GET_ALL
    );
    return response.data;
  },

  getById: async (id: string): Promise<PaymentTypeInterface> => {
    const response = await apiClient.get<{
      success: boolean;
      data: PaymentTypeInterface;
      message?: string;
    }>(API_ENDPOINTS.PAYMENT_TYPE.READ(id));
    return response.data.data;
  },

  create: async (paymentTypeData: PaymentTypeInterface): Promise<PaymentTypeInterface> => {
    const response = await apiClient.post<PaymentTypeInterface>(
      API_ENDPOINTS.PAYMENT_TYPE.CREATE,
      paymentTypeData
    );
    return response.data;
  },

  update: async (
    id: string,
    paymentTypeData: PaymentTypeInterface
  ): Promise<PaymentTypeInterface> => {
    const response = await apiClient.put<PaymentTypeInterface>(
      API_ENDPOINTS.PAYMENT_TYPE.UPDATE(id),
      paymentTypeData
    );
    return response.data;
  },

  delete: async (id: string): Promise<void> => {
    await apiClient.delete<void>(API_ENDPOINTS.PAYMENT_TYPE.DELETE(id));
  },
};
