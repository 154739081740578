import React from "react";
import { useNavigate } from "react-router-dom";
import { Table, Button, Dropdown, Menu, Row, Card } from "antd";
import {
  EyeOutlined,
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import PageTemplate from "components/PageTemplate";
import { ROUTES } from "constants/routeConstants";
import useConfirmDelete from "hooks/useConfirmDelete";
import { useLensCodeService } from "services/LensCodeService";

const LensCodeSpectacleListPage = () => {
  const navigate = useNavigate();
  const { useGetAll, useDelete } = useLensCodeService();
  // const { data: lensCodeData, isLoading, refetch } = useGetAll();
  const { mutateAsync: deleteLensCode, isPending } = useDelete();
  const { confirmDelete } = useConfirmDelete(deleteLensCode, isPending);

  const data = [
    {
      key: "1",
      lensCode: "LC001",
      description: "Single Vision Lens",
      size: "55-18-140",
      segment: "Bifocal",
      rrp: "$120",
      supplier: "Supplier A",
      group: "Group 1",
    },
    {
      key: "2",
      lensCode: "LC002",
      description: "Progressive Lens",
      size: "58-17-145",
      segment: "Multifocal",
      rrp: "$250",
      supplier: "Supplier B",
      group: "Group 2",
    },
  ];

  const handleMenuClick = (key: string, record: any) => {
    switch (key) {
      case "view":
        navigate(`/master-setup/lens-code/details/${record?.key}`);
        break;
      case "update":
        navigate(`/master-setup/lens-code/update/${record?.key}`);
        break;
      case "delete":
        record?.key && confirmDelete(record?.key);
        break;
      default:
        break;
    }
  };

  const actionMenu = (record: any) => (
    <Menu>
      <Menu.Item key="view" icon={<EyeOutlined />} onClick={() => handleMenuClick("view", record)}>
        View details
      </Menu.Item>
      <Menu.Item
        key="update"
        icon={<EditOutlined />}
        onClick={() => handleMenuClick("update", record)}
      >
        Update lens code
      </Menu.Item>
      <Menu.Item
        key="delete"
        icon={<DeleteOutlined />}
        onClick={() => handleMenuClick("delete", record)}
        danger
      >
        Delete lens code
      </Menu.Item>
    </Menu>
  );

  const columns = [
    {
      title: "SN",
      dataIndex: "key",
      render: (text: string) => <span>{text}</span>,
    },
    {
      title: "Lens Code",
      dataIndex: "lensCode",
    },
    {
      title: "Description",
      dataIndex: "description",
    },
    {
      title: "Size",
      dataIndex: "size",
    },
    {
      title: "Segment",
      dataIndex: "segment",
    },
    {
      title: "RRP $",
      dataIndex: "rrp",
    },
    {
      title: "Supplier",
      dataIndex: "supplier",
    },
    {
      title: "Group",
      dataIndex: "group",
    },
    {
      title: "Action",
      render: (text: any, record: any) => (
        <Dropdown overlay={actionMenu(record)} trigger={["click"]}>
          <Button icon={<MoreOutlined />} />
        </Dropdown>
      ),
    },
  ];

  return (
    <PageTemplate>
      <Card
        style={{
          boxShadow: "0px 8px 40px -10px #00000014",
          borderRadius: "4px",
        }}
      >
        <Row justify="end">
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => navigate(ROUTES.MASTER_SETUP.LENS_CODE.CREATE)}
          >
            Add Lens Code
          </Button>
        </Row>
        <div style={{ marginTop: "18px" }}>
          <Table columns={columns} dataSource={data} />
        </div>
      </Card>
    </PageTemplate>
  );
};

export default LensCodeSpectacleListPage;
