import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  DatePicker,
  Select,
  Checkbox,
  Button,
  Table,
  Row,
  Col,
  Card,
  Typography,
  Divider,
  Space,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import { StyledForm } from "../styles";
import dayjs, { Dayjs } from "dayjs";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";

const { Title } = Typography;
const { Option } = Select;

interface SpectacleDetails {
  jobDate: Dayjs;
  specDueDate: Dayjs;
  dispenser: string;
  jobType: string;
  rxByDate: Dayjs;
  po: string;
  category: string;
  status: string;
  saleBy: string;
  rxBy: string;
  monoR: string;
  monoL: string;
  labDue: Dayjs;
  labToSendSpectacles: boolean;
  frameCode: string;
  framePrice: number;
  fittingPrice: number;
  discount: number;
  frameSize: string;
  depth: string;
  ed: string;
  type: string;
  shapeNo: string;
  other: string;
  reorderNote: string;
  extraLenses: string;
  labToApply: string;
  lensPrice: number;
  others: string;
  fittingType: string;
  fittingTypeBy: string;
  othersLensPrice: number;
  discountReason: string;
  lensDiscount: number;
  overallDiscount: number;
  totalPrice: number;
  gstPercentage: number;
  gstAmount: number;
  jobReady: string;
  jobReadyBy: string;
  collectedDate: Dayjs;
  collectedBy: string;
  followUpDate: Dayjs;
  followUpBy: string;
  lastNotified: Dayjs;
  notificationsSent: number;
  comments: string;
  labInstructions: string;
  notificationDetails: string;
}

// Sample data associated with each date
const spectacleDetailsData: Record<string, SpectacleDetails> = {
  "2024-10-01": {
    jobDate: dayjs("2024-10-01"),
    specDueDate: dayjs("2024-10-05"),
    dispenser: "Dispenser A",
    jobType: "Type A",
    rxByDate: dayjs("2024-10-02"),
    po: "PO1234",
    category: "Single Vision",
    status: "Active",
    saleBy: "Salesperson X",
    rxBy: "Dr. Smith",
    monoR: "1.00",
    monoL: "0.75",
    labDue: dayjs("2024-10-10"),
    labToSendSpectacles: true,
    frameCode: "Frame 101",
    framePrice: 100,
    fittingPrice: 50,
    discount: 10,
    frameSize: "Medium",
    depth: "12",
    ed: "15",
    type: "Regular",
    shapeNo: "SN-123",
    other: "N/A",
    reorderNote: "Reorder once stock is low",
    extraLenses: "N/A",
    labToApply: "Apply standard coating",
    lensPrice: 80,
    others: "N/A",
    fittingType: "Standard",
    fittingTypeBy: "Technician A",
    othersLensPrice: 40,
    discountReason: "Seasonal discount",
    lensDiscount: 5,
    overallDiscount: 15,
    totalPrice: 240,
    gstPercentage: 18,
    gstAmount: 43.2,
    jobReady: "Yes",
    jobReadyBy: "Technician A",
    collectedDate: dayjs("2024-10-03"),
    collectedBy: "Customer X",
    followUpDate: dayjs("2024-10-10"),
    followUpBy: "Salesperson Y",
    lastNotified: dayjs("2024-10-02"),
    notificationsSent: 1,
    comments: "First order",
    labInstructions: "Handle with care",
    notificationDetails: "sms",
  },
  "2024-10-10": {
    jobDate: dayjs("2024-10-10"),
    specDueDate: dayjs("2024-10-15"),
    dispenser: "Dispenser B",
    jobType: "Type B",
    rxByDate: dayjs("2024-10-12"),
    po: "PO5678",
    category: "Bifocal",
    status: "Inactive",
    saleBy: "Salesperson Y",
    rxBy: "Dr. Jones",
    monoR: "0.50",
    monoL: "0.75",
    labDue: dayjs("2024-10-18"),
    labToSendSpectacles: false,
    frameCode: "Frame 102",
    framePrice: 120,
    fittingPrice: 60,
    discount: 15,
    frameSize: "Large",
    depth: "13",
    ed: "16",
    type: "Premium",
    shapeNo: "SN-124",
    other: "N/A",
    reorderNote: "Reorder as needed",
    extraLenses: "Tinted lenses",
    labToApply: "Apply anti-glare coating",
    lensPrice: 100,
    others: "N/A",
    fittingType: "Premium",
    fittingTypeBy: "Technician B",
    othersLensPrice: 50,
    discountReason: "Holiday sale",
    lensDiscount: 10,
    overallDiscount: 20,
    totalPrice: 270,
    gstPercentage: 18,
    gstAmount: 48.6,
    jobReady: "No",
    jobReadyBy: "Technician B",
    collectedDate: dayjs("2024-10-14"),
    collectedBy: "Customer Y",
    followUpDate: dayjs("2024-10-20"),
    followUpBy: "Salesperson Z",
    lastNotified: dayjs("2024-10-10"),
    notificationsSent: 2,
    comments: "Urgent order",
    labInstructions: "Ensure proper fitting",
    notificationDetails: "email",
  },
  // You can add more sample data here following the same format
};

const SpectacleDetailsPage = () => {
  const [form] = Form.useForm();
  const [selectedDate, setSelectedDate] = useState<Dayjs | undefined>(undefined);
  const [hasNavigated, setHasNavigated] = useState(false);

  const columns = [
    {
      title: "Rx",
      dataIndex: "rx",
      render: (text: string) => text,
    },

    { title: "Sphere", dataIndex: "sphere", render: () => <Input /> },
    { title: "Cyl", dataIndex: "cyl", render: () => <Input /> },
    { title: "Axis", dataIndex: "axis", render: () => <Input /> },
    { title: "Add", dataIndex: "add", render: () => <Input /> },
    { title: "Inter", dataIndex: "inter", render: () => <Input /> },
    { title: "H Prism", dataIndex: "hPrism", render: () => <Input /> },
    { title: "V Prism", dataIndex: "vPrism", render: () => <Input /> },
  ];

  const rxData = [
    { key: "1", rx: "Right" },
    { key: "2", rx: "Left" },
  ]; // Placeholder data for two rows

  const handleSave = () => {
    form
      .validateFields()
      .then((values) => {
        console.log("Form Values:", values);
      })
      .catch((errorInfo) => {
        console.error("Validation Failed:", errorInfo);
      });
  };

  const columns2 = [
    { title: "Eye", dataIndex: "eye" },
    {
      title: "Lens Code",
      dataIndex: "lensCode",
      render: () => (
        <Select placeholder="Select Lens Code">
          <Option value="code1">Code 1</Option>
          <Option value="code2">Code 2</Option>
        </Select>
      ),
    },
    { title: "Lens Description", dataIndex: "lensDescription", render: () => <Input /> },
    { title: "Lens Size", dataIndex: "lensSize", render: () => <Input /> },
    { title: "Seg Size", dataIndex: "segSize", render: () => <Input /> },
    { title: "Seg Ht", dataIndex: "segHt", render: () => <Input /> },
    { title: "OC Ht", dataIndex: "ocHt", render: () => <Input /> },
    { title: "Hor Decen", dataIndex: "horDecen", render: () => <Input /> },
    { title: "Ver Decen", dataIndex: "verDecen", render: () => <Input /> },
    { title: "BC", dataIndex: "bc", render: () => <Input /> },
    {
      title: "Lens Sup",
      dataIndex: "lensSup",
      render: () => (
        <Select placeholder="Select Supplier">
          <Option value="sup1">Supplier 1</Option>
          <Option value="sup2">Supplier 2</Option>
        </Select>
      ),
    },
    {
      title: "Supplier",
      dataIndex: "supplier",
      render: () => (
        <Select placeholder="Select Supplier">
          <Option value="sup1">Supplier 1</Option>
          <Option value="sup2">Supplier 2</Option>
        </Select>
      ),
    },
    { title: "Lens Order Date", dataIndex: "lensOrderDate", render: () => <DatePicker /> },
    { title: "Lens Price", dataIndex: "lensPrice", render: () => <Input prefix="$" /> },
  ];

  const lensData = [
    { key: "1", eye: "Right Eye" },
    { key: "2", eye: "Left Eye" },
  ];

  const availableDates: Dayjs[] = Object.keys(spectacleDetailsData).map((date) =>
    dayjs(date, "YYYY-MM-DD")
  );

  const disabledDate = (current: Dayjs): boolean => {
    return !availableDates.some((date) => date.isSame(current, "day"));
  };

  const handleDateChange = (date: Dayjs | undefined) => {
    setSelectedDate(date);
    if (date) {
      const history = spectacleDetailsData[date.format("YYYY-MM-DD")];
      if (history) {
        form.setFieldsValue(history);
      } else {
        form.resetFields();
      }
    } else {
      form.resetFields();
    }
  };
  const navigateEntries = (direction: "prev" | "next") => {
    let currentIndex;

    if (!selectedDate) {
      currentIndex = availableDates.length;
    } else {
      currentIndex = availableDates.findIndex((date) => date.isSame(selectedDate, "day"));
    }

    if (direction === "prev") {
      currentIndex = Math.max(0, currentIndex - 1);
    } else if (direction === "next") {
      currentIndex = Math.min(availableDates.length - 1, currentIndex + 1);
    }

    const newDate = availableDates[currentIndex];
    setHasNavigated(true);
    setSelectedDate(newDate);
    handleDateChange(newDate);
  };

  const prevButtonDisabled =
    availableDates.length === 0 || (selectedDate && availableDates[0].isSame(selectedDate, "day"));
  const nextButtonDisabled =
    availableDates.length === 0 ||
    (selectedDate && availableDates[availableDates.length - 1].isSame(selectedDate, "day")) ||
    !hasNavigated;

  return (
    <div>
      <Card
        style={{
          boxShadow: "0px 3px 30px -10px #00000013",
          borderRadius: "3px",
          padding: "0px",
        }}
        title={
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Space>
              <Button
                onClick={() => navigateEntries("prev")}
                icon={<LeftOutlined />}
                disabled={prevButtonDisabled}
                size="small"
              />
              <DatePicker
                value={selectedDate}
                onChange={handleDateChange}
                disabledDate={disabledDate}
                size="small"
              />
              <Button
                onClick={() => navigateEntries("next")}
                icon={<RightOutlined />}
                disabled={nextButtonDisabled}
                size="small"
              />
            </Space>
          </div>
        }
      >
        <StyledForm layout="vertical" form={form} size="small">
          <Row gutter={[16, 16]}>
            <Col span={4}>
              <Form.Item label="Job Date" name="jobDate">
                <DatePicker style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="Spec Due Date" name="specDueDate">
                <DatePicker style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="Dispenser" name="dispenser">
                <Input />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="Job Type" name="jobType">
                <Input />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="Rx by Date" name="rxByDate">
                <DatePicker style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="PO" name="po">
                <Input />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="Category" name="category">
                <Select placeholder="Select Category">
                  <Option value="singleVision">Single Vision</Option>
                  <Option value="bifocal">Bifocal</Option>
                  <Option value="progressive">Progressive</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="Status" name="status">
                <Select placeholder="Select Status">
                  <Option value="active">Active</Option>
                  <Option value="inactive">Inactive</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="Sale By" name="saleBy">
                <Input />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="Rx By" name="rxBy">
                <Input />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="Mono R" name="monoR">
                <Input />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="Mono L" name="monoL">
                <Input />
              </Form.Item>
            </Col>

            <Col span={4}>
              <Form.Item label="Lab Due" name="jobDate">
                <DatePicker style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="labToSendSpectacles"
                valuePropName="checked"
                label="Lab to send spectacles to patient (Yes/No)"
              >
                <Checkbox></Checkbox>
              </Form.Item>
            </Col>
          </Row>

          <Table
            columns={columns}
            dataSource={rxData}
            pagination={false}
            bordered
            style={{ margin: "20px 0px" }}
            size="small"
          />

          <Row gutter={[16, 16]}>
            <Col span={4}>
              <Form.Item label="Frame Code" name="frameCode">
                <Select placeholder="Select Frame Code">
                  <Option value="code1">Code 1</Option>
                  <Option value="code2">Code 2</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="Frame Price $" name="framePrice">
                <Input prefix="$" />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="Fitting Price $" name="fittingPrice">
                <Input prefix="$" />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="Discount $" name="discount">
                <Input prefix="$" />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="Frame Size" name="frameSize">
                <Input />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="Depth" name="depth">
                <Input />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="ED" name="ed">
                <Input />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="Type" name="type">
                <Input />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="Shape No." name="shapeNo">
                <Input />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="Other" name="other">
                <Input />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item label="Reorder Note" name="reorderNote">
                <TextArea rows={1} />
              </Form.Item>
            </Col>
          </Row>

          <Table
            columns={columns2}
            dataSource={lensData}
            pagination={false}
            bordered
            style={{ margin: "20px 0px" }}
            size="small"
          />

          <Row gutter={[16, 16]} style={{ marginTop: "16px" }}>
            <Col span={4}>
              <Form.Item label="Extra Lenses" name="extraLenses">
                <Input />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="Lab to Apply" name="labToApply">
                <Input />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="Lens Price $" name="lensPrice">
                <Input prefix="$" />
              </Form.Item>
            </Col>

            <Col span={4}>
              <Form.Item label="Others" name="others">
                <Input />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="Fitting Type" name="fittingType">
                <Input />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="Fitting Type By" name="fittingTypeBy">
                <Input />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="Lens Price $" name="othersLensPrice">
                <Input prefix="$" />
              </Form.Item>
            </Col>

            <Col span={4}>
              <Form.Item label="Discount Reason" name="discountReason">
                <Input />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="Lens Discount $" name="lensDiscount">
                <Input prefix="$" />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="Overall Discount %" name="overallDiscount">
                <Input suffix="%" />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="Total $" name="totalPrice">
                <Input prefix="$" />
              </Form.Item>
            </Col>

            <Col span={4}>
              <Form.Item label="GST %" name="gstPercentage">
                <Input suffix="%" />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="GST Amount" name="gstAmount">
                <Input prefix="$" />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="Job Ready" name="jobReady">
                <Input />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="Job Ready By" name="jobReadyBy">
                <Input />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="Collected Date" name="collectedDate">
                <DatePicker style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="Collected By" name="collectedBy">
                <Input />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="Follow Up Date" name="followUpDate">
                <DatePicker style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="Follow Up By" name="followUpBy">
                <Input />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="Last Notified" name="lastNotified">
                <DatePicker style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="No. of Notifications Sent" name="notificationsSent">
                <Input />
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item label="Comments" name="comments">
                <TextArea rows={1} />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="Lab Instructions" name="labInstructions">
                <TextArea rows={1} />
              </Form.Item>
            </Col>

            <Col span={6}>
              <Space>
                <Form.Item label="Send Notification Through" name="notificationDetails">
                  <Select placeholder="Select Notification">
                    <Option value="sms">SMS</Option>
                    <Option value="email">Email</Option>
                  </Select>
                </Form.Item>
                <Button type="primary" style={{ marginTop: "26px" }}>
                  Notify Customer
                </Button>
              </Space>
            </Col>
          </Row>

          <Row
            style={{ display: "flex", gap: "5px", justifyContent: "flex-end", marginTop: "8px" }}
          >
            <Button type="default">Esslink</Button>

            <Button type="default">Trace Frame</Button>

            <Button type="default">To VisioOffice</Button>

            <Button type="default">From VisioOffice</Button>

            <Button type="default">Adv Progressive Param</Button>

            <Button type="default">Electronic Order</Button>

            <Button type="default">Email Log</Button>

            <Button type="primary">Save</Button>
          </Row>
        </StyledForm>
      </Card>
    </div>
  );
};

export default SpectacleDetailsPage;
