import React from "react";
import { useChangePassword } from "services/ChangePasswordService/useChangePassword";
import { ChangePasswordPayload } from "services/ChangePasswordService/types";
import MultiStepForm from "components/MultiStepForm";
import EmailStep from "./components/EmailStep";
import OtpStep from "./components/OtpStep";
import PasswordStep from "./components/PasswordStep";
import { Form } from "antd";

const ChangePasswordForm: React.FC = () => {
  const { requestOtpMutation, verifyOtpMutation, changePasswordMutation } = useChangePassword();
  const [form] = Form.useForm();

  const steps = [
    { title: "Enter Email", content: <EmailStep /> },
    { title: "Enter OTP", content: <OtpStep /> },
    { title: "Change Password", content: <PasswordStep /> },
  ];

  const handleSubmit = async (values: any) => {
    const data: ChangePasswordPayload = {
      email: values.email,
      otp: values.otp,
      oldPassword: values.oldPassword,
      password: values.password,
    };
    await changePasswordMutation.mutateAsync(data);
  };

  const handleRequestOtp = async () => {
    try {
      const values = await form.validateFields(["email"]);
      const email: string = values.email;
      await requestOtpMutation.mutateAsync(email);
    } catch (error) {}
  };

  const handleVerifyOtp = async () => {
    try {
      const values = await form.validateFields(["email", "otp"]);
      const data = {
        email: values.email,
        otp: values.otp,
        otpType: "CHANGE_PASSWORD",
      };
      await verifyOtpMutation.mutateAsync(data);
    } catch (error) {}
  };

  return (
    <MultiStepForm
      form={form}
      steps={steps}
      onSubmit={handleSubmit}
      requestOtp={handleRequestOtp}
      verifyOtp={handleVerifyOtp}
      requestLoading={requestOtpMutation.isPending}
      verifyLoading={verifyOtpMutation.isPending}
    />
  );
};

export default ChangePasswordForm;
