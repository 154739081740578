import React, { useState } from "react";
import {
  Form,
  Input,
  InputNumber,
  DatePicker,
  Select,
  Button,
  Table,
  Typography,
  Card,
  Row,
  Col,
  Descriptions,
  Space,
} from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import dayjs, { Dayjs } from "dayjs";
import { StyledForm } from "../styles";

const { TextArea } = Input;
const { Option } = Select;
const { Title } = Typography;

interface ContactLensHistory {
  jobDate: Dayjs;
  dueDate: Dayjs;
  prescriber: string;
  dispenser: string;
  rInvNo: string;
  lInvNo: string;
  rWarrantyExpiry: Dayjs;
  lWarrantyExpiry: Dayjs;
  tint: string;
  discount: number;
  totalExcTax: number;
  tax: number;
  totalIncTax: number;
  clReady: Dayjs;
  collectRLens: Dayjs;
  collectLLens: Dayjs;
  replenishDate: Dayjs;
  notificationDetails: string;
  lastNotified: Dayjs;
  notificationsSent: number;
  comments: string;
  others: string;
  cleaning: string;
  patInstru: string;
  labInstru: string;
}

// Sample data associated with each date
const contactLensData: Record<string, ContactLensHistory> = {
  "2024-10-01": {
    jobDate: dayjs("2024-10-01"),
    dueDate: dayjs("2024-10-05"),
    prescriber: "Dr. Smith",
    dispenser: "Dispenser A",
    rInvNo: "R101",
    lInvNo: "L101",
    rWarrantyExpiry: dayjs("2025-10-01"),
    lWarrantyExpiry: dayjs("2025-10-01"),
    tint: "Light Blue",
    discount: 10,
    totalExcTax: 100,
    tax: 8,
    totalIncTax: 108,
    clReady: dayjs("2024-10-02"),
    collectRLens: dayjs("2024-10-03"),
    collectLLens: dayjs("2024-10-04"),
    replenishDate: dayjs("2025-10-01"),
    notificationDetails: "sms",
    lastNotified: dayjs("2024-10-01"),
    notificationsSent: 1,
    comments: "First order",
    others: "N/A",
    cleaning: "Daily cleaning required",
    patInstru: "Handle with care",
    labInstru: "Use standard procedure",
  },
  "2024-10-10": {
    jobDate: dayjs("2024-10-10"),
    dueDate: dayjs("2024-10-15"),
    prescriber: "Dr. Jones",
    dispenser: "Dispenser B",
    rInvNo: "R102",
    lInvNo: "L102",
    rWarrantyExpiry: dayjs("2025-10-10"),
    lWarrantyExpiry: dayjs("2025-10-10"),
    tint: "Clear",
    discount: 15,
    totalExcTax: 150,
    tax: 12,
    totalIncTax: 162,
    clReady: dayjs("2024-10-11"),
    collectRLens: dayjs("2024-10-12"),
    collectLLens: dayjs("2024-10-13"),
    replenishDate: dayjs("2025-10-10"),
    notificationDetails: "email",
    lastNotified: dayjs("2024-10-10"),
    notificationsSent: 2,
    comments: "Urgent order",
    others: "Check prescription",
    cleaning: "Weekly cleaning required",
    patInstru: "Avoid direct sunlight",
    labInstru: "Use soft lens cleaner",
  },
};

const ContactLensPage = () => {
  const [form] = Form.useForm();
  const [selectedDate, setSelectedDate] = useState<Dayjs | undefined>(undefined);
  const [hasNavigated, setHasNavigated] = useState(false);

  const columns = [
    { title: "CL", dataIndex: "cl", render: (text: any) => text },
    {
      title: "CL Code",
      dataIndex: "clCode",
      render: () => (
        <Select defaultValue="code1">
          <Option value="code1">Code 1</Option>
          <Option value="code2">Code 2</Option>
        </Select>
      ),
    },
    { title: "Sup", dataIndex: "sup", render: () => <Input /> },
    { title: "BCOR", dataIndex: "bcor", render: () => <Input /> },
    { title: "OAD", dataIndex: "oad", render: () => <Input /> },
    { title: "Sphere", dataIndex: "sphere", render: () => <Input /> },
    { title: "Cyl", dataIndex: "cyl", render: () => <Input /> },
    { title: "Axis", dataIndex: "axis", render: () => <Input /> },
    { title: "BCOD", dataIndex: "bcod", render: () => <Input /> },
    { title: "Qty", dataIndex: "qty", render: () => <InputNumber min={1} /> },
    { title: "Periph", dataIndex: "periph", render: () => <Input /> },
    {
      title: "Status",
      dataIndex: "status",
      render: () => (
        <Select defaultValue="active">
          <Option value="active">Active</Option>
          <Option value="inactive">Inactive</Option>
        </Select>
      ),
    },
    { title: "Sup Order Date", dataIndex: "supOrderDate", render: () => <DatePicker /> },
    { title: "Price$", dataIndex: "price", render: () => <InputNumber min={0} /> },
  ];

  // Data source for the contact lens table
  const eyeData = [
    { key: "1", cl: "Right" },
    { key: "2", cl: "Left" },
  ];

  const handleAddToBill = () => {
    form
      .validateFields()
      .then((values) => {
        console.log("Form Values:", values);
      })
      .catch((errorInfo) => {
        console.error("Validation Failed:", errorInfo);
      });
  };

  const availableDates: Dayjs[] = Object.keys(contactLensData).map((date) =>
    dayjs(date, "YYYY-MM-DD")
  );

  const disabledDate = (current: Dayjs): boolean => {
    return !availableDates.some((date) => date.isSame(current, "day"));
  };

  const handleDateChange = (date: Dayjs | undefined) => {
    setSelectedDate(date);
    if (date) {
      const history = contactLensData[date.format("YYYY-MM-DD")];
      if (history) {
        form.setFieldsValue(history);
      } else {
        form.resetFields();
      }
    } else {
      form.resetFields();
    }
  };

  const navigateEntries = (direction: "prev" | "next") => {
    let currentIndex;

    if (!selectedDate) {
      currentIndex = availableDates.length;
    } else {
      currentIndex = availableDates.findIndex((date) => date.isSame(selectedDate, "day"));
    }

    if (direction === "prev") {
      currentIndex = Math.max(0, currentIndex - 1);
    } else if (direction === "next") {
      currentIndex = Math.min(availableDates.length - 1, currentIndex + 1);
    }

    const newDate = availableDates[currentIndex];
    setHasNavigated(true);
    setSelectedDate(newDate);
    handleDateChange(newDate);
  };

  const prevButtonDisabled =
    availableDates.length === 0 || (selectedDate && availableDates[0].isSame(selectedDate, "day"));
  const nextButtonDisabled =
    availableDates.length === 0 ||
    (selectedDate && availableDates[availableDates.length - 1].isSame(selectedDate, "day")) ||
    !hasNavigated;

  return (
    <Card
      style={{
        boxShadow: "0px 8px 40px -10px #00000014",
        borderRadius: "4px",
      }}
      title={
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Space>
            <Button
              onClick={() => navigateEntries("prev")}
              icon={<LeftOutlined />}
              disabled={prevButtonDisabled}
              size="small"
            />
            <DatePicker
              value={selectedDate}
              onChange={handleDateChange}
              disabledDate={disabledDate}
              size="small"
            />
            <Button
              onClick={() => navigateEntries("next")}
              icon={<RightOutlined />}
              disabled={nextButtonDisabled}
              size="small"
            />
          </Space>
        </div>
      }
    >
      <StyledForm layout="vertical" form={form} size="small">
        <Card style={{ border: "1px solid #A3E4F5", backgroundColor: "#E3F7FC" }} size="small">
          <Descriptions layout="vertical" column={3} size="small">
            <Descriptions.Item label="CL Ref No.">CL-12345</Descriptions.Item>
            <Descriptions.Item label="Billing No.">BILL-98765</Descriptions.Item>
            <Descriptions.Item label="Bill Date">2024-10-15</Descriptions.Item>
          </Descriptions>
        </Card>

        <Table
          columns={columns}
          dataSource={eyeData}
          pagination={false}
          bordered
          rowKey="key"
          style={{ margin: "20px 0px 30px 0px" }}
          size="small"
        />

        <Row gutter={[16, 16]}>
          <Col span={4}>
            <Form.Item label="Job Date" name="jobDate">
              <DatePicker style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="Due Date" name="dueDate">
              <DatePicker style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="Prescriber" name="prescriber">
              <Input />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="Dispenser" name="dispenser">
              <Input />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="R Inv. No." name="rInvNo">
              <Input />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="L Inv. No." name="lInvNo">
              <Input />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="R Warranty Expiry" name="rWarrantyExpiry">
              <DatePicker style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="L Warranty Expiry" name="lWarrantyExpiry">
              <DatePicker style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="Tint" name="tint">
              <Input />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="Discount %" name="discount">
              <InputNumber min={0} max={100} style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="Total Excl. Tax" name="totalExcTax">
              <InputNumber min={0} step={0.01} style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="Tax %" name="tax">
              <InputNumber min={0} max={100} step={0.01} style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="Total Incl. Tax" name="totalIncTax">
              <InputNumber min={0} step={0.01} style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="CL Ready Date" name="clReady">
              <DatePicker style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="Collect R Lens" name="collectRLens">
              <DatePicker style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="Collect L Lens" name="collectLLens">
              <DatePicker style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="Replenish Date" name="replenishDate">
              <DatePicker style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="Last Notified" name="lastNotified">
              <DatePicker style={{ width: "100%" }} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16} style={{ marginTop: "12px" }}>
          <Col span={6}>
            <Space>
              <Form.Item label="Send Notification Through" name="notificationDetails">
                <Select placeholder="Select Notification">
                  <Option value="sms">SMS</Option>
                  <Option value="email">Email</Option>
                </Select>
              </Form.Item>
              <Button type="primary" style={{ marginTop: "26px" }}>
                Notify Customer
              </Button>
            </Space>
          </Col>
        </Row>

        <Row gutter={[8, 8]} style={{ marginTop: "12px" }}>
          <Col span={4}>
            <Form.Item label="No. of Notifications Sent" name="notificationsSent">
              <InputNumber min={0} style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col span={7}>
            <Form.Item label="Comments" name="comments">
              <TextArea rows={1} />
            </Form.Item>
          </Col>
          <Col span={7}>
            <Form.Item label="Others" name="others">
              <TextArea rows={1} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Cleaning" name="cleaning">
              <TextArea rows={1} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Pat Instru" name="patInstru">
              <TextArea rows={1} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Lab Instru" name="labInstru">
              <TextArea rows={1} />
            </Form.Item>
          </Col>
        </Row>

        <div style={{ display: "flex", gap: "16px", marginTop: "6px", justifyContent: "flex-end" }}>
          <Button type="default" onClick={() => form.resetFields()}>
            Discard
          </Button>
          <Button type="primary" onClick={handleAddToBill}>
            Save
          </Button>
        </div>
      </StyledForm>
    </Card>
  );
};

export default ContactLensPage;
