import React, { useState } from "react";
import { Card, Divider, Row, Col, Form, Input, Button, Radio, DatePicker, Table } from "antd";
import dayjs from "dayjs";

const { RangePicker } = DatePicker;

const SupplierInvoiceCreditReport: React.FC = () => {
  const [reportData, setReportData] = useState<any[]>([]);
  const [dateRange, setDateRange] = useState<[string, string] | null>(null);

  const handleFinish = (values: any) => {
    console.log("Report values: ", values);
    setDateRange([
      dayjs(values.dateRange[0]).format("DD-MM-YYYY"),
      dayjs(values.dateRange[1]).format("DD-MM-YYYY"),
    ]);

    // Dummy data for demonstration purposes
    setReportData([
      {
        key: 1,
        date: "11-10-2024",
        supplier: "Supplier A",
        invoiceCredit: "INV001",
        ref: "REF001",
        paid: "$100",
        totalAmt: "$110",
        gst: "$10",
        taxableAmt: "$90",
        nonTaxableAmt: "$20",
        note: "Sample note",
      },
      // Add more rows as necessary
    ]);
  };

  const columns = [
    { title: "Date", dataIndex: "date", key: "date" },
    { title: "Supplier", dataIndex: "supplier", key: "supplier" },
    { title: "Supplier Invoice / Credit", dataIndex: "invoiceCredit", key: "invoiceCredit" },
    { title: "Ref", dataIndex: "ref", key: "ref" },
    { title: "Paid", dataIndex: "paid", key: "paid" },
    { title: "Total Amt. (Inc. Tax)$", dataIndex: "totalAmt", key: "totalAmt" },
    { title: "GST$", dataIndex: "gst", key: "gst" },
    { title: "Taxable Amt.", dataIndex: "taxableAmt", key: "taxableAmt" },
    { title: "Non-Taxable Amt.", dataIndex: "nonTaxableAmt", key: "nonTaxableAmt" },
    { title: "Note", dataIndex: "note", key: "note" },
  ];

  return (
    <>
      <Card
        style={{
          boxShadow: "0px 8px 40px -10px #00000014",
          borderRadius: "4px",
        }}
      >
        <Form layout="vertical" onFinish={handleFinish}>
          <Divider orientation="left" orientationMargin="0" style={{ marginBottom: "34px" }}>
            Supplier Invoice / Credit Report
          </Divider>

          <Row gutter={24}>
            <Col span={8}>
              <Form.Item
                label="Date Range"
                name="dateRange"
                rules={[{ required: true, message: "Required" }]}
              >
                <RangePicker />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Supplier Code"
                name="supplierCode"
                rules={[{ required: true, message: "Required" }]}
              >
                <Input placeholder="Enter Supplier Code" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={24}>
              <Form.Item label="Report Destination" name="reportDestination">
                <Radio.Group>
                  <Radio value="screen">To Screen</Radio>
                  <Radio value="printer">To Printer</Radio>
                  <Radio value="printerWithPrompt">To Printer with Prompt</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>

          <Row justify={"end"}>
            <div>
              <Button>Reset</Button>
              <Button
                type="primary"
                htmlType="submit"
                style={{ marginTop: "34px", marginLeft: "12px" }}
              >
                Generate Report
              </Button>
            </div>
          </Row>
        </Form>
      </Card>

      {reportData.length > 0 && (
        <Card
          style={{
            marginTop: "24px",
            boxShadow: "0px 8px 40px -10px #00000014",
            borderRadius: "4px",
          }}
        >
          <Divider orientation="left" orientationMargin="0">
            Supplier Invoice / Credit Report {dateRange?.[0]} To {dateRange?.[1]}
          </Divider>
          <Table columns={columns} dataSource={reportData} pagination={false} />
        </Card>
      )}
    </>
  );
};

export default SupplierInvoiceCreditReport;
