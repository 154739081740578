import React from "react";
import { Card, Space, Typography, Tag } from "antd";
import { format } from "date-fns";
import { Event } from "types/event.type";
import { UserOutlined, ScheduleOutlined } from "@ant-design/icons";

// Define the colors based on status
const statusStyles = {
  booked: {
    background: "#E5E7EB",
    tagColor: "#6B7280",
    textColor: "#1F2937",
  },
  confirmed: {
    background: "#A8E6B8",
    tagColor: "#38B000",
    textColor: "#277A00",
  },
  arrived: {
    background: "#EFD2DF",
    tagColor: "#C14F82",
    textColor: "#973561",
  },
  "pre-test": {
    background: "#D3D2EF",
    tagColor: "#7848E1",
    textColor: "#4B1CB0",
  },
  consult: {
    background: "#E3F7FC",
    tagColor: "#18B6DA",
    textColor: "#0C5C6E",
  },
  "consult complete": {
    background: "#FFD4C2",
    tagColor: "#FF6A2A",
    textColor: "#CC3D00",
  },
  dispensing: {
    background: "#FDE68A",
    tagColor: "#F59E0B",
    textColor: "#92400E",
  },
  departed: {
    background: "#FECACA",
    tagColor: "#DC2626",
    textColor: "#991B1B",
  },
};

const EventBlock: React.FC<{
  event: Event;
  index: number;
  hour: string;
}> = ({ event, index }) => {
  const eventStartTime = new Date(event.startTime);
  const formattedTime = format(eventStartTime, "h:mm a");

  // Get styles based on the event status
  const { background, tagColor, textColor } = statusStyles[event.status] || {};

  return (
    <Card key={index} bordered={false} style={{ backgroundColor: background, color: textColor }}>
      <Space direction="vertical">
        {/* Patient's Name */}
        <Typography.Text style={{ color: textColor }}>
          <UserOutlined style={{ color: textColor, marginRight: 4 }} />
          {event.patientName}
        </Typography.Text>
        {/* Event Title */}
        <Typography.Text strong style={{ color: textColor }}>
          {event.title}
        </Typography.Text>
        {/* Status */}
        <Tag color={tagColor} style={{ backgroundColor: tagColor }}>
          {event.status}
        </Tag>
        {/* Formatted Time */}
        <Typography.Text style={{ color: textColor }}>
          <ScheduleOutlined style={{ color: textColor, marginRight: 4 }} />
          {formattedTime}
        </Typography.Text>
      </Space>
    </Card>
  );
};

export default EventBlock;
