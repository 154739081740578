import React, { useState } from "react";
import { Card, Row, Col, Input, Table, Button } from "antd";
import { AiOutlineEye } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "constants/routeConstants";

const BulkBillingPaymentPage: React.FC = () => {
  const [searchText, setSearchText] = useState("");
  const navigate = useNavigate();

  const handleSearch = (value: string) => {
    setSearchText(value);
    // Add logic to filter the table based on the searchText
  };

  const handleView = (record: any) => {
    console.log("View record: ", record);
    // Implement view logic here
    navigate(ROUTES.FINANCIAL_ROUTES.BULK_BILLING_PAYMENT.DETAILS);
  };

  const columns = [
    {
      title: "SN",
      dataIndex: "sn",
      key: "sn",
    },
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "Billing Account Name",
      dataIndex: "billingAccountName",
      key: "billingAccountName",
    },
    {
      title: "Account",
      dataIndex: "account",
      key: "account",
    },
    {
      title: "Pat Rebate",
      dataIndex: "patRebate",
      key: "patRebate",
    },
    {
      title: "Item From",
      dataIndex: "itemFrom",
      key: "itemFrom",
    },
    {
      title: "Paid By",
      dataIndex: "paidBy",
      key: "paidBy",
    },
    {
      title: "Phone No.",
      dataIndex: "phoneNo",
      key: "phoneNo",
    },
    {
      title: "Actions",
      key: "actions",
      render: (_: any, record: any) => (
        <Button icon={<AiOutlineEye />} onClick={() => handleView(record)}></Button>
      ),
    },
  ];

  const data = [
    {
      key: "1",
      sn: "1",
      code: "001",
      billingAccountName: "John Doe",
      account: "123456789",
      patRebate: "$100",
      itemFrom: "001",
      paidBy: "Insurance",
      phoneNo: "123-456-7890",
    },
    {
      key: "2",
      sn: "2",
      code: "002",
      billingAccountName: "Jane Smith",
      account: "987654321",
      patRebate: "$200",
      itemFrom: "002",
      paidBy: "Self",
      phoneNo: "098-765-4321",
    },
    // Add more data as needed
  ];

  const filteredData = data.filter((item) =>
    Object.values(item).some((value) =>
      value.toString().toLowerCase().includes(searchText.toLowerCase())
    )
  );

  return (
    <Card
      style={{
        boxShadow: "0px 8px 40px -10px #00000014",
        borderRadius: "4px",
      }}
    >
      <Row gutter={24} style={{ marginBottom: "20px" }}>
        <Col span={8}>
          <Input.Search placeholder="Search" enterButton onSearch={handleSearch} />
        </Col>
      </Row>

      <Table
        columns={columns}
        dataSource={filteredData}
        pagination={{ pageSize: 10 }}
        rowKey="key"
      />
    </Card>
  );
};

export default BulkBillingPaymentPage;
