import React from "react";
import { Card, Divider, Row, Col, Form, Input, Table, Button, Space } from "antd";

const boldLabelStyle = { fontWeight: "bold", color: "#141414" };

const SundryForm: React.FC = () => {
  const costTableData = [
    { key: "1", priceFor: "Unit Price", excludingTax: "", includingTax: "" },
    { key: "2", priceFor: "Bulk Price", excludingTax: "", includingTax: "" },
    { key: "3", priceFor: "Wholesale Price", excludingTax: "", includingTax: "" },
    { key: "4", priceFor: "Retail Price", excludingTax: "", includingTax: "" },
    { key: "5", priceFor: "Distributor Price", excludingTax: "", includingTax: "" },
    { key: "6", priceFor: "Special Price", excludingTax: "", includingTax: "" },
  ];

  const costTableColumns = [
    {
      title: "Prices For",
      dataIndex: "priceFor",
      key: "priceFor",
      width: "33%",
      render: (text: string) => <span>{text}</span>,
    },
    {
      title: "Excluding Tax",
      dataIndex: "excludingTax",
      key: "excludingTax",
      width: "33%",
      render: (_: any, record: any) => (
        <Form.Item
          name={["costDetails", record.key, "excludingTax"]}
          rules={[{ required: true, message: "Required" }]}
        >
          <Input placeholder="Excluding Tax" />
        </Form.Item>
      ),
    },
    {
      title: "Including Tax",
      dataIndex: "includingTax",
      key: "includingTax",
      width: "33%",
      render: (_: any, record: any) => (
        <Form.Item
          name={["costDetails", record.key, "includingTax"]}
          rules={[{ required: true, message: "Required" }]}
        >
          <Input placeholder="Including Tax" />
        </Form.Item>
      ),
    },
  ];

  const handleFinish = (values: any) => {
    console.log("Form values: ", values);
  };

  return (
    <Card
      style={{
        boxShadow: "0px 8px 40px -10px #00000014",
        borderRadius: "4px",
      }}
    >
      <Form layout="vertical" onFinish={handleFinish}>
        <Divider orientation="left" orientationMargin="0" style={{ marginBottom: "34px" }}>
          Sundry Details
        </Divider>
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item
              label={<span style={boldLabelStyle}>Item Name</span>}
              name="itemName"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input placeholder="Item Name" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={<span style={boldLabelStyle}>SKU</span>}
              name="sku"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input placeholder="SKU" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={<span style={boldLabelStyle}>Category</span>}
              name="category"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input placeholder="Category" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={<span style={boldLabelStyle}>Status</span>}
              name="status"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input placeholder="Status" />
            </Form.Item>
          </Col>
        </Row>

        <Divider orientation="left" orientationMargin="0" style={{ margin: "40px 0 34px 0" }}>
          Supplier Details
        </Divider>
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item
              label={<span style={boldLabelStyle}>Supplier Name</span>}
              name="supplierName"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input placeholder="Supplier Name" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={<span style={boldLabelStyle}>Contact</span>}
              name="contact"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input placeholder="Contact" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={<span style={boldLabelStyle}>Address</span>}
              name="address"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input placeholder="Address" />
            </Form.Item>
          </Col>
        </Row>

        <Divider orientation="left" orientationMargin="0" style={{ margin: "40px 0 34px 0" }}>
          Re-Order Details
        </Divider>
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item
              label={<span style={boldLabelStyle}>Minimum Quantity</span>}
              name="minQty"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input placeholder="Minimum Quantity" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={<span style={boldLabelStyle}>Maximum Quantity</span>}
              name="maxQty"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input placeholder="Maximum Quantity" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={<span style={boldLabelStyle}>Re-Order Level</span>}
              name="reOrderLevel"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input placeholder="Re-Order Level" />
            </Form.Item>
          </Col>
        </Row>

        <Divider orientation="left" orientationMargin="0" style={{ margin: "40px 0 34px 0" }}>
          Cost Details
        </Divider>
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item
              label={<span style={boldLabelStyle}>Cost Price</span>}
              name="costPrice"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input placeholder="Cost Price" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={<span style={boldLabelStyle}>Tax Rate</span>}
              name="taxRate"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input placeholder="Tax Rate" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={<span style={boldLabelStyle}>Discount</span>}
              name="discount"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input placeholder="Discount" />
            </Form.Item>
          </Col>
        </Row>

        {/* Cost Details Table */}
        <Table
          columns={costTableColumns}
          dataSource={costTableData}
          pagination={false}
          bordered={true}
          style={{ marginTop: "34px" }}
        />

        {/* Sales and Purchase Details Section */}
        <Divider orientation="left" orientationMargin="0" style={{ margin: "40px 0 34px 0" }}>
          Sales and Purchase Details
        </Divider>
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item
              label={<span style={boldLabelStyle}>Total Sales</span>}
              name="totalSales"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input placeholder="Total Sales" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={<span style={boldLabelStyle}>Total Purchases</span>}
              name="totalPurchases"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input placeholder="Total Purchases" />
            </Form.Item>
          </Col>
        </Row>

        <Row justify={"end"}>
          <div>
            <Button>Cancel</Button>
            <Button
              type="primary"
              htmlType="submit"
              style={{ marginTop: "34px", marginLeft: "12px" }}
            >
              Submit
            </Button>
          </div>
        </Row>
      </Form>
    </Card>
  );
};

export default SundryForm;
