import { useApiService } from "hooks/useApiService";
import { QUERY_KEYS } from "constants/queryKeys";
import { UserInterface, UserResponseInterface } from "./types";
import { userServiceApi } from "./userServiceApi";

export const useUserService = () => {
  return useApiService<UserResponseInterface, UserInterface>(
    QUERY_KEYS.USER_MANAGEMENT,
    userServiceApi
  );
};
