import React from "react";
import { Menu, Tooltip } from "antd";
import {
  SquaresFour,
  Stethoscope,
  Invoice,
  TipJar,
  Calendar,
  Warehouse,
  GearSix,
  Users,
  Question,
  UserCircle,
  CurrencyDollarSimple, // Import the icon for Financial menu
} from "@phosphor-icons/react";

interface MenuProps {
  onSelect: (e: any) => void;
  selectedKeys: string;
  openKeys: string[];
  onOpenChange: (e: string[]) => void;
}

const iconColor = (isSelected: boolean) => (isSelected ? "#1499B8" : "#4B5563");

const AppMenu: React.FC<MenuProps> = ({ onSelect, selectedKeys, openKeys, onOpenChange }) => {
  return (
    <Menu
      mode="inline"
      openKeys={openKeys}
      onOpenChange={onOpenChange}
      selectedKeys={[selectedKeys]}
      onClick={onSelect}
      style={{ borderRight: 0, fontWeight: 500 }}
    >
      <Menu.Item
        key="/dashboard"
        icon={
          <SquaresFour size={18} color={iconColor(selectedKeys === "/dashboard")} weight="fill" />
        }
      >
        Dashboard
      </Menu.Item>
      <Menu.Item
        key="/consultation"
        icon={
          <Stethoscope
            size={18}
            color={iconColor(selectedKeys === "/consultation")}
            weight="fill"
          />
        }
      >
        Consultation
      </Menu.Item>
      <Menu.Item
        key="/subscription"
        icon={
          <TipJar size={18} color={iconColor(selectedKeys === "/subscription")} weight="fill" />
        }
      >
        Subscription
      </Menu.Item>
      <Menu.Item
        key="/appointment"
        icon={
          <Calendar size={18} color={iconColor(selectedKeys === "/appointment")} weight="fill" />
        }
      >
        Appointments
      </Menu.Item>
      <Menu.Item
        key="/user-management"
        icon={
          <Users size={18} color={iconColor(selectedKeys === "/user-management")} weight="fill" />
        }
      >
        User Management
      </Menu.Item>
      <Menu.Item
        key="/patient-records"
        icon={
          <UserCircle
            size={18}
            color={iconColor(selectedKeys === "/patient-records")}
            weight="fill"
          />
        }
      >
        Patient Management
      </Menu.Item>
      <Menu.SubMenu
        key="/master-setup"
        icon={
          <GearSix
            size={18}
            color={iconColor(selectedKeys.includes("/master-setup"))}
            weight="fill"
          />
        }
        title="Master Setup"
      >
        <Menu.Item key="/master-setup/lens-code">Lens Code Spectacle</Menu.Item>
        <Menu.Item key="/master-setup/supplier">Supplier Setup</Menu.Item>
        <Menu.Item key="/master-setup/payment-type">Payment Type</Menu.Item>
        <Menu.Item key="/master-setup/frame-group">Frame Group</Menu.Item>
        <Menu.Item key="/master-setup/frame-type">Frame Type</Menu.Item>
        <Menu.Item key="/master-setup/working-hours">Working Hours</Menu.Item>
        <Menu.Item key="/master-setup/appointment-type">Appointment Type</Menu.Item>
        <Menu.Item key="/master-setup/practitioner-setup">Practitioner Setup</Menu.Item>
        <Menu.Item key="/master-setup/support-ticket-type">Support Ticket Type</Menu.Item>
      </Menu.SubMenu>
      <Menu.SubMenu
        key="/inventory"
        icon={
          <Warehouse
            size={18}
            color={iconColor(selectedKeys.includes("/inventory"))}
            weight="fill"
          />
        }
        title="Inventory"
      >
        <Menu.Item key="/inventory/frame">Frame Details</Menu.Item>
        <Menu.Item key="/inventory/sundry">Sundry Details</Menu.Item>
        <Menu.Item key="/inventory/adjustment-report">Adjustment Report</Menu.Item>
        <Menu.Item key="/inventory/invoice-report">Invoice Report</Menu.Item>
        <Menu.Item key="/inventory/frame-stock-report">Frame Stock Report</Menu.Item>
        <Menu.Item key="/inventory/sundry-stock-report">Sundry Stock Report</Menu.Item>
        <Menu.Item key="/inventory/frame-approval">Frame Approval</Menu.Item>
        <Menu.Item key="/inventory/frame-ordering-from-usage">
          <Tooltip title="Frame Ordering From Usage">Frame Ordering From Usage</Tooltip>
        </Menu.Item>
        {/* Last four items with tooltips */}

        <Menu.Item key="/inventory/frame-barcode-by-purchase-date-report">
          <Tooltip title="Print Frame Bar Code By Purchase Date">
            Print Frame Bar Code By Purchase Date
          </Tooltip>
        </Menu.Item>

        <Menu.Item key="/inventory/lens-barcode-report">
          <Tooltip title="Print Bar Code for Lens Code">Print Bar Code for Lens Code</Tooltip>
        </Menu.Item>

        <Menu.Item key="/inventory/lens-price-list-report">
          <Tooltip title="Print Price List for Lens Code">Print Price List for Lens Code</Tooltip>
        </Menu.Item>

        <Menu.Item key="/inventory/frame-stocktake-by-barcode">
          <Tooltip title="Frame Stocktake by BarCode">Frame Stocktake by BarCode</Tooltip>
        </Menu.Item>
      </Menu.SubMenu>
      {/* New Financial Menu */}
      <Menu.SubMenu
        key="/financial"
        icon={
          <CurrencyDollarSimple
            size={18}
            color={iconColor(selectedKeys.includes("/financial"))}
            weight="fill"
          />
        }
        title="Financial"
      >
        <Menu.Item key="/financial/petty-cash">Petty Cash</Menu.Item>
        <Menu.Item key="/financial/banking">Banking</Menu.Item>
        <Menu.Item key="/financial/bulk-billing-accounts">Bulk Billing Accounts</Menu.Item>
        <Menu.Item key="/financial/bulk-billing-payment">Bulk Billing Payments</Menu.Item>
        <Menu.Item key="/financial/bulk-billing-outstanding-accounts">
          Outstanding Bulk Billing Accounts
        </Menu.Item>
        <Menu.Item key="/financial/supplier-invoice-credit-report">
          Supplier Invoice /Credit Report
        </Menu.Item>
        <Menu.Item key="/financial/banking-reconciliation">Bank Reconciliation</Menu.Item>
        <Menu.Item key="/financial/billing-reports-summary-yearly-graph">
          Billing Summary Yearly Graph
        </Menu.Item>
        <Menu.Item key="/financial/billing-reports-ssf-item-summary">
          SSF Billing Item Summary
        </Menu.Item>
        <Menu.Item key="/financial/billing-serach-from-item-number">
          Billing Search By Item No.
        </Menu.Item>
        <Menu.Item key="/financial/payment-summary">Payment Summary</Menu.Item>
      </Menu.SubMenu>
      <Menu.Item
        key="/help-support"
        icon={
          <Question size={18} color={iconColor(selectedKeys === "/help-support")} weight="fill" />
        }
      >
        Help and Support
      </Menu.Item>
    </Menu>
  );
};

export default AppMenu;
