import React from "react";
import { Form, Input } from "antd";
import { FieldConfig } from "types/form.config.type";
import DynamicFormBuilder from ".";
import { componentMap } from "./componentMap";

const FormFieldRenderer: React.FC<{ fieldConfig: FieldConfig }> = ({ fieldConfig }) => {
  const {
    name,
    label,
    props = {},
    children = [],
    valuePropName,
    initialValue,
    componentType,
  } = fieldConfig;
  const Component = componentMap[componentType || "Input"] || Input;

  return (
    <>
      <Form.Item
        name={name}
        label={label}
        initialValue={initialValue}
        valuePropName={valuePropName}
        {...props}
      >
        {componentType ? (
          <Component {...props} fieldConfig={fieldConfig} />
        ) : (
          <fieldConfig.component {...(fieldConfig.props as any)} />
        )}
      </Form.Item>
      {Array.isArray(children) && <DynamicFormBuilder fieldsConfig={children} />}
    </>
  );
};

export default FormFieldRenderer;
