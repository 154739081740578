import { coreClient } from "utils/httpClient";
import { API_ENDPOINTS } from "constants/apiEndpoints";
import { FrameGroupInterface, FrameGroupResponseInterface } from "./types";

const apiClient = coreClient;

export const frameGroupApi = {
  getAll: async (): Promise<FrameGroupResponseInterface> => {
    const response = await apiClient.get<FrameGroupResponseInterface>(
      API_ENDPOINTS.FRAME_GROUP.GET_ALL
    );
    return response.data;
  },

  getById: async (id: string): Promise<FrameGroupInterface> => {
    const response = await apiClient.get<{
      success: boolean;
      data: FrameGroupInterface;
      message?: string;
    }>(API_ENDPOINTS.FRAME_GROUP.READ(id));
    return response.data.data;
  },

  create: async (frameGroupData: FrameGroupInterface): Promise<FrameGroupInterface> => {
    const response = await apiClient.post<FrameGroupInterface>(
      API_ENDPOINTS.FRAME_GROUP.CREATE,
      frameGroupData
    );
    return response.data;
  },

  update: async (id: string, frameGroupData: FrameGroupInterface): Promise<FrameGroupInterface> => {
    const response = await apiClient.put<FrameGroupInterface>(
      API_ENDPOINTS.FRAME_GROUP.UPDATE(id),
      frameGroupData
    );
    return response.data;
  },

  delete: async (id: string) => {
    await apiClient.delete<void>(API_ENDPOINTS.FRAME_GROUP.DELETE(id));
  },
};
