import React, { useState } from "react";
import PageTemplate from "components/PageTemplate";
import { Table, Button, Switch, Card, TimePicker, Space } from "antd";
import { EditOutlined, SaveOutlined, CloseOutlined } from "@ant-design/icons";
import dayjs, { Dayjs } from "dayjs";

// Define the type for a working hours record
interface WorkingHoursRecord {
  key: string;
  day: string;
  isHoliday: boolean;
  openingTime: string;
  closingTime: string;
}

const WorkingHoursConfig: React.FC = () => {
  const [data, setData] = useState<WorkingHoursRecord[]>([
    { key: "1", day: "Sunday", isHoliday: true, openingTime: "", closingTime: "" },
    { key: "2", day: "Monday", isHoliday: false, openingTime: "09:00", closingTime: "17:00" },
    { key: "3", day: "Tuesday", isHoliday: false, openingTime: "09:00", closingTime: "17:00" },
    { key: "4", day: "Wednesday", isHoliday: false, openingTime: "09:00", closingTime: "17:00" },
    { key: "5", day: "Thursday", isHoliday: false, openingTime: "09:00", closingTime: "17:00" },
    { key: "6", day: "Friday", isHoliday: false, openingTime: "09:00", closingTime: "17:00" },
    { key: "7", day: "Saturday", isHoliday: true, openingTime: "", closingTime: "" },
  ]);

  const [editingKey, setEditingKey] = useState<string | null>(null);
  const [editingValues, setEditingValues] = useState<Partial<WorkingHoursRecord>>({});

  const handleEdit = (record: WorkingHoursRecord) => {
    setEditingKey(record.key);
    setEditingValues({
      openingTime: record.openingTime,
      closingTime: record.closingTime,
    });
  };

  const handleSave = (key: string) => {
    setData((prevData) =>
      prevData.map((item) => (item.key === key ? { ...item, ...editingValues } : item))
    );
    setEditingKey(null);
  };

  const handleCancel = () => {
    setEditingKey(null);
  };

  const handleTimeChange = (
    time: Dayjs | null,
    timeString: any,
    field: keyof WorkingHoursRecord
  ) => {
    setEditingValues((prev) => ({ ...prev, [field]: timeString }));
  };

  const columns = [
    {
      title: "SN",
      dataIndex: "key",
      render: (text: string) => <span>{text}</span>,
    },
    {
      title: "Day",
      dataIndex: "day",
    },
    {
      title: "Is Holiday?",
      dataIndex: "isHoliday",
      render: (isHoliday: boolean) => <Switch checked={isHoliday} disabled />,
    },
    {
      title: "Opening Time",
      dataIndex: "openingTime",
      render: (text: string, record: WorkingHoursRecord) =>
        editingKey === record.key ? (
          <TimePicker
            value={dayjs(editingValues.openingTime, "HH:mm")}
            format="HH:mm"
            onChange={(time, timeString) => handleTimeChange(time, timeString, "openingTime")}
          />
        ) : (
          <span>{text || "--"}</span>
        ),
    },
    {
      title: "Closing Time",
      dataIndex: "closingTime",
      render: (text: string, record: WorkingHoursRecord) =>
        editingKey === record.key ? (
          <TimePicker
            value={dayjs(editingValues.closingTime, "HH:mm")}
            format="HH:mm"
            onChange={(time, timeString) => handleTimeChange(time, timeString, "closingTime")}
          />
        ) : (
          <span>{text || "--"}</span>
        ),
    },
    {
      title: "Action",
      render: (_: any, record: WorkingHoursRecord) =>
        editingKey === record.key ? (
          <Space>
            <Button icon={<SaveOutlined />} type="primary" onClick={() => handleSave(record.key)} />
            <Button icon={<CloseOutlined />} onClick={handleCancel} />
          </Space>
        ) : (
          <Button icon={<EditOutlined />} onClick={() => handleEdit(record)} />
        ),
    },
  ];

  return (
    <PageTemplate>
      <Card
        style={{
          boxShadow: "0px 8px 40px -10px #00000014",
          borderRadius: "4px",
        }}
      >
        <div style={{ marginTop: "18px" }}>
          <Table columns={columns} dataSource={data} pagination={false} rowKey="key" />
        </div>
      </Card>
    </PageTemplate>
  );
};

export default WorkingHoursConfig;
