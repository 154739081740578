import React, { useState } from "react";
import { Card, Divider, Row, Col, Form, Input, Button, DatePicker, Table, Radio } from "antd";
import dayjs from "dayjs";

const { RangePicker } = DatePicker;

const BillingSearchByItemNumber: React.FC = () => {
  const [reportData, setReportData] = useState<any[]>([]);
  const [dateRange, setDateRange] = useState<[string, string] | null>(null);

  // Handle form submission
  const handleFinish = (values: any) => {
    console.log("Report values:123 ", values);

    // Set the formatted date range
    setDateRange([
      dayjs(values?.startDate)?.format("DD-MM-YYYY"),
      dayjs(values?.endDate)?.format("DD-MM-YYYY"),
    ]);

    // Dummy data for Billing Search By Item Number
    setReportData([
      {
        key: 1,
        patRef: "REF001",
        acNo: "A/C001",
        date: "10-10-2024",
        code: "ITEM001",
        description: "Item 1 Description",
        itemAmount: "$200",
        gst: "$20",
        gstPercentage: "10%",
        calcGstPercentage: "10%",
      },
      {
        key: 2,
        patRef: "REF002",
        acNo: "A/C002",
        date: "11-10-2024",
        code: "ITEM002",
        description: "Item 2 Description",
        itemAmount: "$300",
        gst: "$30",
        gstPercentage: "10%",
        calcGstPercentage: "10%",
      },
      {
        key: 3,
        patRef: "REF003",
        acNo: "A/C003",
        date: "12-10-2024",
        code: "ITEM003",
        description: "Item 3 Description",
        itemAmount: "$150",
        gst: "$15",
        gstPercentage: "10%",
        calcGstPercentage: "10%",
      },
      // Add more rows as needed
    ]);
  };

  // Define the table columns
  const columns = [
    { title: "Pat. Ref", dataIndex: "patRef", key: "patRef" },
    { title: "A/C No", dataIndex: "acNo", key: "acNo" },
    { title: "Date", dataIndex: "date", key: "date" },
    { title: "Code", dataIndex: "code", key: "code" },
    { title: "Description", dataIndex: "description", key: "description" },
    { title: "Item Amount", dataIndex: "itemAmount", key: "itemAmount" },
    { title: "GST", dataIndex: "gst", key: "gst" },
    { title: "GST Percentage", dataIndex: "gstPercentage", key: "gstPercentage" },
    { title: "Calc GST Percentage", dataIndex: "calcGstPercentage", key: "calcGstPercentage" },
  ];

  console.log(reportData);
  return (
    <>
      <Card
        style={{
          boxShadow: "0px 8px 40px -10px #00000014",
          borderRadius: "4px",
        }}
      >
        <Form layout="vertical" onFinish={handleFinish}>
          <Divider orientation="left" orientationMargin="0" style={{ marginBottom: "34px" }}>
            Billing Search By Item Number
          </Divider>

          <Row gutter={24}>
            <Col span={8}>
              <Form.Item
                label="Start Date"
                name="startDate"
                rules={[{ required: true, message: "Required" }]}
              >
                <DatePicker format="DD-MM-YYYY" style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="End Date"
                name="endDate"
                rules={[{ required: true, message: "Required" }]}
              >
                <DatePicker format="DD-MM-YYYY" style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Item Number"
                name="itemNumber"
                rules={[{ required: true, message: "Required" }]}
              >
                <Input placeholder="Enter Item Number" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={24}>
              <Form.Item label="Report Destination" name="reportDestination">
                <Radio.Group>
                  <Radio value="screen">To Screen</Radio>
                  <Radio value="printer">To Printer</Radio>
                  <Radio value="printerWithPrompt">To Printer with Prompt</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>

          <Row justify={"end"}>
            <div>
              <Button>Reset</Button>
              <Button
                type="primary"
                htmlType="submit"
                style={{ marginTop: "34px", marginLeft: "12px" }}
              >
                Generate Report
              </Button>
            </div>
          </Row>
        </Form>
      </Card>

      {reportData.length > 0 && (
        <Card
          style={{
            marginTop: "24px",
            boxShadow: "0px 8px 40px -10px #00000014",
            borderRadius: "4px",
          }}
        >
          <Divider orientation="left" orientationMargin="0">
            Billing Search By Item Number Report {dateRange?.[0]} To {dateRange?.[1]}
          </Divider>
          <Table columns={columns} dataSource={reportData} pagination={false} />
        </Card>
      )}
    </>
  );
};

export default BillingSearchByItemNumber;
