import React, { ReactNode, useState } from "react";
import { Form, Steps, Button } from "antd";
import { FormInstance } from "antd/es/form";

interface Step {
  title: string;
  content: ReactNode;
}

interface MultiStepFormProps {
  steps: Step[];
  onSubmit: (values: any) => Promise<any>;
  requestOtp?: () => Promise<any>;
  verifyOtp?: () => Promise<any>;
  form: FormInstance;
  requestLoading: boolean;
  verifyLoading: boolean;
}
const MultiStepForm: React.FC<MultiStepFormProps> = ({
  steps,
  onSubmit,
  requestOtp,
  verifyOtp,
  form,
  requestLoading,
  verifyLoading,
}) => {
  const [current, setCurrent] = useState(0);

  const handleNext = async () => {
    try {
      if (current === 0 && requestOtp) {
        await requestOtp();
      } else if (current === 1 && verifyOtp) {
        await verifyOtp();
      } else if (current === steps.length - 1) {
        await form.validateFields();
        await onSubmit(form.getFieldsValue());
      }
      setCurrent((prev) => Math.min(prev + 1, steps.length - 1));
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <Steps current={current} style={{ marginBottom: 20 }} direction="vertical">
        {steps.map((step) => (
          <Steps.Step key={step.title} title={step.title} />
        ))}
      </Steps>
      <Form
        form={form}
        layout="vertical"
        name="multi-step-form"
        style={{ maxWidth: 500, margin: "0 auto" }}
      >
        {steps[current].content}
        <div style={{ marginTop: 24, textAlign: "right" }}>
          {current > 0 && (
            <Button
              style={{ margin: "0 8px" }}
              onClick={() => setCurrent((prev) => Math.max(prev - 1, 0))}
            >
              Previous
            </Button>
          )}
          <Button
            type="primary"
            onClick={handleNext}
            loading={
              (current === 0 && requestLoading) || (current === 1 && verifyLoading)
                ? true
                : undefined
            }
          >
            {current === steps.length - 1 ? "Submit" : "Next"}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default MultiStepForm;
