import { coreClient } from "utils/httpClient";
import { API_ENDPOINTS } from "constants/apiEndpoints";
import { SupplierInterface, SupplierResponseInterface } from "./types";

const apiClient = coreClient;

export const supplierApi = {
  getAll: async (): Promise<SupplierResponseInterface> => {
    const response = await apiClient.get<SupplierResponseInterface>(API_ENDPOINTS.SUPPLIER.GET_ALL);
    return response.data;
  },

  getById: async (id: string): Promise<SupplierInterface> => {
    const response = await apiClient.get<{
      success: boolean;
      data: SupplierInterface;
      message?: string;
    }>(API_ENDPOINTS.SUPPLIER.READ(id));
    return response.data.data;
  },

  create: async (supplierData: SupplierInterface): Promise<SupplierInterface> => {
    const response = await apiClient.post<SupplierInterface>(
      API_ENDPOINTS.SUPPLIER.CREATE,
      supplierData
    );
    return response.data;
  },

  update: async (id: string, supplierData: SupplierInterface): Promise<SupplierInterface> => {
    const response = await apiClient.put<SupplierInterface>(
      API_ENDPOINTS.SUPPLIER.UPDATE(id),
      supplierData
    );
    return response.data;
  },

  delete: async (id: string) => {
    await apiClient.delete<void>(API_ENDPOINTS.SUPPLIER.DELETE(id));
  },
};
