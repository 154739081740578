import React from "react";
import { useNavigate } from "react-router-dom";
import { Table, Button, Dropdown, Menu, Row, Card } from "antd";
import {
  MoreOutlined,
  EyeOutlined,
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
} from "@ant-design/icons";
// import { useAppointmentTypeService } from "services/AppointmentTypeService/useAppointmentTypeService";
import useConfirmDelete from "hooks/useConfirmDelete";
import PageTemplate from "components/PageTemplate";
import { ROUTES } from "constants/routeConstants";

const AppointmentTypeListPage: React.FC = () => {
  const navigate = useNavigate();
  //   const { useGetAll, useDelete } = useAppointmentTypeService();
  // const { data: appointmentTypeData, isLoading, refetch } = useGetAll();
  //   const { mutateAsync: deleteAppointmentType, isPending } = useDelete();
  //   const { confirmDelete } = useConfirmDelete(deleteAppointmentType, isPending);

  const data = [
    {
      key: "1",
      appointmentType: "Consultation",
      patientType: "Patient",
      includeInKPI: true,
      includeForFeedback: false,
      allowedOnline: true,
      recallTime: "6 months",
    },
    {
      key: "2",
      appointmentType: "Sales Visit",
      patientType: "Non-Patient",
      includeInKPI: false,
      includeForFeedback: false,
      allowedOnline: false,
      recallTime: "N/A",
    },
  ];

  const handleMenuClick = (key: string, record: any) => {
    switch (key) {
      case "view":
        navigate(`/appointments/types/details/${record?.key}`);
        break;
      case "update":
        navigate(`/appointments/types/update/${record?.key}`);
        break;
      case "delete":
        // record?.key && confirmDelete(record?.key);
        break;
      default:
        break;
    }
  };

  const actionMenu = (record: any) => (
    <Menu>
      <Menu.Item key="view" icon={<EyeOutlined />} onClick={() => handleMenuClick("view", record)}>
        View details
      </Menu.Item>
      <Menu.Item
        key="update"
        icon={<EditOutlined />}
        onClick={() => handleMenuClick("update", record)}
      >
        Update appointment type
      </Menu.Item>
      <Menu.Item
        key="delete"
        icon={<DeleteOutlined />}
        onClick={() => handleMenuClick("delete", record)}
        danger
      >
        Delete appointment type
      </Menu.Item>
    </Menu>
  );

  const columns = [
    {
      title: "SN",
      dataIndex: "key",
      render: (text: string) => <span>{text}</span>,
    },
    {
      title: "Appointment Type",
      dataIndex: "appointmentType",
    },
    {
      title: "Patient/Non-Patient",
      dataIndex: "patientType",
    },
    {
      title: "Include in KPIs",
      dataIndex: "includeInKPI",
      render: (include: boolean) => (include ? "Yes" : "No"),
    },
    {
      title: "Include for Feedback",
      dataIndex: "includeForFeedback",
      render: (include: boolean) => (include ? "Yes" : "No"),
    },
    {
      title: "Allowed Online",
      dataIndex: "allowedOnline",
      render: (allowed: boolean) => (allowed ? "Yes" : "No"),
    },
    {
      title: "Recall Time",
      dataIndex: "recallTime",
    },
    {
      title: "Action",
      render: (text: any, record: any) => (
        <Dropdown overlay={actionMenu(record)} trigger={["click"]}>
          <Button icon={<MoreOutlined />} />
        </Dropdown>
      ),
    },
  ];

  return (
    <PageTemplate>
      <Card
        style={{
          boxShadow: "0px 8px 40px -10px #00000014",
          borderRadius: "4px",
        }}
      >
        <Row justify="end">
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => navigate(ROUTES.MASTER_SETUP.APPOINTMENT_TYPE.CREATE)}
          >
            Add Appointment Type
          </Button>
        </Row>

        <div style={{ marginTop: "18px" }}>
          <Table columns={columns} dataSource={data} />
        </div>
      </Card>
    </PageTemplate>
  );
};

export default AppointmentTypeListPage;
