import { coreClient } from "utils/httpClient";
import { API_ENDPOINTS } from "constants/apiEndpoints";
import { SundryInterface, SundryResponeInterface } from "./types";

const apiClient = coreClient;

export const sundryApi = {
  getAll: async (): Promise<SundryResponeInterface> => {
    const response = await apiClient.get<SundryResponeInterface>(API_ENDPOINTS.SUNDRY.GET_ALL);
    return response.data;
  },

  getById: async (id: string): Promise<SundryInterface> => {
    const response = await apiClient.get<{
      success: boolean;
      data: SundryInterface;
      message?: string;
    }>(API_ENDPOINTS.SUNDRY.READ(id));
    return response.data.data;
  },

  create: async (sundryData: SundryInterface): Promise<SundryInterface> => {
    const response = await apiClient.post<SundryInterface>(API_ENDPOINTS.SUNDRY.CREATE, sundryData);
    return response.data;
  },

  update: async (id: string, sundryData: SundryInterface): Promise<SundryInterface> => {
    const response = await apiClient.put<SundryInterface>(
      API_ENDPOINTS.SUNDRY.UPDATE(id),
      sundryData
    );
    return response.data;
  },

  delete: async (id: string) => {
    await apiClient.delete<void>(API_ENDPOINTS.SUNDRY.DELETE(id));
  },
};
