import React from "react";
import { Form, Input, Switch, Checkbox, Radio, Button, Card } from "antd";

const AddAppointmentType: React.FC = () => {
  const [form] = Form.useForm();

  const handleSubmit = () => {
    form.validateFields().then((values) => {
      console.log("Form values: ", values);
    });
  };

  return (
    <Card
      title="Add Appointment Type"
      style={{
        boxShadow: "0px 8px 40px -10px #00000014",
        borderRadius: "4px",
      }}
    >
      <Form form={form} layout="vertical">
        {/* Appointment Type Name */}
        <Form.Item
          label="Appointment Type Name"
          name="appointmentTypeName"
          rules={[{ required: true, message: "Please enter appointment type name!" }]}
        >
          <Input />
        </Form.Item>

        {/* Booking Settings */}
        <Form.Item label="Booking Settings">
          <Form.Item
            name="allowedOnlineBookings"
            valuePropName="checked"
            label="Allowed Online Bookings"
          >
            <Switch />
          </Form.Item>

          <Form.Item
            name="nonPatientAppointments"
            valuePropName="checked"
            label="Non-Patient Appointments"
          >
            <Switch />
          </Form.Item>

          <Form.Item name="customBookingMessage" label="Custom Booking Message">
            <Input.TextArea rows={3} />
          </Form.Item>
        </Form.Item>

        {/* Default Recall Frequency */}
        <Form.Item label="Default Recall Frequency">
          <Form.Item name="recallFrequency" label="Recall Frequency">
            <Radio.Group>
              <Radio value="onceOff">Once-Off</Radio>
              <Radio value="recurring">Recurring</Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item name="numberOfVisits" label="Number of Visits">
            <Input type="number" min={1} />
          </Form.Item>
        </Form.Item>

        {/* Default Recall Journey */}
        <Form.Item label="Default Recall Journey">
          <Form.Item name="recallJourney" label="Recall Journey">
            <Radio.Group>
              <Radio value="consultation">Consultation</Radio>
              <Radio value="consultSpecific">Consult specific settings</Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            name="autoGenerateRecall"
            valuePropName="checked"
            label="Auto-generate recall schedule"
          >
            <Switch />
          </Form.Item>
        </Form.Item>

        {/* Reporting */}
        <Form.Item label="Reporting">
          <Form.Item name="excludeKPI" valuePropName="checked">
            <Checkbox>Exclude from KPI reports</Checkbox>
          </Form.Item>
          <Form.Item name="excludeFeedback" valuePropName="checked">
            <Checkbox>Exclude from Patient Feedback</Checkbox>
          </Form.Item>
        </Form.Item>

        {/* Buttons */}
        <Form.Item>
          <Button type="default" onClick={() => form.resetFields()}>
            Cancel
          </Button>
          <Button type="primary" onClick={handleSubmit} style={{ marginLeft: "8px" }}>
            Save
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default AddAppointmentType;
