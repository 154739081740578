import React, { useState } from "react";
import PageTemplate from "components/PageTemplate";
import { useNavigate } from "react-router-dom";
import { Table, Button, Dropdown, Menu, Row, Modal, Form, Input, Card } from "antd";
import { EditOutlined, DeleteOutlined, PlusOutlined, MoreOutlined } from "@ant-design/icons";
import { useUserService } from "services/UserService";
import useConfirmDelete from "hooks/useConfirmDelete";

const UserList = () => {
  const navigate = useNavigate();
  const { useGetAll, useDelete } = useUserService(); // Assuming these hooks are implemented for user management
  const { mutateAsync: deleteUser, isPending } = useDelete();
  const { confirmDelete } = useConfirmDelete(deleteUser, isPending);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();

  const data = [
    {
      key: "1",
      fullName: "John Doe",
      email: "john.doe@example.com",
      phoneNumber: "1234567890",
      createdDate: "2023-10-01",
      status: "Active",
    },
    {
      key: "2",
      fullName: "Jane Smith",
      email: "jane.smith@example.com",
      phoneNumber: "0987654321",
      createdDate: "2023-09-15",
      status: "Inactive",
    },
  ];

  const handleMenuClick = (key: string, record: any) => {
    switch (key) {
      case "view":
        navigate(`/user/details/${record?.key}`);
        break;
      case "update":
        navigate(`/user/update/${record?.key}`);
        break;
      case "delete":
        record?.key && confirmDelete(record?.key);
        break;
      default:
        break;
    }
  };

  const actionMenu = (record: any) => (
    <Menu>
      <Menu.Item
        key="update"
        icon={<EditOutlined />}
        onClick={() => handleMenuClick("update", record)}
      >
        Update
      </Menu.Item>
      <Menu.Item
        key="delete"
        icon={<DeleteOutlined />}
        onClick={() => handleMenuClick("delete", record)}
        danger
      >
        Delete
      </Menu.Item>
    </Menu>
  );

  const columns = [
    {
      title: "SN",
      dataIndex: "key",
      render: (text: string) => <span>{text}</span>,
    },
    {
      title: "Full Name",
      dataIndex: "fullName",
    },
    {
      title: "Email ID",
      dataIndex: "email",
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
    },
    {
      title: "Created Date",
      dataIndex: "createdDate",
    },
    {
      title: "Status",
      dataIndex: "status",
    },
    {
      title: "Action",
      render: (text: any, record: any) => (
        <Dropdown overlay={actionMenu(record)} trigger={["click"]}>
          <Button icon={<MoreOutlined />} />
        </Dropdown>
      ),
    },
  ];

  const handleAddUser = () => {
    form.resetFields(); // Reset form fields
    setIsModalVisible(true); // Show modal
  };

  const handleModalOk = () => {
    form.validateFields().then((values) => {
      // Handle form submission (create user logic here)
      console.log("Received values from form: ", values);
      setIsModalVisible(false); // Close modal
    });
  };

  const handleModalCancel = () => {
    setIsModalVisible(false); // Close modal
  };

  return (
    <PageTemplate>
      <Card
        style={{
          boxShadow: "0px 8px 40px -10px #00000014",
          borderRadius: "4px",
        }}
      >
        <Row justify="end">
          <Button type="primary" icon={<PlusOutlined />} onClick={handleAddUser}>
            Add User
          </Button>
        </Row>

        <div style={{ marginTop: "18px" }}>
          <Table columns={columns} dataSource={data} />
        </div>
      </Card>

      <Modal
        title="Add User"
        visible={isModalVisible}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="fullName"
            label="Full Name"
            rules={[{ required: true, message: "Please enter full name!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="email"
            label="Email"
            rules={[
              { required: true, message: "Please enter email!" },
              { type: "email", message: "Please enter a valid email!" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="phoneNumber"
            label="Phone Number"
            rules={[{ required: true, message: "Please enter phone number!" }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </PageTemplate>
  );
};

export default UserList;
