export const routeTitles: Record<string, string> = {
  // Dashboard
  "/dashboard": "Dashboard",

  // Authentication Routes
  "/signin": "Sign In",
  "/signup": "Sign Up",
  "/resetpassword": "Reset Password",
  "/changepassword": "Change Password",

  // Consultation
  "/consultation": "Consultation",
  "/consultation/add": "Add Consultation",
  "/consultation/edit": "Edit Consultation",
  "/consultation/details": "Consultation Details",

  // Appointment
  "/appointment": "Appointments",
  "/appointment/add": "Add Appointment",
  "/appointment/update": "Update Appointment",
  "/appointment/details": "Appointment Details",

  // Patient Records
  "/patient-records": "Patient Records",
  "/patient-records/create": "Add Patient Records",
  "/patient-records/update": "Update Patient Records",
  "/patient-records/details/:id": "Patient Record Details",

  // Subscription
  "/subscription": "Subscription",
  "/subscription/create": "Add Subscription",
  "/subscription/update/:id": "Update Subscription",
  "/subscription/details/:id": "Subscription Details",

  // User Management
  "/user-management": "User Management",
  "/user-management/create": "Add User",
  "/user-management/update/:id": "Update User",
  "/user-management/details/:id": "User Details",

  // Inventory - Frame
  "/inventory/frame": "Frames",
  "/inventory/frame/create": "Add Frame",
  "/inventory/frame/details/:id": "Frame Details",
  "/inventory/frame/update/:id": "Update Frame",

  // Inventory - Sundry
  "/inventory/sundry": "Sundry",
  "/inventory/sundry/create": "Add Sundry",
  "/inventory/sundry/details/:id": "Sundry Details",
  "/inventory/sundry/update/:id": "Update Sundry",

  // Inventory Reports
  "/inventory/adjustment-report": "Adjustment Report",
  "/inventory/invoice-report": "Invoice Report",
  "/inventory/frame-stock-report": "Frame Stock Report",
  "/inventory/sundry-stock-report": "Sundry Stock Report",
  "/inventory/frame-approval": "Frame Approval",
  "/inventory/frame-ordering-from-usage": "Frame Ordering From Usage",
  "/inventory/frame-barcode-by-purchase-date-report": "Frame Barcode Report by Purchase Date",
  "/inventory/lens-barcode-report": "Lens Barcode Report",
  "/inventory/lens-price-list-report": "Lens Price List Report",
  "/inventory/frame-stocktake-by-barcode": "Frame Stocktake",

  // Master Setup - Supplier
  "/master-setup/supplier": "Suppliers",
  "/master-setup/supplier/create": "Add Supplier",
  "/master-setup/supplier/details/:id": "Supplier Details",
  "/master-setup/supplier/update/:id": "Update Supplier",

  // Master Setup - Payment Type
  "/master-setup/payment-type": "Payment Types",
  "/master-setup/payment-type/create": "Add Payment Type",
  "/master-setup/payment-type/details/:id": "Payment Type Details",
  "/master-setup/payment-type/update/:id": "Update Payment Type",

  // Master Setup - Lens Code
  "/master-setup/lens-code": "Lens Code Spectacle",
  "/master-setup/lens-code/create": "Add Lens Code Spectacle",
  "/master-setup/lens-code/details/:id": "Lens Code Spectacle Details",
  "/master-setup/lens-code/update/:id": "Update Lens Code Spectacle",

  // Master Setup - Frame Group
  "/master-setup/frame-group": "Frame Group",
  "/master-setup/frame-group/create": "Add Frame Group",
  "/master-setup/frame-group/details/:id": "Frame Group Details",
  "/master-setup/frame-group/update/:id": "Update Frame Group",

  // Master Setup - Support Ticket Type
  "/master-setup/support-ticket-type": "Support Ticket Types",
  "/master-setup/support-ticket-type/create": "Add Support Ticket Type",
  "/master-setup/support-ticket-type/details/:id": "Support Ticket Type Details",
  "/master-setup/support-ticket-type/update/:id": "Update Support Ticket Type",

  "/master-setup/frame-type": "Frame Types",
  "/master-setup/working-hours": "Working Hours",
  "/master-setup/appointment-type": "Appointment Types",
  "/master-setup/practitioner-setup": "Practitioner Setup",

  // Financial
  "/financial/petty-cash": "Petty Cash",
  "/financial/banking": "Banking",
  "/financial/bulk-billing-accounts": "Bulk Billing Accounts",
  "/financial/bulk-billing-payment": "Bulk Billing Payments",
  "/financial/bulk-billing-outstanding-accounts": "Outstanding Bulk Billing Accounts",
  "/financial/supplier-invoice-credit-report": "Supplier Invoice / Credit Report",
  "/financial/banking-reconciliation": "Bank Reconciliation",
  "/financial/billing-reports-summary-yearly-graph": "Billing Summary Yearly Graph",
  "/financial/billing-reports-ssf-item-summary": "SSF Billing Item Summary",
  "/financial/billing-serach-from-item-number": "Billing Search by Item No.",
  "/financial/payment-summary": "Payment Summary",

  // Help & Support
  "/help-support": "Help & Support",
  "/ticket-types": "Support Ticket Types",

  // Not Found
  "*": "Page Not Found",
};
