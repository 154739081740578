import React from "react";
import { Card, Divider, Descriptions, Table } from "antd";
import { subscriptionPackages } from "../data";

const boldLabelStyle = { fontWeight: "bold", color: "#141414" };

const SubscriptionCard: React.FC = ({}) => {
  const subscription = subscriptionPackages[0];

  const features = [
    { key: "1", serial: 1, name: "Inventory" },
    { key: "2", serial: 2, name: "Consultation" },
    { key: "3", serial: 3, name: "Appointments" },
  ];

  const columns = [
    {
      title: "No.",
      dataIndex: "serial",
      key: "serial",
      width: "10%",
    },
    {
      title: "Feature",
      dataIndex: "name",
      key: "name",
      width: "90%",
    },
  ];
  return (
    <Card
      style={{
        padding: "20px 20px",
        boxShadow: "0px 8px 40px -10px #00000014",
        borderRadius: "4px",
      }}
    >
      <Descriptions column={3} bordered={false} layout="vertical">
        <Descriptions.Item label={<span style={boldLabelStyle}>Description</span>}>
          {subscription.description}
        </Descriptions.Item>
        <Descriptions.Item label={<span style={boldLabelStyle}>Allowed User Types</span>}>
          {subscription.allowUserType.join(", ")}
        </Descriptions.Item>
        <Descriptions.Item label={<span style={boldLabelStyle}>Trial</span>}>
          {subscription.trial.enable ? subscription.trial.text : "No trial available"}
        </Descriptions.Item>
        <Descriptions.Item label={<span style={boldLabelStyle}>Annual Cost</span>}>
          {subscription.annualCost.enable
            ? `$${subscription.annualCost.cost} (Tax: ${subscription.annualCost.taxRate}%, Discount: ${subscription.annualCost.discountPercent}%)`
            : "N/A"}
        </Descriptions.Item>
        <Descriptions.Item label={<span style={boldLabelStyle}>Monthly Cost</span>}>
          {subscription.monthlyCost.enable
            ? `$${subscription.monthlyCost.cost} (Tax: ${subscription.monthlyCost.taxRate}%, Discount: ${subscription.monthlyCost.discountPercent}%)`
            : "N/A"}
        </Descriptions.Item>
        <Descriptions.Item label={<span style={boldLabelStyle}>Storage</span>}>
          {subscription.storage.base} GB
        </Descriptions.Item>
        <Descriptions.Item label={<span style={boldLabelStyle}>Features</span>}>
          {subscription.plans.map((plan) => (
            <div key={plan.planName}>
              {plan.planName} - {plan.allowed ? "Allowed" : "Not Allowed"}
            </div>
          ))}
        </Descriptions.Item>
        <Descriptions.Item label={<span style={boldLabelStyle}>Subtitle</span>}>
          {subscription.subtitle}
        </Descriptions.Item>
      </Descriptions>

      <Divider orientation="left" orientationMargin="0">
        Features
      </Divider>

      <Table columns={columns} dataSource={features} pagination={false} bordered={false} />
    </Card>
  );
};

export default SubscriptionCard;
