/** @jsxImportSource @emotion/react */
import React from "react";
import { Form, Button, Space, Input, Select, DatePicker, Checkbox, Row, Col } from "antd";
import TextArea from "antd/es/input/TextArea";
import { PlusOutlined } from "@ant-design/icons";
import { ReactComponent as SaveIcon } from "assets/icon3.svg";
import { ReactComponent as CopyIcon } from "assets/icon1.svg";
import { ReactComponent as ClipboardIcon } from "assets/icon2.svg";
import { ReactComponent as UserIcon } from "assets/icon5.svg";
import { ReactComponent as PatientIcon } from "assets/icon6.svg";
import { NavLink } from "react-router-dom";
import { StyledDrawer } from "../styles";
import { useAppointmentContext } from "../context";

const { Option } = Select;

const AppointmentForm = () => {
  const [form] = Form.useForm();
  const { isEditMode, setIsEditMode, open, setOpen } = useAppointmentContext();

  const onFinish = (values: any) => {
    console.log("Form values:", values);
    // Handle form submission here (e.g., API call)
  };

  return (
    <StyledDrawer
      closable
      destroyOnClose
      title={isEditMode ? "Edit Appointment" : "New Appointment"}
      placement="right"
      open={open}
      onClose={() => {
        setOpen(false);
        setIsEditMode(false);
      }}
      width={500}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        initialValues={{
          enableMessage: true, // Set initial values as needed
        }}
      >
        {/* Form Header Section */}
        <Form.Item label="" style={{ marginBottom: "16px" }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <Button.Group>
                <Button type="primary">Walk In</Button>
                <Button>Appointment</Button>
              </Button.Group>
            </div>
            <Space>
              <Button icon={<CopyIcon />} />
              <Button icon={<ClipboardIcon />} />
              <Button type="primary" icon={<SaveIcon />}>
                Save
              </Button>
            </Space>
          </div>
        </Form.Item>

        <Row gutter={[18, 24]}>
          {/* First Name */}
          <Col span={12}>
            <Form.Item
              name="firstName"
              label="First Name"
              rules={[{ required: true, message: "This is required" }]}
            >
              <Input placeholder="Enter your first name" />
            </Form.Item>
          </Col>

          {/* Last Name */}
          <Col span={12}>
            <Form.Item
              name="lastName"
              label="Last Name"
              rules={[{ required: true, message: "This is required" }]}
            >
              <Input placeholder="Enter your last name" />
            </Form.Item>
          </Col>

          {/* Mobile Number */}
          <Col span={12}>
            <Form.Item
              name="mobileNumber"
              label="Mobile Number"
              rules={[{ required: true, message: "This is required" }]}
            >
              <Input placeholder="Enter your mobile number" />
            </Form.Item>
          </Col>

          {/* Date of Birth */}
          <Col span={12}>
            <Form.Item name="dateOfBirth" label="Date of Birth">
              <DatePicker style={{ width: "100%" }} placeholder="Select date of birth" />
            </Form.Item>
          </Col>

          {/* Email */}
          <Col span={12}>
            <Form.Item
              name="email"
              label="Email"
              rules={[{ type: "email", message: "Invalid email type" }]}
            >
              <Input placeholder="Enter your email" />
            </Form.Item>
          </Col>

          {/* Gender */}
          <Col span={12}>
            <Form.Item name="gender" label="Gender">
              <Select placeholder="Select gender">
                <Option value="male">Male</Option>
                <Option value="female">Female</Option>
                <Option value="other">Other</Option>
              </Select>
            </Form.Item>
          </Col>

          {/* Patient Section */}
          <Col span={24}>
            <Form.Item label="">
              <Space>
                <Button
                  type="primary"
                  icon={<PatientIcon />}
                  style={{ backgroundColor: "#263446" }}
                >
                  <NavLink to="/patient-records">Find or create patient record</NavLink>
                </Button>
                <Button icon={<UserIcon />}>Recent Patient</Button>
              </Space>
            </Form.Item>
          </Col>

          {/* Appointment Type */}
          <Col span={24}>
            <Form.Item name="appointmentType" label="Appointment Type">
              <Select placeholder="Select type">
                <Option value="walk">Walk In</Option>
                <Option value="appointment">Appointment</Option>
              </Select>
            </Form.Item>
          </Col>

          {/* Date and Time */}
          <Col span={12}>
            <Form.Item name="dateAndTime" label="Date and Time">
              <DatePicker showTime style={{ width: "100%" }} placeholder="Select date and time" />
            </Form.Item>
          </Col>

          {/* Room */}
          <Col span={12}>
            <Form.Item name="room" label="Room">
              <Select placeholder="Select room">
                <Option value="room1">Room 1</Option>
                <Option value="room2">Room 2</Option>
              </Select>
            </Form.Item>
          </Col>

          {/* Status */}
          <Col span={12}>
            <Form.Item name="status" label="Status">
              <Select placeholder="Select status">
                <Option value="scheduled">Scheduled</Option>
                <Option value="completed">Completed</Option>
                <Option value="cancelled">Cancelled</Option>
              </Select>
            </Form.Item>
          </Col>

          {/* Scheduled Practitioner */}
          <Col span={12}>
            <Form.Item name="scheduledPractitioner" label="Scheduled Practitioner">
              <Select placeholder="Select practitioner">
                <Option value="dr_smith">Dr. Smith</Option>
                <Option value="dr_jones">Dr. Jones</Option>
              </Select>
            </Form.Item>
          </Col>

          {/* Booking Notes */}
          <Col span={24}>
            <Form.Item name="bookingNotes" label="Booking Notes">
              <TextArea rows={3} placeholder="Enter booking notes" />
            </Form.Item>
          </Col>

          {/* Enable Message Checkbox */}
          <Col span={24}>
            <Form.Item name="enableMessage" valuePropName="checked">
              <Checkbox defaultChecked>
                Enable message to the patient for this appointment booking.
              </Checkbox>
            </Form.Item>
          </Col>

          {/* Add Booking Person Button */}
          <Col span={24}>
            <Form.Item>
              <Button type="primary" icon={<PlusOutlined />} style={{ fontSize: "13px" }}>
                Add Booking Person
              </Button>
            </Form.Item>
          </Col>
        </Row>

        <Row justify={"end"}>
          <Space>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
            <Button type="default" onClick={() => form.resetFields()}>
              Reset
            </Button>
          </Space>
        </Row>
      </Form>
    </StyledDrawer>
  );
};

export default AppointmentForm;
