/** @jsxImportSource @emotion/react */
import React, { useEffect } from "react";
import { Row, Card, Button, Typography } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import LoadingCard from "components/LoadingComponent";
import PageTemplate from "components/PageTemplate";
import TicketList from "./SupportTicket/SupportTicketList";
import TicketDetail from "./SupportTicket/SupportTicketDetails";
import AddIssueTicket from "./SupportTicket/SupportTicketAdd";
import { useTicketManagement } from "../hooks/useTicketManagement";
import { useSocket } from "hooks/useSocket";
import { useSocketMessages } from "hooks/useSocketMessages";
import { SupportTicket } from "services/SupportTicketService";

const { Title } = Typography;

const HelpAndSupport: React.FC = () => {
  const {
    state,
    dispatch,
    handleSendMessage,
    handleMarkComplete,
    tickets,
    ticketTypes,
    ticketsLoading,
  } = useTicketManagement();

  const { selectedTicket, messages, message, isModalOpen } = state;
  const { socket } = useSocket(selectedTicket?._id);
  const fetchedMessages = useSocketMessages(socket, selectedTicket?._id);

  useEffect(() => {
    dispatch({ type: "SET_MESSAGES", payload: fetchedMessages });
  }, [fetchedMessages, dispatch]);

  const setSelectedTicket = (ticket: SupportTicket | null) => {
    dispatch({ type: "SET_SELECTED_TICKET", payload: ticket });
  };

  const setMessage = (msg: string) => {
    dispatch({ type: "SET_MESSAGE", payload: msg });
  };

  const setModalOpen = (isOpen: boolean) => {
    dispatch({ type: "SET_MODAL_OPEN", payload: isOpen });
  };

  //   if (ticketsLoading) return <LoadingCard />;

  return (
    <PageTemplate currentNode="Help and Support">
      <Card>
        <div>
          <Row justify="end" style={{ marginBottom: "18px" }}>
            <Button
              onClick={() => dispatch({ type: "SET_MODAL_OPEN", payload: true })}
              icon={<PlusOutlined />}
              type="primary"
            >
              Create Issue Ticket
            </Button>
          </Row>
          <Row gutter={[24, 24]}>
            <TicketList
              tickets={tickets}
              setSelectedTicket={setSelectedTicket}
              selectedTicket={selectedTicket}
              ticketTypes={ticketTypes}
            />
            <TicketDetail
              selectedTicket={selectedTicket}
              messages={[
                {
                  _id: "64f2b2f1e2b4f9d1d8b4e301",
                  isDescription: false,
                  isRead: false,
                  content: "Hi, I noticed an issue with the app when trying to submit a form.",
                  type: "text",
                  receiver: "64f2b2f1e2b4f9d1d8b4e2c1", // Support agent
                  sender: "64f2b2f1e2b4f9d1d8b4e2c2", // User
                  ticketId: "64f2b2f1e2b4f9d1d8b4e2c3",
                  version: 0,
                  createdAt: new Date(),
                  updatedAt: new Date(),
                },
                {
                  _id: "64f2b2f1e2b4f9d1d8b4e302",
                  isDescription: true,
                  isRead: true,
                  content: "Thanks for reporting the issue. Could you please provide more details?",
                  type: "text",
                  receiver: "64f2b2f1e2b4f9d1d8b4e2c2", // User
                  sender: "64f2b2f1e2b4f9d1d8b4e2c1", // Support agent
                  ticketId: "64f2b2f1e2b4f9d1d8b4e2c3",
                  version: 0,
                  createdAt: new Date(),
                  updatedAt: new Date(),
                },
                {
                  _id: "64f2b2f1e2b4f9d1d8b4e303",
                  isDescription: false,
                  isRead: true,
                  content:
                    "Sure, the issue happens when I click the submit button after filling out the form.",
                  type: "text",
                  receiver: "64f2b2f1e2b4f9d1d8b4e2c1", // Support agent
                  sender: "64f2b2f1e2b4f9d1d8b4e2c2", // User
                  ticketId: "64f2b2f1e2b4f9d1d8b4e2c3",
                  version: 1,
                  createdAt: new Date(),
                  updatedAt: new Date(),
                },
                {
                  _id: "64f2b2f1e2b4f9d1d8b4e304",
                  isDescription: true,
                  isRead: false,
                  content:
                    "Thanks for the details. We will investigate and get back to you shortly.",
                  type: "text",
                  receiver: "64f2b2f1e2b4f9d1d8b4e2c2", // User
                  sender: "64f2b2f1e2b4f9d1d8b4e2c1", // Support agent
                  ticketId: "64f2b2f1e2b4f9d1d8b4e2c3",
                  version: 2,
                  createdAt: new Date(),
                  updatedAt: new Date(),
                },
                {
                  _id: "64f2b2f1e2b4f9d1d8b4e305",
                  isDescription: false,
                  isRead: true,
                  content: "Great, thank you! I'll wait for your update.",
                  type: "text",
                  receiver: "64f2b2f1e2b4f9d1d8b4e2c1", // Support agent
                  sender: "64f2b2f1e2b4f9d1d8b4e2c2", // User
                  ticketId: "64f2b2f1e2b4f9d1d8b4e2c3",
                  version: 1,
                  createdAt: new Date(),
                  updatedAt: new Date(),
                },
              ]}
              message={message}
              setMessage={setMessage}
              handleSendMessage={() => handleSendMessage(message, selectedTicket, socket)}
              handleMarkComplete={() => handleMarkComplete(selectedTicket)}
            />
          </Row>
        </div>
      </Card>
      <AddIssueTicket isModalOpen={isModalOpen} setIsModalOpen={setModalOpen} />
    </PageTemplate>
  );
};

export default HelpAndSupport;
