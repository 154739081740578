/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React from "react";
import { Col, Card, Table, Form, Typography, Row, Button } from "antd";
import MatrixTableCard from "../cards/MatrixTableCard";
import {
  kReadingColumns,
  pdColumns,
  visualAcuityColumns,
  averageColumns,
  cylColumns,
} from "../handlers/tableColumns";
import { kReadingData, matrixTableData2, matrixTableData3 } from "../data";
import TextArea from "antd/es/input/TextArea";
import IOPCard from "../cards/IOPCard";
import BinocularCard from "../cards/BinoccularCard";
import NotesCard from "../cards/NotestCard";
import TherapeuticPrescription from "./TherepeuticPrescription";
import { cardStyle, StyledTable } from "../styles";

const KReadingSection: React.FC = () => {
  return (
    <>
      <Col span={8} style={{ display: "flex", flexDirection: "column" }}>
        <BinocularCard />
      </Col>
      <Col span={16}>
        <Row gutter={[6, 6]} style={{ display: "flex", flexWrap: "wrap" }}>
          <Col span={12} style={{ display: "flex", flexDirection: "column" }}>
            <MatrixTableCard
              title="Interpupillary Distance"
              dataSource={matrixTableData2}
              columns={pdColumns}
            />
          </Col>
          <Col span={12} style={{ display: "flex", flexDirection: "column" }}>
            <MatrixTableCard
              title="Visual Acuity"
              dataSource={matrixTableData3}
              columns={visualAcuityColumns}
            />
          </Col>
          <Col span={12} style={{ display: "flex", flexDirection: "column" }}>
            <Card css={cardStyle} style={{ flex: 1 }} title="K Reading">
              <StyledTable
                columns={kReadingColumns}
                dataSource={kReadingData}
                pagination={false}
                size="small"
                rowKey="key"
                showHeader
              />
              <Typography.Title level={5} style={{ margin: "8px 0px" }}>
                Average
              </Typography.Title>
              <StyledTable
                columns={averageColumns}
                dataSource={kReadingData}
                pagination={false}
                size="small"
                rowKey="key"
                showHeader
              />
              <Typography.Title level={5} style={{ margin: "8px 0px" }}>
                Cyl
              </Typography.Title>
              <StyledTable
                columns={cylColumns}
                dataSource={kReadingData}
                pagination={false}
                size="small"
                rowKey="key"
                showHeader
              />
              <Form layout="vertical" style={{ marginTop: "6px" }}>
                <Form.Item label="Notes" name="notes">
                  <TextArea rows={2} />
                </Form.Item>
              </Form>
            </Card>
          </Col>
          <Col span={12}>
            <Row gutter={[6, 6]}>
              <Col span={24}>
                <NotesCard title="Visual Field" />
              </Col>
              <Col span={24}>
                <NotesCard title="Color Vision" />
              </Col>
              <Col span={24}>
                <NotesCard title="Pupils" />
              </Col>
              <Col span={24}>
                <NotesCard title="Opthalmoscopy" />
              </Col>
              <Col span={24}>
                <NotesCard title="Biomicroscopy" />
              </Col>
            </Row>
          </Col>
          <Col span={12} style={{ display: "flex", flexDirection: "column" }}>
            <IOPCard />
          </Col>
          <Col span={12} style={{ display: "flex", flexDirection: "column" }}>
            <TherapeuticPrescription />
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default KReadingSection;
