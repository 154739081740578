import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Form, FormInstance, Button, Card, Divider, Row, Space } from "antd";
import { UseMutationResult, UseQueryResult } from "@tanstack/react-query";
import { AxiosError } from "axios";
import DynamicFormBuilder from "components/DynamicFormBuilder";
import LoadingCard from "components/LoadingComponent";
import { FormFieldConfig, SectionConfig } from "types/form.config.type";
import styled from "@emotion/styled";

const WholeFormCardContainer = styled(Card)`
  border-radius: 4px;
  box-shadow: 0px 8px 40px -10px #00000014;
  background: #ffffff;
  padding: 24px;
`;

type FieldsConfigType = FormFieldConfig[] | Record<string, SectionConfig>;

interface WithFormHandlerProps<T> {
  useCreate: () => UseMutationResult<T, AxiosError, T>;
  useUpdate: () => UseMutationResult<T, AxiosError, { id: string; data: T }>;
  useFetchById: (id: string) => UseQueryResult<T, AxiosError>;
  onSuccess: () => void;
  onError: (error: AxiosError) => void;
  fieldsConfig: FieldsConfigType;
  wrapInCard?: boolean;
  redirectUrl?: string;
  initialValues?: T;
  id?: string;
}

const withFormHandler = <T extends Record<string, any>>(
  WrappedFormComponent: React.ComponentType<{
    form: FormInstance<T>;
    fieldsConfig: FieldsConfigType;
  }>,
  {
    useCreate,
    useUpdate,
    useFetchById,
    onSuccess,
    onError,
    fieldsConfig,
    wrapInCard = false,
    redirectUrl,
    initialValues,
    id,
  }: WithFormHandlerProps<T>
) => {
  return (props: any) => {
    const navigate = useNavigate();
    const [form] = Form.useForm<T>();

    const { mutate: create, isPending: isCreating } = useCreate();
    const { mutate: update, isPending: isUpdating } = useUpdate();
    const { data: item, isLoading: isLoadingItem } = useFetchById(id ? id : "");

    useEffect(() => {
      if (id && item) {
        form.setFieldsValue(item);
      } else if (!id && initialValues) {
        form.setFieldsValue(initialValues);
      }
    }, [id, item, initialValues, form]);

    const handleFinish = async (values: T) => {
      try {
        if (id) {
          update(
            { id: id, data: values },
            {
              onSuccess: () => {
                onSuccess();
                redirectUrl && navigate(redirectUrl);
              },
              onError,
            }
          );
        } else {
          create(values, {
            onSuccess: () => {
              form.resetFields();
              onSuccess();
              navigate("/");
            },
            onError,
          });
        }
      } catch (error) {
        onError(error as AxiosError);
      }
    };

    // if (isLoadingItem) return <LoadingCard />;

    const FormContent = (
      <Form form={form} layout="vertical" onFinish={handleFinish}>
        <DynamicFormBuilder form={form} fieldsConfig={fieldsConfig} />
        <Divider />
        <Row justify={"end"}>
          <Space>
            <Form.Item>
              <Button onClick={() => navigate(redirectUrl ? redirectUrl : "/")}>Cancel</Button>
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={isCreating || isUpdating}>
                {id ? "Update Item" : "Create Item"}
              </Button>
            </Form.Item>
          </Space>
        </Row>
      </Form>
    );

    return wrapInCard ? (
      <WholeFormCardContainer>{FormContent}</WholeFormCardContainer>
    ) : (
      FormContent
    );
  };
};

export default withFormHandler;
