import React, { useState } from "react";
import PageTemplate from "components/PageTemplate";
import { useNavigate } from "react-router-dom";
import { Table, Button, Dropdown, Menu, Row, Modal, Form, Input, Card, Col } from "antd";
import { MoreOutlined, EditOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";
// import { usePractitionerService } from "services/PractitionerService";
import useConfirmDelete from "hooks/useConfirmDelete";

const PractitionerSetup: React.FC = () => {
  const navigate = useNavigate();
  // const { useGetAll, useDelete } = usePractitionerService();
  // const { mutateAsync: deletePractitioner, isPending } = useDelete();
  // const { confirmDelete } = useConfirmDelete(deletePractitioner, isPending);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();

  const data = [
    {
      key: "1",
      practitionerName: "Dr. John Doe",
      emailId: "johndoe@example.com",
      mobileNo: "1234567890",
      licenseNo: "LIC001",
      status: "Active",
      experience: "10 years",
    },
    {
      key: "2",
      practitionerName: "Dr. Jane Smith",
      emailId: "janesmith@example.com",
      mobileNo: "0987654321",
      licenseNo: "LIC002",
      status: "Inactive",
      experience: "8 years",
    },
  ];

  const handleMenuClick = (key: any, record: any) => {
    switch (key) {
      case "view":
        navigate(`/practitioner-setup/details/${record?.key}`);
        break;
      case "update":
        navigate(`/practitioner-setup/update/${record?.key}`);
        break;
      case "delete":
        // record?.key && confirmDelete(record?.key);
        break;
      default:
        break;
    }
  };

  const actionMenu = (record: any) => (
    <Menu>
      <Menu.Item
        key="update"
        icon={<EditOutlined />}
        onClick={() => handleMenuClick("update", record)}
      >
        Update
      </Menu.Item>
      <Menu.Item
        key="delete"
        icon={<DeleteOutlined />}
        onClick={() => handleMenuClick("delete", record)}
        danger
      >
        Delete
      </Menu.Item>
    </Menu>
  );

  const columns = [
    {
      title: "SN",
      dataIndex: "key",
      render: (text: any) => <span>{text}</span>,
    },
    {
      title: "Practitioner Name",
      dataIndex: "practitionerName",
    },
    {
      title: "Email ID",
      dataIndex: "emailId",
    },
    {
      title: "Mobile No",
      dataIndex: "mobileNo",
    },
    {
      title: "License No",
      dataIndex: "licenseNo",
    },
    {
      title: "Status",
      dataIndex: "status",
    },
    {
      title: "Experience",
      dataIndex: "experience",
    },
    {
      title: "Action",
      render: (text: any, record: any) => (
        <Dropdown overlay={actionMenu(record)} trigger={["click"]}>
          <Button icon={<MoreOutlined />} />
        </Dropdown>
      ),
    },
  ];

  const handleAddPractitioner = () => {
    form.resetFields();
    setIsModalVisible(true);
  };

  const handleModalOk = () => {
    form.validateFields().then((values) => {
      console.log("Received values from form: ", values);
      setIsModalVisible(false);
    });
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <PageTemplate>
      <Card
        style={{
          boxShadow: "0px 8px 40px -10px #00000014",
          borderRadius: "4px",
        }}
      >
        <Row justify="end">
          <Button type="primary" icon={<PlusOutlined />} onClick={handleAddPractitioner}>
            Add Practitioner
          </Button>
        </Row>

        <div style={{ marginTop: "18px" }}>
          <Table columns={columns} dataSource={data} />
        </div>
      </Card>

      <Modal
        title="Add Practitioner"
        visible={isModalVisible}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
      >
        <Form form={form} layout="vertical">
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="practitionerName"
                label="Practitioner Name"
                rules={[{ required: true, message: "Please enter practitioner name!" }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="emailId"
                label="Email ID"
                rules={[
                  { required: true, type: "email", message: "Please enter a valid email ID!" },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="mobileNo"
                label="Mobile No"
                rules={[{ required: true, message: "Please enter mobile number!" }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="licenseNo"
                label="License No"
                rules={[{ required: true, message: "Please enter license number!" }]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="experience"
                label="Experience"
                rules={[{ required: true, message: "Please enter experience!" }]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="specialization"
                label="Specialization"
                rules={[{ required: true, message: "Please enter specialization!" }]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name="notes" label="Notes">
                <Input.TextArea />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </PageTemplate>
  );
};

export default PractitionerSetup;
