import React from "react";
import { Card, Divider, Row, Col, Form, Input, Button, Select, Upload, Switch } from "antd";
import { UploadOutlined } from "@ant-design/icons";

const { Option } = Select;
const boldLabelStyle = { fontWeight: "bold", color: "#141414" };

const PatientRecordForm: React.FC = () => {
  const handleFinish = (values: any) => {
    console.log("Form values: ", values);
  };

  return (
    <Card
      style={{
        boxShadow: "0px 8px 40px -10px #00000014",
        borderRadius: "4px",
      }}
    >
      <Form layout="vertical" onFinish={handleFinish}>
        {/* Personal Details Section */}
        <Divider orientation="left" orientationMargin="0" style={{ marginBottom: "34px" }}>
          Personal Details
        </Divider>
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item
              label={<span style={boldLabelStyle}>Patient Name</span>}
              name="patientName"
              rules={[{ required: true, message: "Patient Name is required" }]}
            >
              <Input placeholder="Patient Name" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={<span style={boldLabelStyle}>Gender</span>}
              name="gender"
              rules={[{ required: true, message: "Gender is required" }]}
            >
              <Select placeholder="Select Gender">
                <Option value="male">Male</Option>
                <Option value="female">Female</Option>
                <Option value="other">Other</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={<span style={boldLabelStyle}>Date of Birth</span>}
              name="dateOfBirth"
              rules={[{ required: true, message: "Date of Birth is required" }]}
            >
              <Input type="date" placeholder="Date of Birth" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={<span style={boldLabelStyle}>Age</span>}
              name="age"
              rules={[{ required: true, message: "Age is required" }]}
            >
              <Input placeholder="Age" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={<span style={boldLabelStyle}>Doctor (GP)</span>}
              name="gpDoctor"
              rules={[{ required: false }]}
            >
              <Input placeholder="Doctor (GP)" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={<span style={boldLabelStyle}>Ophthalmologist</span>}
              name="ophthalmologist"
              rules={[{ required: false }]}
            >
              <Input placeholder="Ophthalmologist" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={<span style={boldLabelStyle}>Assistance Required?</span>}
              name="assistanceRequired"
              rules={[{ required: true, message: "Please specify assistance" }]}
            >
              <Switch checkedChildren="Yes" unCheckedChildren="No" />
            </Form.Item>
          </Col>
        </Row>

        {/* Contact Details Section */}
        <Divider orientation="left" orientationMargin="0" style={{ margin: "40px 0 34px 0" }}>
          Contact Details
        </Divider>
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item
              label={<span style={boldLabelStyle}>Residential Address</span>}
              name="residentialAddress"
              rules={[{ required: true, message: "Address is required" }]}
            >
              <Input placeholder="Residential Address" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={<span style={boldLabelStyle}>Home Phone No.</span>}
              name="homePhone"
              rules={[{ required: false }]}
            >
              <Input placeholder="Home Phone No." />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={<span style={boldLabelStyle}>Mobile No.</span>}
              name="mobileNo"
              rules={[{ required: true, message: "Mobile No. is required" }]}
            >
              <Input placeholder="Mobile No." />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={<span style={boldLabelStyle}>Work Phone No.</span>}
              name="workPhone"
              rules={[{ required: false }]}
            >
              <Input placeholder="Work Phone No." />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={<span style={boldLabelStyle}>Email ID</span>}
              name="email"
              rules={[{ required: true, message: "Email ID is required" }]}
            >
              <Input placeholder="Email ID" />
            </Form.Item>
          </Col>
        </Row>

        {/* Lifestyle Details Section */}
        <Divider orientation="left" orientationMargin="0" style={{ margin: "40px 0 34px 0" }}>
          Lifestyle Details
        </Divider>
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item
              label={<span style={boldLabelStyle}>Occupation</span>}
              name="occupation"
              rules={[{ required: true, message: "Occupation is required" }]}
            >
              <Input placeholder="Occupation" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={<span style={boldLabelStyle}>Hobbies</span>}
              name="hobbies"
              rules={[{ required: false }]}
            >
              <Input placeholder="Hobbies" />
            </Form.Item>
          </Col>
        </Row>

        {/* Form Actions */}
        <Row justify={"end"}>
          <div>
            <Button>Cancel</Button>
            <Button
              type="primary"
              htmlType="submit"
              style={{ marginTop: "34px", marginLeft: "12px" }}
            >
              Submit
            </Button>
          </div>
        </Row>
      </Form>
    </Card>
  );
};

export default PatientRecordForm;
