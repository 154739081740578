/** @jsxImportSource @emotion/react */
import React, { useState } from "react";
import { Row, Col, Card, Button, Dropdown, Menu } from "antd";
import RxTableSection from "./RxTableSection";
import NotesSection from "./NotesSection";
import KReadingSection from "./kReadingSection";
import { initialData } from "../data";
import { DataType } from "../data";
import { PlusOutlined } from "@ant-design/icons";
import { cardStyle } from "../styles";

const DefaultLayout: React.FC = () => {
  const [data, setData] = useState<DataType[]>(initialData);
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const addRow = (itemName: string) => {
    const newRow: DataType = {
      key: (data.length + 1).toString(),
      rowLabel: itemName,
      leftSph: "",
      leftCyl: "",
      leftAxis: "",
      leftAdd: "",
      leftInter: "",
      leftVa: "",
      rightSph: "",
      rightCyl: "",
      rightAxis: "",
      rightAdd: "",
      rightInter: "",
      rightVa: "",
    };
    setData([...data, newRow]);
    setDropdownVisible(false);
  };

  const handleMenuClick = (e: any) => {
    addRow(e.key);
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="Lensmeter">Lensmeter</Menu.Item>
      <Menu.Item key="Autorefractor">Autorefractor</Menu.Item>
      <Menu.Item key="Retinoscope">Retinoscope</Menu.Item>
    </Menu>
  );

  return (
    <Row gutter={[6, 6]} style={{ display: "flex", flexWrap: "wrap" }}>
      <Col span={24}>
        <Card css={cardStyle}>
          <RxTableSection data={data} setData={setData} showActions={true} />
          <div style={{ marginTop: 6, textAlign: "end" }}>
            <Dropdown
              overlay={menu}
              trigger={["click"]}
              visible={dropdownVisible}
              onVisibleChange={setDropdownVisible}
            >
              <Button type="primary" icon={<PlusOutlined />} size="small">
                Add Row
              </Button>
            </Dropdown>
          </div>
        </Card>
      </Col>
      <KReadingSection />
    </Row>
  );
};

export default DefaultLayout;
