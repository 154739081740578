import PageTemplate from "components/PageTemplate";
import { ROUTES } from "constants/routeConstants";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Table, Button, Dropdown, Menu, Row, Card } from "antd";
import {
  MoreOutlined,
  EyeOutlined,
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { useSupplierService } from "services/SupplierService/useSupplierService";
import useConfirmDelete from "hooks/useConfirmDelete";

const SupplierListPage = () => {
  const navigate = useNavigate();
  const { useGetAll, useDelete } = useSupplierService();
  //   const { data: supplierData, isLoading, refetch } = useGetAll();
  const { mutateAsync: deleteSupplier, isPending } = useDelete();
  const { confirmDelete } = useConfirmDelete(deleteSupplier, isPending);

  const data = [
    {
      key: "1",
      supplierCode: "SUP001",
      supplierName: "Supplier A",
      alias: "Alias A",
      abn: "ABN001",
      contactName: "John Doe",
      phoneNo: "123-456-7890",
      emailId: "supplierA@example.com",
    },
    {
      key: "2",
      supplierCode: "SUP002",
      supplierName: "Supplier B",
      alias: "Alias B",
      abn: "ABN002",
      contactName: "Jane Smith",
      phoneNo: "098-765-4321",
      emailId: "supplierB@example.com",
    },
  ];

  const handleMenuClick = (key: string, record: any) => {
    switch (key) {
      case "view":
        navigate(`/master-setup/supplier/details/${record?.key}`);
        break;
      case "update":
        navigate(`/master-setup/supplier/update/${record?.key}`);
        break;
      case "delete":
        record?.key && confirmDelete(record?.key);
        break;
      default:
        break;
    }
  };

  const actionMenu = (record: any) => (
    <Menu>
      <Menu.Item key="view" icon={<EyeOutlined />} onClick={() => handleMenuClick("view", record)}>
        View details
      </Menu.Item>
      <Menu.Item
        key="update"
        icon={<EditOutlined />}
        onClick={() => handleMenuClick("update", record)}
      >
        Update supplier
      </Menu.Item>
      <Menu.Item
        key="delete"
        icon={<DeleteOutlined />}
        onClick={() => handleMenuClick("delete", record)}
        danger
      >
        Delete supplier
      </Menu.Item>
    </Menu>
  );

  const columns = [
    {
      title: "SN",
      dataIndex: "key",
      render: (text: string) => <span>{text}</span>,
    },
    {
      title: "Supplier Code",
      dataIndex: "supplierCode",
    },
    {
      title: "Supplier Name",
      dataIndex: "supplierName",
    },
    {
      title: "Alias",
      dataIndex: "alias",
    },
    {
      title: "ABN",
      dataIndex: "abn",
    },
    {
      title: "Contact Name",
      dataIndex: "contactName",
    },
    {
      title: "Phone No.",
      dataIndex: "phoneNo",
    },
    {
      title: "Email ID",
      dataIndex: "emailId",
    },
    {
      title: "Action",
      render: (text: any, record: any) => (
        <Dropdown overlay={actionMenu(record)} trigger={["click"]}>
          <Button icon={<MoreOutlined />} />
        </Dropdown>
      ),
    },
  ];

  return (
    <PageTemplate>
      <Card
        style={{
          boxShadow: "0px 8px 40px -10px #00000014",
          borderRadius: "4px",
        }}
      >
        <Row justify="end">
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => navigate(ROUTES.MASTER_SETUP.SUPPLIER.CREATE)}
          >
            Add Supplier
          </Button>
        </Row>

        <div style={{ marginTop: "18px" }}>
          <Table columns={columns} dataSource={data} />
        </div>
      </Card>
    </PageTemplate>
  );
};

export default SupplierListPage;
