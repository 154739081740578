import React, { useState } from "react";
import {
  Card,
  Row,
  Col,
  Menu,
  Divider,
  Button,
  Table,
  Form,
  Input,
  Radio,
  Dropdown,
  Modal,
} from "antd";
import { SearchOutlined, PlusOutlined, MoreOutlined } from "@ant-design/icons";

const PettyCashPage: React.FC = () => {
  const [selectedKey, setSelectedKey] = useState("pettyCashCode");
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleMenuClick = (e: any) => {
    setSelectedKey(e.key);
  };

  const handleAddCodeClick = () => {
    setIsModalVisible(true);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };
  const pettyCashEntryData = [
    {
      key: "1",
      sn: 1,
      code: "PC001",
      description: "Office Supplies",
      tax: 5,
      gst: 18,
      amount: 100.0,
    },
    {
      key: "2",
      sn: 2,
      code: "PC002",
      description: "Travel Reimbursement",
      tax: 10,
      gst: 18,
      amount: 250.0,
    },
    {
      key: "3",
      sn: 3,
      code: "PC003",
      description: "Food & Beverages",
      tax: 5,
      gst: 18,
      amount: 75.5,
    },
    {
      key: "4",
      sn: 4,
      code: "PC004",
      description: "Client Meeting Expenses",
      tax: 8,
      gst: 18,
      amount: 150.0,
    },
    {
      key: "5",
      sn: 5,
      code: "PC005",
      description: "Stationery",
      tax: 5,
      gst: 18,
      amount: 50.0,
    },
  ];

  const dataSource = [
    {
      key: "1",
      sn: 1,
      code: "PC001",
      description: "Office Supplies",
      tax: "10%",
      amount: "150.00",
    },
    { key: "2", sn: 2, code: "PC002", description: "Travel Expenses", tax: "5%", amount: "200.00" },
    { key: "3", sn: 3, code: "PC003", description: "Client Meeting", tax: "12%", amount: "300.00" },
  ];

  const renderContent = () => {
    switch (selectedKey) {
      case "pettyCashCode":
        return (
          <Card style={{ minHeight: "calc(100vh - 138px)", overflowY: "auto" }}>
            <Divider orientation="left" orientationMargin="0" style={{ marginBottom: "34px" }}>
              Petty Cash Code
            </Divider>
            <Row gutter={16} justify="space-between" align="middle">
              <Col span={8}>
                <Input placeholder="Search Code" prefix={<SearchOutlined />} />
              </Col>
              <Col>
                <Button type="primary" icon={<PlusOutlined />} onClick={handleAddCodeClick}>
                  Add Code
                </Button>
              </Col>
            </Row>

            {/* Table with dummy data */}
            <Table
              style={{ marginTop: "20px" }}
              dataSource={dataSource}
              columns={[
                { title: "SN", dataIndex: "sn", key: "sn" },
                { title: "Code", dataIndex: "code", key: "code" },
                { title: "Description", dataIndex: "description", key: "description" },
                { title: "Tax %", dataIndex: "tax", key: "tax" },
                { title: "Amount", dataIndex: "amount", key: "amount" },
                {
                  title: "Action",
                  key: "action",
                  render: (text, record) => (
                    <Dropdown
                      overlay={
                        <Menu>
                          <Menu.Item key="update">Update</Menu.Item>
                          <Menu.Item key="delete">Delete</Menu.Item>
                        </Menu>
                      }
                      trigger={["click"]}
                    >
                      <Button icon={<MoreOutlined />} />
                    </Dropdown>
                  ),
                },
              ]}
            />

            {/* Modal for Add Code */}
            <Modal
              title="Add Petty Cash Code"
              visible={isModalVisible}
              onCancel={handleModalCancel}
              footer={[
                <Button key="cancel" onClick={handleModalCancel}>
                  Cancel
                </Button>,
                <Button key="submit" type="primary">
                  Submit
                </Button>,
              ]}
            >
              <Form layout="vertical">
                <Form.Item label="Petty Cash Code" name="code" rules={[{ required: true }]}>
                  <Input placeholder="Enter Petty Cash Code" />
                </Form.Item>
                <Form.Item label="Amount" name="amount" rules={[{ required: true }]}>
                  <Input placeholder="Enter Amount" />
                </Form.Item>
                <Form.Item label="Tax Rate %" name="taxRate" rules={[{ required: true }]}>
                  <Input placeholder="Enter Tax Rate %" />
                </Form.Item>
                <Form.Item label="Description" name="description">
                  <Input placeholder="Enter Description" />
                </Form.Item>
              </Form>
            </Modal>
          </Card>
        );
      case "pettyCashEntry":
        return (
          <Card style={{ minHeight: "calc(100vh - 138px)", overflowY: "auto" }}>
            <Divider orientation="left" orientationMargin="0" style={{ marginBottom: "34px" }}>
              Petty Cash Entry
            </Divider>
            <Row gutter={16} justify="space-between" align="middle">
              <Col span={8}>
                <Input placeholder="Search Code" prefix={<SearchOutlined />} />
              </Col>
              <Col>
                <Button type="primary" icon={<PlusOutlined />} onClick={handleAddCodeClick}>
                  Add Entry
                </Button>
              </Col>
            </Row>

            {/* Table for Petty Cash Entry */}
            <Table
              style={{ marginTop: "20px" }}
              dataSource={pettyCashEntryData}
              columns={[
                { title: "SN", dataIndex: "sn", key: "sn" },
                { title: "Petty Cash Code", dataIndex: "code", key: "code" },
                { title: "Description", dataIndex: "description", key: "description" },
                { title: "Tax %", dataIndex: "tax", key: "tax" },
                { title: "GST %", dataIndex: "gst", key: "gst" },
                { title: "Amount", dataIndex: "amount", key: "amount" },
                {
                  title: "Action",
                  key: "action",
                  render: (text, record) => (
                    <Dropdown
                      overlay={
                        <Menu>
                          <Menu.Item key="update">Update</Menu.Item>
                          <Menu.Item key="delete">Delete</Menu.Item>
                        </Menu>
                      }
                      trigger={["click"]}
                    >
                      <Button icon={<MoreOutlined />} />
                    </Dropdown>
                  ),
                },
              ]}
            />

            {/* Modal for Add Petty Cash Entry */}
            <Modal
              title="Add Petty Cash Entry"
              visible={isModalVisible}
              onCancel={handleModalCancel}
              footer={[
                <Button key="cancel" onClick={handleModalCancel}>
                  Cancel
                </Button>,
                <Button key="submit" type="primary">
                  Submit
                </Button>,
              ]}
            >
              <Form layout="vertical">
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item label="Date" name="date" rules={[{ required: true }]}>
                      <Input placeholder="Enter Date" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="Petty Cash Code" name="code" rules={[{ required: true }]}>
                      <Input placeholder="Enter Petty Cash Code" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item label="Amount" name="amount" rules={[{ required: true }]}>
                      <Input placeholder="Enter Amount" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="Tax Rate %" name="taxRate" rules={[{ required: true }]}>
                      <Input placeholder="Enter Tax Rate %" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item label="GST %" name="gst" rules={[{ required: true }]}>
                      <Input placeholder="Enter GST %" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>{/* You can add additional form items here if needed */}</Col>
                </Row>
                <Row gutter={16}>
                  <Col span={24}>
                    <Form.Item label="Description" name="description">
                      <Input placeholder="Enter Description" />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Modal>
          </Card>
        );
      case "pettyCashReports":
        return (
          <Card style={{ minHeight: "calc(100vh - 138px)", overflowY: "auto" }}>
            <Divider orientation="left" orientationMargin="0" style={{ marginBottom: "34px" }}>
              Petty Cash Reports
            </Divider>
            <Form layout="vertical">
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="Start Date">
                    <Input placeholder="Start Date" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="End Date">
                    <Input placeholder="End Date" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item label="Report Type">
                    <Radio.Group>
                      <Radio value="detailByDate">Detail Report by Date</Radio>
                      <Radio value="detailByCode">Detail Report by Code</Radio>
                      <Radio value="summaryByCode">Summary Report by Code</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item label="Report Destination">
                    <Radio.Group>
                      <Radio value="screen">To Screen</Radio>
                      <Radio value="printer">To Printer</Radio>
                      <Radio value="printerWithPrompt">To Printer with Prompt</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16} style={{ marginTop: "20px" }} justify={"end"}>
                <Button type="default">Reset</Button>
                <Button type="primary" style={{ marginLeft: "12px" }}>
                  Submit
                </Button>
              </Row>
            </Form>
          </Card>
        );
      default:
        return (
          <Card style={{ height: "calc(100vh - 138px)", overflowY: "auto" }}>
            Select a menu item to see content.
          </Card>
        );
    }
  };

  return (
    <Row gutter={24} style={{ height: "100vh" }}>
      <Col span={8}>
        <Card
          style={{
            boxShadow: "0px 8px 40px -10px #00000014",
            borderRadius: "4px",
            height: "calc(100vh - 138px)",
          }}
        >
          <Menu
            mode="inline"
            selectedKeys={[selectedKey]}
            onClick={handleMenuClick}
            style={{ border: "none" }}
          >
            <Menu.Item
              key="pettyCashCode"
              style={
                selectedKey === "pettyCashCode" ? { backgroundColor: "#18B6DA", color: "#fff" } : {}
              }
            >
              Petty Cash Code
            </Menu.Item>
            <Menu.Item
              key="pettyCashEntry"
              style={
                selectedKey === "pettyCashEntry"
                  ? { backgroundColor: "#18B6DA", color: "#fff" }
                  : {}
              }
            >
              Petty Cash Entry
            </Menu.Item>
            <Menu.Item
              key="pettyCashReports"
              style={
                selectedKey === "pettyCashReports"
                  ? { backgroundColor: "#18B6DA", color: "#fff" }
                  : {}
              }
            >
              Petty Cash Reports
            </Menu.Item>
          </Menu>
        </Card>
      </Col>
      <Col span={16}>{renderContent()}</Col>
    </Row>
  );
};

export default PettyCashPage;
