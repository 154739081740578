/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, { useState } from "react";
import { Button, Card, Col, Modal, Input, Typography, Checkbox, Tag, Row } from "antd";
import { ReactComponent as NoDataSvg } from "assets/svg/rafiki.svg";
import {
  PlusOutlined,
  SearchOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import { cardStyle } from "../styles";

const prescriptions = [
  {
    id: 1,
    name: "Bimatoprost 0.03% + Timolol 0.5% Eye Drops, 30 x 0.4ml Unit Doses",
    restricted: true,
  },
  {
    id: 2,
    name: "11 uld Paraffin, 01 Tyloxapol, Poloxamer + Trometamol Drochlonde, Trometamol Cetalkonium Chloride Eye Drops, 10 mL",
    restricted: false,
  },
  { id: 3, name: "Antiviral Eye Ointment, 5g Tube", restricted: true },
  { id: 4, name: "Antibiotic Eye Drops, 10mL Bottle", restricted: false },
];

const TherapeuticPrescription: React.FC = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedPrescriptions, setSelectedPrescriptions] = useState<number[]>([]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onCheckboxChange = (id: number) => {
    setSelectedPrescriptions((prev) =>
      prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]
    );
  };

  return (
    <>
      <Card
        title="Therapeutic Prescription"
        style={{ flex: 1, boxShadow: "0px 8px 40px -10px #00000014" }}
        css={cardStyle}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              textAlign: "center",
              width: "400px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <NoDataSvg style={{ width: 200, height: 120, marginBottom: 10 }} />

            <Typography.Text strong>No Therapeutic Prescription</Typography.Text>

            <Button type="primary" icon={<PlusOutlined />} onClick={showModal} size="small">
              Add Therapeutic Prescription
            </Button>
          </div>
        </div>
      </Card>

      <Modal
        title="Search and Select Therapeutic Prescription"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Confirm"
        cancelText="Cancel"
        width={600}
      >
        <Input
          placeholder="Search Prescription"
          prefix={<SearchOutlined />}
          style={{ marginBottom: 20 }}
        />

        <Row gutter={[16, 16]}>
          {prescriptions.map((prescription) => (
            <Col span={24} key={prescription.id}>
              <Card>
                <div
                  style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
                >
                  <div>
                    <Typography.Text strong>{prescription.name}</Typography.Text>
                    <br />
                    <Tag
                      color={prescription.restricted ? "red" : "green"}
                      icon={
                        prescription.restricted ? <CloseCircleOutlined /> : <CheckCircleOutlined />
                      }
                    >
                      {prescription.restricted ? "Restricted" : "Not Restricted"}
                    </Tag>
                  </div>
                  <Checkbox onChange={() => onCheckboxChange(prescription.id)} />
                </div>
              </Card>
            </Col>
          ))}
        </Row>
      </Modal>
    </>
  );
};

export default TherapeuticPrescription;
