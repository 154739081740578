import React from "react";
import { Card, Divider, Row, Col, Form, Input, Button, Radio } from "antd";

const InvoiceReport: React.FC = () => {
  const handleFinish = (values: any) => {
    console.log("Invoice Report values: ", values);
  };

  return (
    <>
      <Card
        style={{
          boxShadow: "0px 8px 40px -10px #00000014",
          borderRadius: "4px",
        }}
      >
        <Form layout="vertical" onFinish={handleFinish}>
          {/* Report Details Section */}
          <Divider orientation="left" orientationMargin="0" style={{ marginBottom: "34px" }}>
            Invoice Report
          </Divider>
          <Row gutter={24}>
            <Col span={8}>
              <Form.Item
                label="Invoice Number"
                name="invoiceNumber"
                rules={[{ required: true, message: "Required" }]}
              >
                <Input placeholder="Enter Invoice Number" />
              </Form.Item>
            </Col>
          </Row>

          {/* Report Destination Section */}
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item label="Report Destination" name="reportDestination">
                <Radio.Group>
                  <Radio value="screen">Screen</Radio>
                  <Radio value="printer">Printer</Radio>
                  <Radio value="printerWithPrompt">Printer with Prompt</Radio>
                  <Radio value="excelSpreadsheet">Excel Spreadsheet</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>

          {/* Action Buttons */}
        </Form>
      </Card>

      <Row justify={"end"}>
        <div>
          <Button>Reset</Button>
          <Button
            type="primary"
            htmlType="submit"
            style={{ marginTop: "34px", marginLeft: "12px" }}
          >
            Generate Report
          </Button>
        </div>
      </Row>
    </>
  );
};

export default InvoiceReport;
