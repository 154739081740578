/** @jsxImportSource @emotion/react */
import React from "react";
import { Col, Row, Space, Typography, Button } from "antd";
import { css } from "@emotion/react";
import { ArrowLeftOutlined } from "@ant-design/icons";
import icons from "icons";
import BackgroundImage from "assets/svg/gradient.png";
import { InnerContainer, OuterContainer } from "Scenes/Auth/Login/styles";
import { useNavigate } from "react-router-dom";

const { Logo, Login, OrangeStroke, BlueStroke } = icons;

interface AuthPageLayoutProps {
  title: string;
  description: string;
  form: React.ReactNode;
  withBackButton?: boolean;
}

const AuthPageLayout: React.FC<AuthPageLayoutProps> = ({
  title,
  description,
  form,
  withBackButton = false,
}) => {
  const navigate = useNavigate();
  return (
    <OuterContainer style={{ position: "relative" }}>
      <div
        css={css`
          position: absolute;
          top: 0;
          right: 0;
          zindex: 100;
        `}
      >
        <OrangeStroke />
      </div>
      <div
        css={css`
          position: absolute;
          top: 20%;
          left: 0;
          zindex: 100;
        `}
      >
        <BlueStroke />
      </div>

      <InnerContainer style={{ zIndex: "10000" }}>
        <Row gutter={0} style={{ height: "100%" }}>
          <Col
            span={12}
            css={css`
              padding: 2rem 4rem;
              display: flex;
              flex-direction: column;
              justify-content: center;
            `}
          >
            <Space direction="vertical" style={{ marginBottom: "28px" }}>
              <Row justify={"space-between"}>
                <Logo />
                {withBackButton && (
                  <Button icon={<ArrowLeftOutlined />} onClick={() => navigate("/signin")}>
                    Login
                  </Button>
                )}
              </Row>
              <Typography.Title level={3}>{title}</Typography.Title>
              <Typography.Text type="secondary">{description}</Typography.Text>
            </Space>

            {form}
          </Col>

          <Col
            span={12}
            css={css`
              background: url(${BackgroundImage}) no-repeat center center;
              background-size: cover;
              padding: 2rem 4rem;
              color: white;
            `}
          >
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <Login />
            </div>
            <Space direction="vertical" size="middle" style={{ marginTop: "2rem" }}>
              <Typography.Text style={{ color: "white" }}>Welcome to,</Typography.Text>
              <Typography.Title level={3} style={{ margin: 0, color: "white" }}>
                Kyama Vision
              </Typography.Title>
              <Typography.Text style={{ color: "white" }}>
                Manage your patients, appointments, inventory, and more from one portal.
              </Typography.Text>
              <Space>
                <Button icon={<ArrowLeftOutlined />} shape="circle" ghost />
                <Typography.Text style={{ color: "white" }}>Get Started</Typography.Text>
              </Space>
            </Space>
          </Col>
        </Row>
      </InnerContainer>
    </OuterContainer>
  );
};

export default AuthPageLayout;
