import React from "react";
import { Card, Divider, Row, Col, Form, InputNumber, DatePicker, Button, Descriptions } from "antd";

const BankingPage: React.FC = () => {
  const handleFinish = (values: any) => {
    console.log("Banking Page values: ", values);
  };

  return (
    <Card
      style={{
        boxShadow: "0px 8px 40px -10px #00000014",
        borderRadius: "4px",
      }}
    >
      <Form layout="vertical" onFinish={handleFinish}>
        {/* Banking Date and Cash Deposit */}
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item
              label="Banking Date"
              name="bankingDate"
              rules={[{ required: true, message: "Required" }]}
            >
              <DatePicker style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Cash Deposit"
              name="cashDeposit"
              rules={[{ required: true, message: "Required" }]}
            >
              <InputNumber style={{ width: "100%" }} placeholder="Enter Amount" />
            </Form.Item>
          </Col>
        </Row>

        <Divider orientation="left" orientationMargin="0" style={{ marginBottom: "34px" }}>
          Banking Information
        </Divider>

        {/* Descriptions for Banking Information */}
        <Descriptions column={3} layout="vertical">
          <Descriptions.Item label="Banking No.">0003232 (Current Period)</Descriptions.Item>
          <Descriptions.Item label="Start Date">20/09/2024</Descriptions.Item>
          <Descriptions.Item label="End Date">-</Descriptions.Item>
          <Descriptions.Item label="Cash Received">$0.00</Descriptions.Item>
          <Descriptions.Item label="Cheque Received">$0.00</Descriptions.Item>
          <Descriptions.Item label="Cheque Deposit">$0.00</Descriptions.Item>
          <Descriptions.Item label="Others Received">$5056.00</Descriptions.Item>
          <Descriptions.Item label="Credit Card Deposit">$0.00</Descriptions.Item>
          <Descriptions.Item label="Total Amount Received">$5056.00</Descriptions.Item>
          <Descriptions.Item label="EFTPOS (Include Credit Card)">$1265.00</Descriptions.Item>
          <Descriptions.Item label="Petty Cash">$96.00</Descriptions.Item>
          <Descriptions.Item label="Direct Deposit Into Bank">$4290.00</Descriptions.Item>
          <Descriptions.Item label="GST Amount">$4819</Descriptions.Item>
          <Descriptions.Item label="Total Banked">$5506.00</Descriptions.Item>
        </Descriptions>

        {/* Action Buttons */}
        <Row justify={"end"} gutter={16} style={{ marginTop: "20px" }}>
          <Col>
            <Button>Export To Xero</Button>
          </Col>
          <Col>
            <Button>Export to MYOB</Button>
          </Col>
          <Col>
            <Button>Close Banking Period</Button>
          </Col>
          <Col>
            <Button>Transaction Listing</Button>
          </Col>
          <Col>
            <Button type="primary">Print Banking Reports</Button>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default BankingPage;
