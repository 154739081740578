import styled from "@emotion/styled";
import { Col, Divider, Layout, Menu, Row } from "antd";
import { MenuOutlined } from "@ant-design/icons";
const { Header, Content } = Layout;

export const StyledMenu = styled(Menu)`
  height: 68px;
  font-size: 10px;
  font-weight: 600;
  padding-left: 0; /* Remove left padding */
  display: flex;
  justify-content: center;
  background-color: #ffffff;

  .ant-menu-item,
  .ant-menu-submenu-title {
    color: #263446;
    text-align: center; /* Center text */
  }

  .ant-menu-item > span,
  .ant-menu-submenu-title > span {
    display: flex;
    align-items: center;
    justify-content: center; /* Center content */
  }

  .ant-menu-item > span .anticon,
  .ant-menu-submenu-title > span .anticon {
    margin-right: 8px;
  }

  .ant-menu-submenu-title {
    padding-right: 24px !important;
  }

  .ant-menu-submenu-arrow {
    display: none;
  }

  .ant-menu .ant-menu-submenu-title .anticon {
    font-size: 24px !important;
  }
`;

export const StyledHeader = styled(Layout.Header)`
  display: flex;
  align-items: center;
  background-color: #e9eaec;
  height: 68px;
  padding: 0px 80px;
`;

export const StyledRow = styled(Row)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledCol = styled(Col)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledDiv = styled.div`
  height: 36px;
  width: 36px;
  border-radius: 4px;
  background-color: #f5f6fa;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const MenuWrapper = styled.div`
  background-color: white;
  box-shadow: 0px 19px 34px -15px #d2d2f2;
  position: relative;
  z-index: 1;
`;

// export const StyledContent = styled(Layout.Content)`
//   padding: 35px 48px;
//   background-color: #f5f6fa;
//   height: calc(100vh - 136px);
//   overflow: auto;
// `;

export const CenteredDiv = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const OuterHeader = styled(Header)`
  background: #141414;
  height: 48px;
  color: white;
`;

export const InnerHeader = styled(Header)`
  background: white;
  padding: 0;
  height: 55px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledContent = styled(Content)`
  background: #f3f4f6;
  padding: 18px 18px 45px 18px;
  height: calc(100vh - 136px);
  overflow: auto;
`;

export const SidebarSearchWrapper = styled.div`
  margin: 8px 8px 20px 8px;
`;

export const StyledDivider = styled(Divider)`
  height: 48px;
  margin: 0 16px;
`;

export const ToggleIcon = styled(MenuOutlined)`
  cursor: pointer;
  font-size: 24px;
`;
