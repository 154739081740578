/** @jsxImportSource @emotion/react */
import React from "react";
import { Table, Button, Input } from "antd";
import { DataType } from "../data";
import { getRxColumns } from "../handlers/getRxColumns";
import {
  matrixTableStyle,
  CellStyles,
  LeftEyeColumn,
  RightEyeColumn,
  SubHeaderStyles,
  TableWrapper,
  tableLabelStyle,
  StyledTable,
} from "../styles";

interface RxTableSectionProps {
  data: DataType[];
  setData: React.Dispatch<React.SetStateAction<DataType[]>>;
  showActions: boolean;
}

const getUpdatedRowPrev = (row: DataType) => ({
  leftSph: row.rightSph,
  leftCyl: row.rightCyl,
  leftAxis: row.rightAxis,
  leftAdd: row.rightAdd,
  leftInter: row.rightInter,
  leftVa: row.rightVa,
  rightSph: row.rightSph,
  rightCyl: row.rightCyl,
  rightAxis: row.rightAxis,
  rightAdd: row.rightAdd,
  rightInter: row.rightInter,
  rightVa: row.rightVa,
});

const getUpdatedRowNext = (row: DataType) => ({
  leftSph: row.leftSph,
  leftCyl: row.leftCyl,
  leftAxis: row.leftAxis,
  leftAdd: row.leftAdd,
  leftInter: row.leftInter,
  leftVa: row.leftVa,
  rightSph: row.leftSph,
  rightCyl: row.leftCyl,
  rightAxis: row.leftAxis,
  rightAdd: row.leftAdd,
  rightInter: row.leftInter,
  rightVa: row.leftVa,
});

const RxTableSection: React.FC<RxTableSectionProps> = ({ data, setData, showActions }) => {
  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    key: string,
    field: string
  ) => {
    const newData = data.map((item) =>
      item.key === key ? { ...item, [field]: e.target.value } : item
    );
    setData(newData);
  };

  const handleRowUpdate = (key: string, action: "prev" | "next" | "down") => {
    const rowIndex = data.findIndex((row) => row.key === key);

    if (rowIndex === -1) return;

    let updatedData = [...data];

    if (action === "prev") {
      if (rowIndex > 0) {
        updatedData[rowIndex] = { ...data[rowIndex], ...getUpdatedRowPrev(data[rowIndex]) };
      }
    }

    if (action === "next") {
      updatedData[rowIndex] = { ...data[rowIndex], ...getUpdatedRowNext(data[rowIndex]) };
    }

    if (action === "down") {
      if (rowIndex < data.length - 1) {
        updatedData[rowIndex + 1] = { ...data[rowIndex + 1], ...data[rowIndex] };
      }
    }

    setData(updatedData);
  };

  const handleRowDelete = (key: string) => {
    const newData = data.filter((item) => item.key !== key);
    setData(newData);
  };

  const isDefaultRow = (key: string) => {
    return ["1", "2", "3"].includes(key);
  };

  return (
    <div css={TableWrapper}>
      <StyledTable
        columns={getRxColumns(
          handleInputChange,
          handleRowUpdate,
          handleRowDelete,
          isDefaultRow,
          showActions
        )}
        dataSource={data}
        pagination={false}
        rowKey="key"
        size="small"
        css={[
          matrixTableStyle,
          CellStyles,
          LeftEyeColumn,
          RightEyeColumn,
          SubHeaderStyles,
          tableLabelStyle,
        ]}
      />
    </div>
  );
};

export default RxTableSection;
