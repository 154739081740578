import React from "react";
import { Card, Divider, Descriptions, Table } from "antd";

const boldLabelStyle = { fontWeight: "bold", color: "#141414" };

const SundryDetailsPage: React.FC = () => {
  // Dummy sundry details data
  const sundryDetails = {
    itemName: "Sample Item",
    sku: "S13345",
    category: "Stationery",
    status: "Active",
  };

  // Dummy supplier details data
  const supplierDetails = {
    supplierName: "Supplier XYZ",
    contact: "+133456789",
    address: "133 Supplier Lane, Industrial Area",
  };

  // Dummy re-order details data
  const reOrderDetails = {
    minQty: 50,
    maxQty: 300,
    reOrderLevel: 75,
  };

  // Dummy cost details
  const costDetails = {
    costPrice: "$10",
    taxRate: "10%",
    discount: "5%",
  };

  // Dummy sales and purchase details
  const salesAndPurchaseDetails = {
    totalSales: 500,
    totalPurchases: 400,
  };

  // Dummy data for the cost details table
  const costTableData = [
    { key: "1", priceFor: "Unit Price", excludingTax: "$10", includingTax: "$11" },
    { key: "3", priceFor: "Bulk Price", excludingTax: "$30", includingTax: "$33" },
    { key: "3", priceFor: "Wholesale Price", excludingTax: "$30", includingTax: "$33" },
    { key: "4", priceFor: "Retail Price", excludingTax: "$40", includingTax: "$44" },
    { key: "5", priceFor: "Distributor Price", excludingTax: "$50", includingTax: "$55" },
    { key: "6", priceFor: "Special Price", excludingTax: "$60", includingTax: "$66" },
  ];

  // Columns for the cost details table
  const costTableColumns = [
    {
      title: "Prices For",
      dataIndex: "priceFor",
      key: "priceFor",
      width: "33%",
    },
    {
      title: "Excluding Tax",
      dataIndex: "excludingTax",
      key: "excludingTax",
      width: "33%",
    },
    {
      title: "Including Tax",
      dataIndex: "includingTax",
      key: "includingTax",
      width: "33%",
    },
  ];

  return (
    <Card
      style={{
        boxShadow: "0px 8px 40px -10px #00000014",
        borderRadius: "4px",
      }}
    >
      {/* Sundry Details Section */}
      <Divider orientation="left" orientationMargin="0" style={{ marginBottom: "34px" }}>
        Sundry Details
      </Divider>
      <Descriptions column={3} bordered={false} layout="vertical">
        <Descriptions.Item label={<span style={boldLabelStyle}>Item Name</span>}>
          {sundryDetails.itemName}
        </Descriptions.Item>
        <Descriptions.Item label={<span style={boldLabelStyle}>SKU</span>}>
          {sundryDetails.sku}
        </Descriptions.Item>
        <Descriptions.Item label={<span style={boldLabelStyle}>Category</span>}>
          {sundryDetails.category}
        </Descriptions.Item>
        <Descriptions.Item label={<span style={boldLabelStyle}>Status</span>}>
          {sundryDetails.status}
        </Descriptions.Item>
      </Descriptions>

      {/* Supplier Details Section */}
      <Divider orientation="left" orientationMargin="0" style={{ margin: "40px 0 34px 0" }}>
        Supplier Details
      </Divider>
      <Descriptions column={3} bordered={false} layout="vertical">
        <Descriptions.Item label={<span style={boldLabelStyle}>Supplier Name</span>}>
          {supplierDetails.supplierName}
        </Descriptions.Item>
        <Descriptions.Item label={<span style={boldLabelStyle}>Contact</span>}>
          {supplierDetails.contact}
        </Descriptions.Item>
        <Descriptions.Item label={<span style={boldLabelStyle}>Address</span>}>
          {supplierDetails.address}
        </Descriptions.Item>
      </Descriptions>

      {/* Re-Order Details Section */}
      <Divider orientation="left" orientationMargin="0" style={{ margin: "40px 0 34px 0" }}>
        Re-Order Details
      </Divider>
      <Descriptions column={3} bordered={false} layout="vertical">
        <Descriptions.Item label={<span style={boldLabelStyle}>Minimum Quantity</span>}>
          {reOrderDetails.minQty}
        </Descriptions.Item>
        <Descriptions.Item label={<span style={boldLabelStyle}>Maximum Quantity</span>}>
          {reOrderDetails.maxQty}
        </Descriptions.Item>
        <Descriptions.Item label={<span style={boldLabelStyle}>Re-Order Level</span>}>
          {reOrderDetails.reOrderLevel}
        </Descriptions.Item>
      </Descriptions>

      {/* Cost Details Section */}
      <Divider orientation="left" orientationMargin="0" style={{ margin: "40px 0 34px 0" }}>
        Cost Details
      </Divider>
      <Descriptions column={3} bordered={false} layout="vertical">
        <Descriptions.Item label={<span style={boldLabelStyle}>Cost Price</span>}>
          {costDetails.costPrice}
        </Descriptions.Item>
        <Descriptions.Item label={<span style={boldLabelStyle}>Tax Rate</span>}>
          {costDetails.taxRate}
        </Descriptions.Item>
        <Descriptions.Item label={<span style={boldLabelStyle}>Discount</span>}>
          {costDetails.discount}
        </Descriptions.Item>
      </Descriptions>

      {/* Cost Details Table */}
      <Table
        columns={costTableColumns}
        dataSource={costTableData}
        pagination={false}
        bordered={true}
        style={{ marginTop: "34px" }}
      />

      {/* Sales and Purchase Details Section */}
      <Divider orientation="left" orientationMargin="0" style={{ margin: "40px 0 34px 0" }}>
        Sales and Purchase Details
      </Divider>
      <Descriptions column={3} bordered={false} layout="vertical">
        <Descriptions.Item label={<span style={boldLabelStyle}>Total Sales</span>}>
          {salesAndPurchaseDetails.totalSales}
        </Descriptions.Item>
        <Descriptions.Item label={<span style={boldLabelStyle}>Total Purchases</span>}>
          {salesAndPurchaseDetails.totalPurchases}
        </Descriptions.Item>
      </Descriptions>
    </Card>
  );
};

export default SundryDetailsPage;
