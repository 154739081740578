import React, { useState } from "react";
import { Card, Divider, Row, Col, Form, DatePicker, Button } from "antd";
import { Column } from "@ant-design/charts";
import dayjs from "dayjs";

const BillingSummary: React.FC = () => {
  const [chartData, setChartData] = useState<any[]>([]);
  const [years, setYears] = useState<{ from: number; to: number } | null>(null);

  const handleFinish = (values: { yearFrom: dayjs.Dayjs; yearTo: dayjs.Dayjs }) => {
    const yearFrom = values.yearFrom.year();
    const yearTo = values.yearTo.year();

    console.log("Billing Summary values: ", yearFrom, yearTo);
    setYears({ from: yearFrom, to: yearTo });

    // Manually generating data for the months of both years
    const data = [
      { month: "Jan", year: yearFrom, value: Math.random() * 1000 },
      { month: "Jan", year: yearTo, value: Math.random() * 1000 },
      { month: "Feb", year: yearFrom, value: Math.random() * 1000 },
      { month: "Feb", year: yearTo, value: Math.random() * 1000 },
      { month: "Mar", year: yearFrom, value: Math.random() * 1000 },
      { month: "Mar", year: yearTo, value: Math.random() * 1000 },
      { month: "Apr", year: yearFrom, value: Math.random() * 1000 },
      { month: "Apr", year: yearTo, value: Math.random() * 1000 },
      { month: "May", year: yearFrom, value: Math.random() * 1000 },
      { month: "May", year: yearTo, value: Math.random() * 1000 },
      { month: "Jun", year: yearFrom, value: Math.random() * 1000 },
      { month: "Jun", year: yearTo, value: Math.random() * 1000 },
      { month: "Jul", year: yearFrom, value: Math.random() * 1000 },
      { month: "Jul", year: yearTo, value: Math.random() * 1000 },
      { month: "Aug", year: yearFrom, value: Math.random() * 1000 },
      { month: "Aug", year: yearTo, value: Math.random() * 1000 },
      { month: "Sep", year: yearFrom, value: Math.random() * 1000 },
      { month: "Sep", year: yearTo, value: Math.random() * 1000 },
      { month: "Oct", year: yearFrom, value: Math.random() * 1000 },
      { month: "Oct", year: yearTo, value: Math.random() * 1000 },
      { month: "Nov", year: yearFrom, value: Math.random() * 1000 },
      { month: "Nov", year: yearTo, value: Math.random() * 1000 },
      { month: "Dec", year: yearFrom, value: Math.random() * 1000 },
      { month: "Dec", year: yearTo, value: Math.random() * 1000 },
    ];
    setChartData(data);
  };

  const config = {
    data: chartData,
    isGroup: true,
    xField: "month",
    yField: "value",
    seriesField: "year",
    color: ["#1f77b4", "#ff7f0e"], // Set different colors for different years
    xAxis: {
      label: {
        autoRotate: false,
      },
    },
    yAxis: {
      label: {
        formatter: (v: number) => {
          if (v >= 1000) {
            return `${(v / 1000).toFixed(1)}k`; // Format numbers above 1000 as "k"
          }
          return v.toFixed(2); // For smaller numbers, show them as is
        },
      },
    },
    legend: {
      position: "top",
    },
    // Removing labels from the bars
    label: null,
  };

  return (
    <>
      <Card
        style={{
          boxShadow: "0px 8px 40px -10px #00000014",
          borderRadius: "4px",
        }}
      >
        <Form layout="vertical" onFinish={handleFinish}>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                label="Year From"
                name="yearFrom"
                rules={[{ required: true, message: "Please select the starting year" }]}
              >
                <DatePicker picker="year" placeholder="Select Year" style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Year To"
                name="yearTo"
                rules={[{ required: true, message: "Please select the ending year" }]}
              >
                <DatePicker picker="year" placeholder="Select Year" style={{ width: "100%" }} />
              </Form.Item>
            </Col>
          </Row>

          <Row justify="end">
            <Button type="primary" htmlType="submit" style={{ marginTop: "34px" }}>
              Generate Report
            </Button>
          </Row>
        </Form>
      </Card>

      {chartData.length > 0 && (
        <Card
          style={{
            marginTop: "24px",
            boxShadow: "0px 8px 40px -10px #00000014",
            borderRadius: "4px",
          }}
        >
          <Divider orientation="left" orientationMargin="0">
            Graph Report
          </Divider>
          <Column {...config} />
        </Card>
      )}
    </>
  );
};

export default BillingSummary;
