import React from "react";
import { Card, Divider, Row, Col, Form, Input, Button, Select, Radio } from "antd";

const { TextArea } = Input;

const AdjustmentReport: React.FC = () => {
  const handleFinish = (values: any) => {
    console.log("Report values: ", values);
  };

  return (
    <>
      <Card
        style={{
          boxShadow: "0px 8px 40px -10px #00000014",
          borderRadius: "4px",
        }}
      >
        <Form layout="vertical" onFinish={handleFinish}>
          {/* Report Details Section */}
          <Divider orientation="left" orientationMargin="0" style={{ marginBottom: "34px" }}>
            Adjustment Report
          </Divider>
          <Row gutter={24}>
            <Col span={8}>
              <Form.Item
                label="Start Date"
                name="startDate"
                rules={[{ required: true, message: "Required" }]}
              >
                <Input type="date" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="End Date"
                name="endDate"
                rules={[{ required: true, message: "Required" }]}
              >
                <Input type="date" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Supplier"
                name="supplier"
                rules={[{ required: true, message: "Required" }]}
              >
                <Select placeholder="Select Supplier">
                  <Select.Option value="supplier1">Supplier 1</Select.Option>
                  <Select.Option value="supplier2">Supplier 2</Select.Option>
                  <Select.Option value="supplier3">Supplier 3</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>

          {/* Reason for Adjustment Section */}
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                label="Reason for Adjustment"
                name="adjustmentReason"
                rules={[{ required: true, message: "Required" }]}
              >
                <TextArea placeholder="Enter reason for adjustment" rows={4} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={24}>
              <Form.Item label="Report Destination" name="reportDestination">
                <Radio.Group>
                  <Radio value="screen">Screen</Radio>
                  <Radio value="printer">Printer</Radio>
                  <Radio value="printerWithPrompt">Printer with Prompt</Radio>
                  <Radio value="excelSpreadsheet">Excel Spreadsheet</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>

          {/* Action Buttons */}
        </Form>
      </Card>
      <Row justify={"end"}>
        <div>
          <Button>Reset</Button>
          <Button
            type="primary"
            htmlType="submit"
            style={{ marginTop: "34px", marginLeft: "12px" }}
          >
            Generate Report
          </Button>
        </div>
      </Row>
    </>
  );
};

export default AdjustmentReport;
