import React, { useState } from "react";
import {
  Row,
  Col,
  Card,
  Dropdown,
  Menu,
  Input,
  Typography,
  Button,
  Modal,
  Form,
  Select,
  Upload,
} from "antd";
import { FaFileAlt, FaEye, FaTrashAlt, FaUpload } from "react-icons/fa";
import { MoreOutlined, InboxOutlined, UploadOutlined } from "@ant-design/icons";
import Dragger from "antd/es/upload/Dragger";

const { Search } = Input;
const { Option } = Select;

const documentList = [
  { id: 1, name: "Eye Test Report", size: "1 MB" },
  { id: 2, name: "Prescription - Glasses", size: "750 KB" },
  { id: 3, name: "Vision Acuity Test", size: "2.5 MB" },
  { id: 4, name: "Consultation Summary", size: "900 KB" },
  { id: 5, name: "Vision Acuity Test", size: "2.5 MB" },
  { id: 6, name: "Consultation Summary", size: "900 KB" },
];

const CorrespondenceTab: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleMenuClick = (key: string) => {
    if (key === "preview") {
      console.log("Preview clicked");
    } else if (key === "delete") {
      console.log("Delete clicked");
    }
  };

  const menu = (
    <Menu onClick={({ key }) => handleMenuClick(key)}>
      <Menu.Item key="preview" icon={<FaEye />}>
        Preview
      </Menu.Item>
      <Menu.Item key="delete" icon={<FaTrashAlt />}>
        Delete
      </Menu.Item>
    </Menu>
  );

  const filteredDocuments = documentList.filter((doc) =>
    doc.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const categories = [
    { title: "Referral", documents: filteredDocuments },
    { title: "Report", documents: filteredDocuments },
    { title: "Patient Information", documents: filteredDocuments },
  ];

  const handleUploadClick = () => {
    setIsModalVisible(true);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  const handleModalOk = () => {
    // Add your upload logic here
    setIsModalVisible(false);
  };

  return (
    <div>
      <Row justify={"end"}>
        <Button
          type="primary"
          icon={<UploadOutlined />}
          onClick={handleUploadClick}
          style={{ marginBottom: 24 }}
        >
          Upload Document
        </Button>
      </Row>
      <Row gutter={[24, 24]}>
        {categories.map((category) => (
          <Col span={24} key={category.title}>
            <Card
              title={category.title}
              style={{
                boxShadow: "0px 8px 40px -10px #00000014",
                borderRadius: "4px",
              }}
            >
              <Row gutter={[24, 24]}>
                {category.documents.length > 0 ? (
                  category.documents.map((doc) => (
                    <Col span={12}>
                      <Card>
                        <div key={doc.id} style={{ display: "flex", marginBottom: 16 }}>
                          <div
                            style={{
                              width: 50,
                              height: 50,
                              backgroundColor: "#f0f0f0",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              marginRight: 16,
                              borderRadius: "4px",
                            }}
                          >
                            <FaFileAlt style={{ fontSize: 24 }} />
                          </div>

                          <div style={{ flex: 1 }}>
                            <Typography.Text strong>{doc.name}</Typography.Text>
                            <Typography.Paragraph style={{ margin: 0 }}>
                              {doc.size}
                            </Typography.Paragraph>
                          </div>

                          <Dropdown overlay={menu} trigger={["click"]}>
                            <MoreOutlined style={{ fontSize: 22, cursor: "pointer" }} />
                          </Dropdown>
                        </div>
                      </Card>
                    </Col>
                  ))
                ) : (
                  <Typography.Paragraph>No documents found</Typography.Paragraph>
                )}
              </Row>
            </Card>
          </Col>
        ))}
      </Row>

      <Modal
        title="Upload Correspondence"
        visible={isModalVisible}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
      >
        <Form layout="vertical" onFinish={handleModalOk}>
          <Form.Item
            name="category"
            rules={[{ required: true, message: "Please select a category!" }]}
          >
            <Select placeholder="Select a category">
              <Option value="referral">Referral</Option>
              <Option value="report">Report</Option>
              <Option value="patient-info">Patient Information</Option>
            </Select>
          </Form.Item>
          <Form.Item name="upload">
            <Dragger>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">Click or drag file to this area to upload</p>
              <p className="ant-upload-hint">
                Support for a single or bulk upload. Strictly prohibited from uploading company data
                or other banned files.
              </p>
            </Dragger>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default CorrespondenceTab;
