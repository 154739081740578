import React, { useState } from "react";
import { Card, Row, Col, Menu, Divider, Button, Table, Form, Input, Select, Radio } from "antd";

// Define reusable columns for the table
const columns = [
  {
    title: "SN",
    dataIndex: "sn",
    key: "sn",
  },
  {
    title: "Pat Ref",
    dataIndex: "patRef",
    key: "patRef",
  },
  {
    title: "RRP Pickup",
    dataIndex: "rrpPickup",
    key: "rrpPickup",
  },
  {
    title: "Due",
    dataIndex: "due",
    key: "due",
  },
  {
    title: "Return",
    dataIndex: "return",
    key: "return",
  },
  {
    title: "Supp Ord",
    dataIndex: "suppOrd",
    key: "suppOrd",
  },
];

// Define reusable data source for all menu items
const dataSource = [
  {
    key: "1",
    sn: 1,
    patRef: "PAT001",
    rrpPickup: "10/01/2023",
    due: "10/02/2023",
    return: "10/03/2023",
    suppOrd: "ORD001",
  },
  {
    key: "2",
    sn: 2,
    patRef: "PAT002",
    rrpPickup: "10/01/2023",
    due: "10/02/2023",
    return: "10/03/2023",
    suppOrd: "ORD002",
  },
  {
    key: "3",
    sn: 3,
    patRef: "PAT003",
    rrpPickup: "10/01/2023",
    due: "10/02/2023",
    return: "10/03/2023",
    suppOrd: "ORD003",
  },
];

// FrameApprovalPage component
const FrameApprovalPage: React.FC = () => {
  const [selectedKey, setSelectedKey] = useState("frameApprovalHistory");

  const handleMenuClick = (e: any) => {
    setSelectedKey(e.key);
  };

  const renderContent = () => {
    switch (selectedKey) {
      case "frameApprovalHistory":
        return (
          <Card
            style={{
              height: "calc(100vh - 138px)",
              overflowY: "auto",
            }}
          >
            <Divider orientation="left" orientationMargin="0" style={{ marginBottom: "34px" }}>
              Frame Approval History (By Pickup Date)
            </Divider>
            <Row justify="end" style={{ marginBottom: "20px" }}>
              <Button type="primary">Print</Button>
            </Row>
            <Table
              dataSource={dataSource} // Use the same data source for all menu items
              columns={columns}
            />
          </Card>
        );
      case "frameDueForPicking":
        return (
          <Card
            style={{
              height: "calc(100vh - 138px)",
              overflowY: "auto",
            }}
          >
            <Divider orientation="left" orientationMargin="0" style={{ marginBottom: "34px" }}>
              Frame Due for Picking by Patients
            </Divider>
            <Row justify="end" style={{ marginBottom: "20px" }}>
              <Button type="primary">Print</Button>
            </Row>
            <Table
              dataSource={dataSource} // Use the same data source for all menu items
              columns={columns}
            />
          </Card>
        );
      case "frameDueForReturn":
        return (
          <Card
            style={{
              height: "calc(100vh - 138px)",
              overflowY: "auto",
            }}
          >
            <Divider orientation="left" orientationMargin="0" style={{ marginBottom: "34px" }}>
              Frame Due for Return by Patients
            </Divider>
            <Row justify="end" style={{ marginBottom: "20px" }}>
              <Button type="primary">Print</Button>
            </Row>
            <Table
              dataSource={dataSource} // Use the same data source for all menu items
              columns={columns}
            />
          </Card>
        );
      case "frameDueForDelivery":
        return (
          <Card
            style={{
              height: "calc(100vh - 138px)",
              overflowY: "auto",
            }}
          >
            <Divider orientation="left" orientationMargin="0" style={{ marginBottom: "34px" }}>
              Frame Due for Delivery from Supplier
            </Divider>
            <Row justify="end" style={{ marginBottom: "20px" }}>
              <Button type="primary">Print</Button>
            </Row>
            <Table
              dataSource={dataSource} // Use the same data source for all menu items
              columns={columns}
            />
          </Card>
        );
      case "frameDueForReturnToSupplier":
        return (
          <Card
            style={{
              height: "calc(100vh - 138px)",
              overflowY: "auto",
            }}
          >
            <Divider orientation="left" orientationMargin="0" style={{ marginBottom: "34px" }}>
              Frame Due for Return to Supplier
            </Divider>
            <Form layout="vertical">
              <Row gutter={16}>
                <Col span={8}>
                  <Form.Item label="Start Date">
                    <Input placeholder="Start Date" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="End Date">
                    <Input placeholder="End Date" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="Supplier Code">
                    <Input placeholder="Supplier Code" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="Brand">
                    <Input placeholder="Brand" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="Frame Type">
                    <Select placeholder="Select Frame Type">
                      <Select.Option value="type1">Type 1</Select.Option>
                      <Select.Option value="type2">Type 2</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="Frame Group">
                    <Select placeholder="Select Frame Group">
                      <Select.Option value="group1">Group 1</Select.Option>
                      <Select.Option value="group2">Group 2</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="Frame Status">
                    <Select placeholder="Select Frame Status">
                      <Select.Option value="status1">Status 1</Select.Option>
                      <Select.Option value="status2">Status 2</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="Location">
                    <Input placeholder="Location" />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  {" "}
                  {/* Adjusted span to 24 for Report Destination */}
                  <Form.Item label="Report Destination">
                    <Radio.Group>
                      <Radio value="screen">Screen</Radio>
                      <Radio value="printer">Printer</Radio>
                      <Radio value="printerWithPrompt">Printer with Prompt</Radio>
                      <Radio value="excelSpreadsheet">Excel Spreadsheet</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16} style={{ marginTop: "20px" }} justify={"end"}>
                <Button type="default">Reset</Button>
                <Button type="primary" style={{ marginLeft: "12px" }}>
                  Submit
                </Button>
              </Row>
            </Form>
          </Card>
        );
      default:
        return (
          <Card
            style={{
              height: "calc(100vh - 138px)",
              overflowY: "auto",
            }}
          >
            Select a menu item to see content.
          </Card>
        );
    }
  };

  return (
    <Row gutter={24} style={{ height: "100vh" }}>
      <Col span={8}>
        <Card
          style={{
            boxShadow: "0px 8px 40px -10px #00000014",
            borderRadius: "4px",
            height: "calc(100vh - 138px)",
          }}
        >
          <Menu
            mode="inline"
            selectedKeys={[selectedKey]}
            onClick={handleMenuClick}
            style={{ border: "none" }}
          >
            <Menu.Item
              key="frameApprovalHistory"
              style={
                selectedKey === "frameApprovalHistory"
                  ? { backgroundColor: "#18B6DA", color: "#fff" }
                  : {}
              }
            >
              Frame Approval History
            </Menu.Item>
            <Menu.Item
              key="frameDueForPicking"
              style={
                selectedKey === "frameDueForPicking"
                  ? { backgroundColor: "#18B6DA", color: "#fff" }
                  : {}
              }
            >
              Frame Due for Picking by Patients
            </Menu.Item>
            <Menu.Item
              key="frameDueForReturn"
              style={
                selectedKey === "frameDueForReturn"
                  ? { backgroundColor: "#18B6DA", color: "#fff" }
                  : {}
              }
            >
              Frame Due for Return by Patients
            </Menu.Item>
            <Menu.Item
              key="frameDueForDelivery"
              style={
                selectedKey === "frameDueForDelivery"
                  ? { backgroundColor: "#18B6DA", color: "#fff" }
                  : {}
              }
            >
              Frame Due for Delivery from Supplier
            </Menu.Item>
            <Menu.Item
              key="frameDueForReturnToSupplier"
              style={
                selectedKey === "frameDueForReturnToSupplier"
                  ? { backgroundColor: "#18B6DA", color: "#fff" }
                  : {}
              }
            >
              Frame Due for Return to Supplier
            </Menu.Item>{" "}
            {/* New Menu Item */}
          </Menu>
        </Card>
      </Col>
      <Col span={16}>{renderContent()}</Col>
    </Row>
  );
};

export default FrameApprovalPage;
