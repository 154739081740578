import { useApiService } from "hooks/useApiService";
import { QUERY_KEYS } from "constants/queryKeys";
import { FrameGroupInterface, FrameGroupResponseInterface } from "./types";
import { frameGroupApi } from "./frameGroupServiceApi";

export const useFrameGroupService = () => {
  return useApiService<FrameGroupResponseInterface, FrameGroupInterface>(
    QUERY_KEYS.FRAME_GROUP,
    frameGroupApi
  );
};
