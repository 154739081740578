import React from "react";
import { Card, Divider, Descriptions } from "antd";

const boldLabelStyle = { fontWeight: "bold", color: "#141414" };

const LensCodeSpectacleDetails: React.FC = () => {
  const lensCodeData = {
    lensCode: "LC-001",
    refIndex: 1.5,
    baseCurve: 6,
    lensSize: 55,
    sagSize: 2,
    material: "Polycarbonate",
    stockGrind: "Stock",
    labDescription: "Polycarbonate high impact lens",
    invoiceDescription: "Polycarbonate Lens, Anti-Glare",
    description: "High-quality lens with UV protection",
    pricingDetails: {
      retailPriceTaxRate: 10,
      retailPricePerLensIncTax: 150,
      costPricePerLensExcTax: 100,
      costPriceTaxRate: 5,
      costPricePerLensIncTax: 105,
      priceListGrouping: "Premium",
    },
    supplierDetails: {
      supplier: "Optical Suppliers Inc.",
      supplierProductCode: "OS-1234",
    },
    otherDetails: {
      eyeTalkCode: "ETC-5678",
      healthFundItemNo1: "HF001",
      healthFundItemNo2: "HF002",
      includeInPicklist: true,
      includeInBarcodeListing: true,
      preference: false,
      category: "Prescription",
      colorTreatment: "Blue Light Filter",
      startAvailability: new Date("2023-01-01"),
      endAvailability: new Date("2025-01-01"),
      highPrecisionCompatibility: true,
      otherInfoNotes: "Compatible with high-index lenses",
    },
  };

  return (
    <Card
      style={{
        boxShadow: "0px 8px 40px -10px #00000014",
        borderRadius: "4px",
      }}
    >
      {/* Lens Details Section */}
      <Divider orientation="left" orientationMargin="0" style={{ marginBottom: "34px" }}>
        Lens Code Details
      </Divider>
      <Descriptions column={3} bordered={false} layout="vertical">
        <Descriptions.Item label={<span style={boldLabelStyle}>Lens Code</span>}>
          {lensCodeData.lensCode}
        </Descriptions.Item>
        <Descriptions.Item label={<span style={boldLabelStyle}>Ref Index</span>}>
          {lensCodeData.refIndex}
        </Descriptions.Item>
        <Descriptions.Item label={<span style={boldLabelStyle}>Base Curve</span>}>
          {lensCodeData.baseCurve}
        </Descriptions.Item>
        <Descriptions.Item label={<span style={boldLabelStyle}>Lens Size</span>}>
          {lensCodeData.lensSize}
        </Descriptions.Item>
        <Descriptions.Item label={<span style={boldLabelStyle}>Sag Size</span>}>
          {lensCodeData.sagSize}
        </Descriptions.Item>
        <Descriptions.Item label={<span style={boldLabelStyle}>Material</span>}>
          {lensCodeData.material}
        </Descriptions.Item>
        <Descriptions.Item label={<span style={boldLabelStyle}>Stock/Grind</span>}>
          {lensCodeData.stockGrind}
        </Descriptions.Item>
        <Descriptions.Item label={<span style={boldLabelStyle}>Lab Description</span>}>
          {lensCodeData.labDescription}
        </Descriptions.Item>
        <Descriptions.Item label={<span style={boldLabelStyle}>Invoice Description</span>}>
          {lensCodeData.invoiceDescription}
        </Descriptions.Item>
        <Descriptions.Item label={<span style={boldLabelStyle}>Description</span>}>
          {lensCodeData.description}
        </Descriptions.Item>
      </Descriptions>

      {/* Pricing Details Section */}
      <Divider orientation="left" orientationMargin="0" style={{ margin: "40px 0 34px 0" }}>
        Pricing Details
      </Divider>
      <Descriptions column={3} bordered={false} layout="vertical">
        <Descriptions.Item label={<span style={boldLabelStyle}>Retail Price Tax Rate</span>}>
          {lensCodeData.pricingDetails.retailPriceTaxRate}%
        </Descriptions.Item>
        <Descriptions.Item label={<span style={boldLabelStyle}>Retail Price (Inc. Tax)</span>}>
          ${lensCodeData.pricingDetails.retailPricePerLensIncTax}
        </Descriptions.Item>
        <Descriptions.Item label={<span style={boldLabelStyle}>Cost Price (Exc. Tax)</span>}>
          ${lensCodeData.pricingDetails.costPricePerLensExcTax}
        </Descriptions.Item>
        <Descriptions.Item label={<span style={boldLabelStyle}>Cost Price Tax Rate</span>}>
          {lensCodeData.pricingDetails.costPriceTaxRate}%
        </Descriptions.Item>
        <Descriptions.Item label={<span style={boldLabelStyle}>Cost Price (Inc. Tax)</span>}>
          ${lensCodeData.pricingDetails.costPricePerLensIncTax}
        </Descriptions.Item>
        <Descriptions.Item label={<span style={boldLabelStyle}>Price List Grouping</span>}>
          {lensCodeData.pricingDetails.priceListGrouping}
        </Descriptions.Item>
      </Descriptions>

      {/* Supplier Details Section */}
      <Divider orientation="left" orientationMargin="0" style={{ margin: "40px 0 34px 0" }}>
        Supplier Details
      </Divider>
      <Descriptions column={3} bordered={false} layout="vertical">
        <Descriptions.Item label={<span style={boldLabelStyle}>Supplier</span>}>
          {lensCodeData.supplierDetails.supplier}
        </Descriptions.Item>
        <Descriptions.Item label={<span style={boldLabelStyle}>Supplier Product Code</span>}>
          {lensCodeData.supplierDetails.supplierProductCode}
        </Descriptions.Item>
      </Descriptions>

      {/* Other Details Section */}
      <Divider orientation="left" orientationMargin="0" style={{ margin: "40px 0 34px 0" }}>
        Other Details
      </Divider>
      <Descriptions column={3} bordered={false} layout="vertical">
        <Descriptions.Item label={<span style={boldLabelStyle}>EyeTalk Code</span>}>
          {lensCodeData.otherDetails.eyeTalkCode}
        </Descriptions.Item>
        <Descriptions.Item label={<span style={boldLabelStyle}>Health Fund Item No. 1</span>}>
          {lensCodeData.otherDetails.healthFundItemNo1}
        </Descriptions.Item>
        <Descriptions.Item label={<span style={boldLabelStyle}>Health Fund Item No. 2</span>}>
          {lensCodeData.otherDetails.healthFundItemNo2}
        </Descriptions.Item>
        <Descriptions.Item label={<span style={boldLabelStyle}>Include in Picklist</span>}>
          {lensCodeData.otherDetails.includeInPicklist ? "Yes" : "No"}
        </Descriptions.Item>
        <Descriptions.Item label={<span style={boldLabelStyle}>Include in Barcode Listing</span>}>
          {lensCodeData.otherDetails.includeInBarcodeListing ? "Yes" : "No"}
        </Descriptions.Item>
        <Descriptions.Item label={<span style={boldLabelStyle}>Preference</span>}>
          {lensCodeData.otherDetails.preference ? "Yes" : "No"}
        </Descriptions.Item>
        <Descriptions.Item label={<span style={boldLabelStyle}>Category</span>}>
          {lensCodeData.otherDetails.category}
        </Descriptions.Item>
        <Descriptions.Item label={<span style={boldLabelStyle}>Color Treatment</span>}>
          {lensCodeData.otherDetails.colorTreatment}
        </Descriptions.Item>
        <Descriptions.Item label={<span style={boldLabelStyle}>Start Availability</span>}>
          {lensCodeData.otherDetails.startAvailability.toLocaleDateString()}
        </Descriptions.Item>
        <Descriptions.Item label={<span style={boldLabelStyle}>End Availability</span>}>
          {lensCodeData.otherDetails.endAvailability.toLocaleDateString()}
        </Descriptions.Item>
        <Descriptions.Item label={<span style={boldLabelStyle}>High Precision Compatibility</span>}>
          {lensCodeData.otherDetails.highPrecisionCompatibility ? "Yes" : "No"}
        </Descriptions.Item>
        <Descriptions.Item label={<span style={boldLabelStyle}>Other Info Notes</span>}>
          {lensCodeData.otherDetails.otherInfoNotes}
        </Descriptions.Item>
      </Descriptions>
    </Card>
  );
};

export default LensCodeSpectacleDetails;
