import React from "react";
import { Dropdown, Table, Menu, Button } from "antd";
import { PatientRecord } from "services/PatientRecordService/type";
import { MoreOutlined } from "@ant-design/icons";
import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  PhoneOutlined,
  FileAddOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { usePatientRecordService } from "services/PatientRecordService";
import useConfirmDelete from "hooks/useConfirmDelete";

interface PatientTableProps {
  dataSource: PatientRecord[];
}

const PatientTable: React.FC<PatientTableProps> = ({ dataSource }) => {
  const navigate = useNavigate();
  const { useGetAll, useDelete } = usePatientRecordService();
  const { mutateAsync: deleteUser, isPending } = useDelete();
  const { confirmDelete } = useConfirmDelete(deleteUser, isPending);

  const handleMenuClick = (key: string, record: any) => {
    switch (key) {
      case "view-profile":
        navigate(`/patient-records/details/${record?.id}`);
        break;
      case "update":
        navigate(`/user/update/${record?.key}`);
        break;
      case "delete":
        record?.key && confirmDelete(record?.key);
        break;
      default:
        break;
    }
  };

  const actionMenu = (record: any) => (
    <Menu>
      <Menu.Item
        key="view-profile"
        icon={<EyeOutlined />}
        onClick={() => handleMenuClick("view-profile", record)}
      >
        View Profile
      </Menu.Item>
      <Menu.Item
        key="edit-details"
        icon={<EditOutlined />}
        onClick={() => handleMenuClick("edit-details", record)}
      >
        Edit Details
      </Menu.Item>
      <Menu.Item
        key="schedule-call"
        icon={<PhoneOutlined />}
        onClick={() => handleMenuClick("schedule-call", record)}
      >
        Schedule Calls
      </Menu.Item>
      <Menu.Item
        key="add-notes"
        icon={<FileAddOutlined />}
        onClick={() => handleMenuClick("add-notes", record)}
      >
        Add Notes
      </Menu.Item>
      <Menu.Item
        key="delete"
        icon={<DeleteOutlined />}
        onClick={() => handleMenuClick("delete", record)}
        danger
      >
        Delete Patient
      </Menu.Item>
    </Menu>
  );

  const columns = [
    {
      title: "Patient Name",
      dataIndex: "fullName",
      key: "fullName",
      render: (text: any, record: PatientRecord) => `${record.firstName} ${record.lastName}`,
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      render: (text: any, record: PatientRecord) =>
        `${record.address.street}, ${record.address.city}, ${record.address.state} ${record.address.postalCode}`,
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Date of Birth",
      dataIndex: "dateOfBirth",
      key: "dateOfBirth",
    },
    {
      title: "Gender",
      dataIndex: "gender",
      key: "gender",
    },
    {
      title: "Action",
      render: (text: any, record: any) => (
        <Dropdown overlay={actionMenu(record)} trigger={["click"]}>
          <Button icon={<MoreOutlined />} />
        </Dropdown>
      ),
    },
  ];
  return <Table dataSource={dataSource} columns={columns} />;
};

export default PatientTable;
