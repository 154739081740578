/** @jsxImportSource @emotion/react */
import React, { useState, useCallback } from "react";
import { TicketType } from "services/TicketTypeService/types";
import { ActionKeys } from "enums/actionKeys";
import { ticketTypeColumns } from "../../config/ticketTypeColumnsConfig";
import withPagination from "hoc/withPagination";
import PaginatedTable from "components/PaginatedtTable";
import { Button, Card, Row, Typography } from "antd";
import AddTicketTypes from "./AddTicketTypes";
import { useTicketTypeService } from "services/TicketTypeService";
import { PlusOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import useConfirmDelete from "hooks/useConfirmDelete";
import PageHeader from "components/PageHeader";

const StyledCard = styled(Card)`
  border-radius: 4px;
  box-shadow: 0px 8px 40px -10px #00000014;
  padding: 40px 20px;
`;

const WrappedPaginatedTable = withPagination(PaginatedTable<TicketType>);

const ListTicketTypes: React.FC = () => {
  const { useGetAll, useDelete } = useTicketTypeService();
  const { data, isFetching, refetch } = useGetAll();
  const { mutateAsync: deleteMutation, isPending } = useDelete();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTicketType, setSelectedTicketType] = useState<TicketType | null>(null);
  const { confirmDelete } = useConfirmDelete(deleteMutation, isPending);

  const openModal = (ticketType?: TicketType) => {
    setSelectedTicketType(ticketType || null);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedTicketType(null);
  };

  const handleDelete = (record: TicketType) => {
    confirmDelete(record?._id);
  };

  const actionsConfig = [
    {
      key: ActionKeys.EDIT,
      onClick: (record: TicketType) => openModal(record),
    },
    {
      key: ActionKeys.DELETE,
      onClick: (record: TicketType) => handleDelete(record),
    },
  ];

  return (
    <StyledCard>
      <PageHeader
        title="Ticket Types"
        subtitle="Following list illustrates the ticket types."
        titleLevel={3}
      />

      <Row justify="end" style={{ marginBottom: 24 }}>
        <Button type="primary" icon={<PlusOutlined />} onClick={() => openModal()}>
          Add Ticket Type
        </Button>
      </Row>

      <WrappedPaginatedTable
        data={data?.data || []}
        total={data?.totalData || 0}
        isFetching={isFetching}
        fetchData={refetch}
        columns={ticketTypeColumns}
        actionsConfig={actionsConfig}
      />

      {isModalOpen && (
        <AddTicketTypes
          id={selectedTicketType?._id}
          isModalOpen={isModalOpen}
          onClose={closeModal}
          onSave={refetch}
        />
      )}
    </StyledCard>
  );
};

export default ListTicketTypes;
