import React from "react";
import { Card, Row, Col, Form, Input, Select, Button, InputNumber } from "antd";

const { Option } = Select;

const BulkBillingAccountsPage: React.FC = () => {
  const handleFinish = (values: any) => {
    console.log("Bulk Billing Account values: ", values);
  };

  return (
    <Card
      style={{
        boxShadow: "0px 8px 40px -10px #00000014",
        borderRadius: "4px",
      }}
    >
      <Form layout="vertical" onFinish={handleFinish}>
        {/* Form Fields */}
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item
              label="Account Code"
              name="accountCode"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input placeholder="Enter Account Code" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Vision Description"
              name="visionDescription"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input placeholder="Enter Vision Description" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Name (Mailing)"
              name="nameMailing"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input placeholder="Enter Name (Mailing)" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={8}>
            <Form.Item
              label="Province"
              name="province"
              rules={[{ required: true, message: "Required" }]}
            >
              <Select placeholder="Select Province">
                <Option value="province1">Province 1</Option>
                <Option value="province2">Province 2</Option>
                {/* Add more options as needed */}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="District"
              name="district"
              rules={[{ required: true, message: "Required" }]}
            >
              <Select placeholder="Select District">
                <Option value="district1">District 1</Option>
                <Option value="district2">District 2</Option>
                {/* Add more options as needed */}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Municipality"
              name="municipality"
              rules={[{ required: true, message: "Required" }]}
            >
              <Select placeholder="Select Municipality">
                <Option value="municipality1">Municipality 1</Option>
                <Option value="municipality2">Municipality 2</Option>
                {/* Add more options as needed */}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={8}>
            <Form.Item
              label="Street/Tole"
              name="streetTole"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input placeholder="Enter Street/Tole" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="P Code"
              name="pCode"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input placeholder="Enter P Code" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Phone No."
              name="phoneNo"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input placeholder="Enter Phone No." />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={8}>
            <Form.Item
              label="Payment Type"
              name="paymentType"
              rules={[{ required: true, message: "Required" }]}
            >
              <Select placeholder="Select Payment Type">
                <Option value="type1">Type 1</Option>
                <Option value="type2">Type 2</Option>
                {/* Add more options as needed */}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Bank" name="bank" rules={[{ required: true, message: "Required" }]}>
              <Input placeholder="Enter Bank Name" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Branch"
              name="branch"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input placeholder="Enter Branch" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={8}>
            <Form.Item label="BSB" name="bsb" rules={[{ required: true, message: "Required" }]}>
              <Input placeholder="Enter BSB" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Account No."
              name="accountNo"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input placeholder="Enter Account No." />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Account Type"
              name="accountType"
              rules={[{ required: true, message: "Required" }]}
            >
              <Select placeholder="Select Account Type">
                <Option value="savings">Savings</Option>
                <Option value="current">Current</Option>
                {/* Add more options as needed */}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={8}>
            <Form.Item
              label="Use Item No. From (Another Bulk Billing Account)"
              name="useItemNoFrom"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input placeholder="Enter Item No." />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Patient Rebate"
              name="patientRebate"
              rules={[{ required: true, message: "Required" }]}
            >
              <InputNumber style={{ width: "100%" }} placeholder="Enter Patient Rebate" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Email ID"
              name="emailId"
              rules={[{ type: "email", message: "Invalid email" }]}
            >
              <Input placeholder="Enter Email ID" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={24}>
            <Form.Item label="Notes" name="notes">
              <Input.TextArea rows={2} placeholder="Enter Notes" />
            </Form.Item>
          </Col>
        </Row>

        {/* Action Buttons */}
        <Row justify="end" gutter={16} style={{ marginTop: "20px" }}>
          <Col>
            <Button>Reset</Button>
          </Col>
          <Col>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default BulkBillingAccountsPage;
