/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Typography, Menu, Card, Table, Form } from "antd";

export const HeaderContainer = styled.div`
  background-color: white;
  border-radius: 4px;
  padding: 12px;
  box-shadow: 0px 8px 40px -10px #00000014;
`;

export const StyledTitle = styled(Typography.Title)`
  && {
    margin: 0;
    color: #333;
  }
`;

export const StyledCard = styled(Card)`
  && {
    border-radius: 12px;
    box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.1);
    margin-bottom: 24px;
  }
`;

export const StyledMenu = styled(Menu)`
  &&& {
    border-bottom: none;
    margin: 16px 0;
  }
`;

export const AvatarWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

// Table Wrapper Styles
export const TableWrapper = css`
  .ant-table {
    width: 100%;
    border-radius: 8px; /* Rounded corners for the table */
    overflow: hidden; /* Ensures child elements respect border-radius */
    // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  }
`;

// Cell Styles
export const CellStyles = css`
  background-color: white;
  .ant-table-cell {
    background-color: white;
    // border: 1px solid #ddd; /* Light border for each cell */
  }
`;

// Left Eye Column Styles
export const LeftEyeColumn = css`
  .ant-table-cell.left-eye-column {
    background-color: #1499b8;
    color: white;
    border: 1px solid #1499b8; /* Matching border for consistency */
  }
`;

// Right Eye Column Styles
export const RightEyeColumn = css`
  .ant-table-cell.right-eye-column {
    background-color: #1499b8;
    color: white;
    border: 1px solid #1499b8; /* Matching border for consistency */
  }
`;

// Sub Header Styles
export const SubHeaderStyles = css`
  .ant-table-thead > tr > th.sub-header-cell {
    background-color: #e5e7eb;
    border: 1px solid #ccc; /* Subtle border for the header cells */
    font-weight: bold;
  }
`;

// Matrix Table Styles
export const matrixTableStyle = css``;

// Button Styles
export const buttonStyles = css`
  .ant-btn {
    border-radius: 4px; /* Rounded corners for buttons */
    font-size: 14px; /* Slightly larger font size for readability */
  }
  .ant-btn-primary {
    background-color: #1499b8; /* Consistent with table color */
  }
  .ant-btn-primary:hover {
    background-color: #354f9f; /* Slightly darker shade on hover */
    border-color: #354f9f;
  }
  .ant-btn-icon-only {
    border-radius: 50%; /* Rounded buttons for icons */
    width: 32px;
    height: 32px;
    line-height: 32px;
    text-align: center;
  }
`;

export const tabsStyle = css`
  .ant-tabs-nav {
    background: #ffffff;
    border-bottom: 1px solid #e0e0e0;
    padding: 0 16px 0px 0px;
  }

  .ant-tabs-nav .ant-tabs-tab {
    font-weight: 600;
    color: #333;
    font-size: 14px;
    padding: 16px 0px;
    margin-right: 34px;
  }

  .ant-tabs-nav .ant-tabs-tab-active {
    color: #1890ff;
    border-bottom: 2px solid #1890ff;
    font-weight: 700;
  }

  .ant-tabs-content {
    padding: 0px 0px;
  }

  margin-top: 0px;
`;

export const cardStyle = css`
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0px 8px 40px -10px #00000014;

  .ant-input,
  .ant-select {
    border: 1px solid #d9d9d9;
    border-radius: 6px;
  }

  .ant-input-textarea {
  }

  .ant-card,
  .ant-card-head {
    min-height: 30px;
    font-size: 14px;
    padding: 8px 18px;
  }

  .ant-card,
  .ant-card-body {
    padding: 8px 18px;
  }
  .ant-table-thead > tr > th {
    background-color: #f0f0f0;
    color: #555;
    font-weight: 600;
    text-align: center;
    padding: 4px 4px;
  }

  .ant-table-tbody > tr > td {
    text-align: center;
  }
`;

export const binocularCardStyle = css`
  .ant-input,
  .ant-select {
    border: 1px solid #d9d9d9;
    border-radius: 6px;
  }

  .ant-input-textarea {
  }

  .ant-table-thead > tr > th {
    background-color: #f0f0f0;
    color: #555;
    font-weight: 600;
    text-align: center;
    padding: 4px 4px;
  }

  .ant-table-tbody > tr > td {
    text-align: center;
  }
`;

export const titleStyle = css`
  font-size: 24px;
  font-weight: 600;
  color: #333;
  margin-bottom: 24px;
`;

export const formItemStyle = css`
  .ant-form-item-label {
    font-weight: 500;
    color: #555;
  }

  .ant-input,
  .ant-select {
    border-radius: 8px;
  }
`;

export const tableLabelStyle = css`
  .ant-table-thead > tr > th {
    white-space: nowrap;
    text-align: center;
    padding: 4px 4px;
  }

  .ant-table-tbody > tr > td {
    text-align: center;
  }
`;

export const tableStyles = css`
  .ant-table-wrapper .ant-table.ant-table-small .ant-table-cell,
  .ant-table-wrapper .ant-table.ant-table-small .ant-table-thead > tr > th {
    padding: 4px 4px;
  }
`;

export const StyledTable = styled(Table)`
  .ant-table-small .ant-table-cell,
  .ant-table-small .ant-table-thead > tr > th {
    padding: 5px !important;
  }
`;

export const StyledForm = styled(Form)`
  .ant-form-item {
    margin-bottom: 4px !important;
  }

  .ant-form-vertical .ant-form-item:not(.ant-form-item-horizontal) .ant-form-item-label {
    padding: 0;
  }
`;
