import React, { useState } from "react";
import {
  Card,
  Row,
  Col,
  Menu,
  Divider,
  Button,
  Table,
  Form,
  Input,
  Select,
  Radio,
  List,
  DatePicker,
} from "antd";
import dayjs from "dayjs";

// Define reusable data source for the payment summary categories
const paymentSummaryData = [
  { label: "Consult", cost: "$100" },
  { label: "Frame", cost: "$150" },
  { label: "Lens", cost: "$200" },
  { label: "CL Sunglasses", cost: "$50" },
  { label: "Sundry", cost: "$25" },
  { label: "Misc", cost: "$30" },
  { label: "Total Receipt", cost: "$555" },
  { label: "GST Collected", cost: "$55" },
  { label: "Total Receipt including GST", cost: "$610" },
];

// FrameApprovalPage component
const PaymentSummaryPage: React.FC = () => {
  const [selectedKey, setSelectedKey] = useState("paymentSummary");
  const [reportData, setReportData] = useState<any[]>([]);
  const [dateRange, setDateRange] = useState<[string, string] | null>(null);

  const handleMenuClick = (e: any) => {
    setSelectedKey(e.key);
  };

  const handleFinish = (values: any) => {
    setDateRange([
      dayjs(values.startDate).format("DD-MM-YYYY"),
      dayjs(values.endDate).format("DD-MM-YYYY"),
    ]);

    // Simulated report data after form submission
    setReportData(paymentSummaryData);
  };

  const renderContent = () => {
    switch (selectedKey) {
      case "paymentSummary":
        return (
          <Card
            style={{
              height: "calc(100vh - 138px)",
              overflowY: "auto",
            }}
          >
            <Divider orientation="left" orientationMargin="0" style={{ marginBottom: "34px" }}>
              Payment Summary
            </Divider>
            <Form layout="vertical" onFinish={handleFinish}>
              <Row gutter={16}>
                <Col span={8}>
                  <Form.Item
                    label="Start Date"
                    name="startDate"
                    rules={[{ required: true, message: "Required" }]}
                  >
                    <DatePicker style={{ width: "100%" }} />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="End Date"
                    name="endDate"
                    rules={[{ required: true, message: "Required" }]}
                  >
                    <DatePicker style={{ width: "100%" }} />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="Report Destination" name="reportDestination">
                    <Radio.Group>
                      <Radio value="screen">Screen</Radio>
                      <Radio value="printer">Printer</Radio>
                      <Radio value="printerWithPrompt">Printer with Prompt</Radio>
                      <Radio value="excelSpreadsheet">Excel Spreadsheet</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16} style={{ marginTop: "20px" }} justify="end">
                <Button type="default">Reset</Button>
                <Button type="primary" htmlType="submit" style={{ marginLeft: "12px" }}>
                  Generate Report
                </Button>
              </Row>
            </Form>
            {reportData.length > 0 && (
              <>
                <h3 style={{ marginBottom: "16px" }}>Payment Summary from</h3>

                <List
                  size="small"
                  bordered
                  header={<div style={{ fontWeight: "bold" }}>Receipt including GST</div>}
                  dataSource={reportData}
                  renderItem={(item) => (
                    <List.Item
                      style={{
                        borderBottom: "1px dotted #D1D5DB",
                        backgroundColor: "#F9FAFB",
                        padding: "16px",
                      }}
                    >
                      <div>{item.label}</div>
                      <div
                        style={{
                          textAlign: "right",
                          flex: 1,
                          fontWeight: "bold",
                          paddingLeft: "8px",
                        }}
                      >
                        {item.cost}
                      </div>
                    </List.Item>
                  )}
                />

                <Row gutter={16} style={{ marginTop: "24px" }}>
                  <Col span={12}>
                    <Card
                      title="Taxable Amount"
                      style={{
                        border: "1px solid #D1D5DB",
                        marginTop: "16px",
                        padding: "16px",
                        backgroundColor: "#F9FAFB",
                      }}
                    >
                      <div style={{ textAlign: "right", fontWeight: "bold" }}>$500</div>
                    </Card>
                  </Col>
                  <Col span={12}>
                    <Card
                      title="Non-Taxable Amount"
                      style={{
                        border: "1px solid #D1D5DB",
                        marginTop: "16px",
                        padding: "16px",
                        backgroundColor: "#F9FAFB",
                      }}
                    >
                      <div style={{ textAlign: "right", fontWeight: "bold" }}>$110</div>
                    </Card>
                  </Col>
                </Row>
              </>
            )}
          </Card>
        );
      case "paymentSummaryByDate":
        return (
          <Card
            style={{
              height: "calc(100vh - 138px)",
              overflowY: "auto",
            }}
          >
            <Divider orientation="left" orientationMargin="0" style={{ marginBottom: "34px" }}>
              Payment Summary by Date
            </Divider>
            {/* You can reuse the form from the previous case */}
            <Form layout="vertical" onFinish={handleFinish}>
              {/* Same form elements */}
            </Form>
          </Card>
        );
      case "paymentSummaryByProvider":
        return (
          <Card
            style={{
              height: "calc(100vh - 138px)",
              overflowY: "auto",
            }}
          >
            <Divider orientation="left" orientationMargin="0" style={{ marginBottom: "34px" }}>
              Payment Summary by Provider
            </Divider>
            {/* Implement Payment Summary by Provider */}
          </Card>
        );
      case "paymentSummaryByPaymentType":
        return (
          <Card
            style={{
              height: "calc(100vh - 138px)",
              overflowY: "auto",
            }}
          >
            <Divider orientation="left" orientationMargin="0" style={{ marginBottom: "34px" }}>
              Payment Summary by Payment Type
            </Divider>
            {/* Implement Payment Summary by Payment Type */}
          </Card>
        );
      default:
        return (
          <Card
            style={{
              height: "calc(100vh - 138px)",
              overflowY: "auto",
            }}
          >
            Select a menu item to see content.
          </Card>
        );
    }
  };

  return (
    <Row gutter={24} style={{ height: "100vh" }}>
      <Col span={8}>
        <Card
          style={{
            boxShadow: "0px 8px 40px -10px #00000014",
            borderRadius: "4px",
            height: "calc(100vh - 138px)",
          }}
        >
          <Menu
            mode="inline"
            selectedKeys={[selectedKey]}
            onClick={handleMenuClick}
            style={{ border: "none" }}
          >
            <Menu.Item
              key="paymentSummary"
              style={
                selectedKey === "paymentSummary"
                  ? { backgroundColor: "#18B6DA", color: "#fff" }
                  : {}
              }
            >
              Payment Summary
            </Menu.Item>
            <Menu.Item
              key="paymentSummaryByDate"
              style={
                selectedKey === "paymentSummaryByDate"
                  ? { backgroundColor: "#18B6DA", color: "#fff" }
                  : {}
              }
            >
              Payment Summary by Date
            </Menu.Item>
            <Menu.Item
              key="paymentSummaryByProvider"
              style={
                selectedKey === "paymentSummaryByProvider"
                  ? { backgroundColor: "#18B6DA", color: "#fff" }
                  : {}
              }
            >
              Payment Summary by Provider
            </Menu.Item>
            <Menu.Item
              key="paymentSummaryByPaymentType"
              style={
                selectedKey === "paymentSummaryByPaymentType"
                  ? { backgroundColor: "#18B6DA", color: "#fff" }
                  : {}
              }
            >
              Payment Summary by Payment Type
            </Menu.Item>
          </Menu>
        </Card>
      </Col>
      <Col span={16}>{renderContent()}</Col>
    </Row>
  );
};

export default PaymentSummaryPage;
