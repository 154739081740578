import React, { useState } from "react";
import { Card, Divider, Row, Col, Form, Input, Button, Radio, DatePicker, Table } from "antd";
import dayjs from "dayjs";

const { RangePicker } = DatePicker;

const SSFBillingItemSummary: React.FC = () => {
  const [reportData, setReportData] = useState<any[]>([]);
  const [dateRange, setDateRange] = useState<[string, string] | null>(null);

  // Handle form submission
  const handleFinish = (values: any) => {
    console.log("Report values: ", values);

    // Set the formatted date range
    setDateRange([
      dayjs(values.dateRange[0]).format("DD-MM-YYYY"),
      dayjs(values.dateRange[1]).format("DD-MM-YYYY"),
    ]);

    // Dummy data for SSF Billing Item Summary
    setReportData([
      {
        key: 1,
        by: "User A",
        itemNumber: "ITEM001",
        description: "Item 1 Description",
        quantity: 100,
        quantityPercent: 50,
        itemAmount: "$500",
      },
      {
        key: 2,
        by: "User B",
        itemNumber: "ITEM002",
        description: "Item 2 Description",
        quantity: 200,
        quantityPercent: 30,
        itemAmount: "$600",
      },
      {
        key: 3,
        by: "User C",
        itemNumber: "ITEM003",
        description: "Item 3 Description",
        quantity: 150,
        quantityPercent: 20,
        itemAmount: "$450",
      },
      // Add more rows as needed
    ]);
  };

  // Define the table columns
  const columns = [
    { title: "By", dataIndex: "by", key: "by" },
    { title: "Item Number", dataIndex: "itemNumber", key: "itemNumber" },
    { title: "Description", dataIndex: "description", key: "description" },
    { title: "Quantity", dataIndex: "quantity", key: "quantity" },
    { title: "Quantity (%)", dataIndex: "quantityPercent", key: "quantityPercent" },
    { title: "Item Amount", dataIndex: "itemAmount", key: "itemAmount" },
  ];

  return (
    <>
      <Card
        style={{
          boxShadow: "0px 8px 40px -10px #00000014",
          borderRadius: "4px",
        }}
      >
        <Form layout="vertical" onFinish={handleFinish}>
          <Divider orientation="left" orientationMargin="0" style={{ marginBottom: "34px" }}>
            SSF Billing Item Summary Report
          </Divider>

          <Row gutter={24}>
            <Col span={8}>
              <Form.Item
                label="Start Date"
                name="dateRange"
                rules={[{ required: true, message: "Required" }]}
              >
                <DatePicker style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="End Date"
                name="itemCode"
                rules={[{ required: true, message: "Required" }]}
              >
                <DatePicker style={{ width: "100%" }} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={24}>
              <Form.Item label="Report Destination" name="reportDestination">
                <Radio.Group>
                  <Radio value="screen">To Screen</Radio>
                  <Radio value="printer">To Printer</Radio>
                  <Radio value="printerWithPrompt">To Printer with Prompt</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>

          <Row justify={"end"}>
            <div>
              <Button>Reset</Button>
              <Button
                type="primary"
                htmlType="submit"
                style={{ marginTop: "34px", marginLeft: "12px" }}
              >
                Generate Report
              </Button>
            </div>
          </Row>
        </Form>
      </Card>

      {reportData.length > 0 && (
        <Card
          style={{
            marginTop: "24px",
            boxShadow: "0px 8px 40px -10px #00000014",
            borderRadius: "4px",
          }}
        >
          <Divider orientation="left" orientationMargin="0">
            SSF Billing Item Summary Report {dateRange?.[0]} To {dateRange?.[1]}
          </Divider>
          <Table columns={columns} dataSource={reportData} pagination={false} />
        </Card>
      )}
    </>
  );
};

export default SSFBillingItemSummary;
