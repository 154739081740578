import React from "react";
import {
  Card,
  Divider,
  Row,
  Col,
  Form,
  Input,
  InputNumber,
  Button,
  Switch,
  Select,
  Radio,
} from "antd";
import { LensCodeInterface } from "services/LensCodeService";

const { Option } = Select;

const boldLabelStyle = { fontWeight: "bold", color: "#141414" };

const LensCodeSpectacleForm: React.FC = () => {
  const handleFinish = (values: LensCodeInterface) => {
    console.log("Form values: ", values);
  };

  return (
    <Card
      style={{
        boxShadow: "0px 8px 40px -10px #00000014",
        borderRadius: "4px",
      }}
    >
      <Form layout="vertical" onFinish={handleFinish}>
        {/* Lens Code Details Section */}
        <Divider orientation="left" orientationMargin="0" style={{ marginBottom: "34px" }}>
          Lens Details
        </Divider>
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item
              label={<span style={boldLabelStyle}>Lens Code</span>}
              name="lensCode"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input placeholder="Lens Code" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={<span style={boldLabelStyle}>Refractive Index</span>}
              name="refIndex"
              rules={[{ required: true, message: "Required" }]}
            >
              <InputNumber placeholder="Refractive Index" min={0} style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={<span style={boldLabelStyle}>Base Curve</span>}
              name="baseCurve"
              rules={[{ required: true, message: "Required" }]}
            >
              <InputNumber placeholder="Base Curve" min={0} style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={<span style={boldLabelStyle}>Lens Size</span>}
              name="lensSize"
              rules={[{ required: true, message: "Required" }]}
            >
              <InputNumber placeholder="Lens Size" min={0} style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={<span style={boldLabelStyle}>Sag Size</span>}
              name="sagSize"
              rules={[{ required: true, message: "Required" }]}
            >
              <InputNumber placeholder="Sag Size" min={0} style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={<span style={boldLabelStyle}>Material</span>}
              name="material"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input placeholder="Material" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={<span style={boldLabelStyle}>Stock/Grind</span>}
              name="stockGrind"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input placeholder="Stock/Grind" />
            </Form.Item>
          </Col>
          <Col span={24}></Col>
          <Col span={8}>
            <Form.Item
              label={<span style={boldLabelStyle}>Lab Description</span>}
              name="labDescription"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input.TextArea rows={3} placeholder="Description" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={<span style={boldLabelStyle}>Invoice Description</span>}
              name="invoiceDescription"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input.TextArea rows={3} placeholder="Description" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={<span style={boldLabelStyle}>Description</span>}
              name="description"
              rules={[{ required: false }]}
            >
              <Input.TextArea rows={3} placeholder="Description" />
            </Form.Item>
          </Col>
        </Row>

        {/* Pricing Details Section */}
        <Divider orientation="left" orientationMargin="0" style={{ margin: "40px 0 34px 0" }}>
          Pricing Details
        </Divider>
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item
              label={<span style={boldLabelStyle}>Retail Price Tax Rate (%)</span>}
              name={["pricingDetails", "retailPriceTaxRate"]}
              rules={[{ required: true, message: "Required" }]}
            >
              <InputNumber
                min={0}
                max={100}
                placeholder="Retail Tax Rate"
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={<span style={boldLabelStyle}>Retail Price per Lens (Inc. Tax)</span>}
              name={["pricingDetails", "retailPricePerLensIncTax"]}
              rules={[{ required: true, message: "Required" }]}
            >
              <InputNumber placeholder="Retail Price Inc. Tax" min={0} style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={<span style={boldLabelStyle}>Cost Price per Lens (Exc. Tax)</span>}
              name={["pricingDetails", "costPricePerLensExcTax"]}
              rules={[{ required: true, message: "Required" }]}
            >
              <InputNumber placeholder="Cost Price Exc. Tax" min={0} style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={<span style={boldLabelStyle}>Cost Price Tax Rate (%)</span>}
              name={["pricingDetails", "costPriceTaxRate"]}
              rules={[{ required: true, message: "Required" }]}
            >
              <InputNumber
                min={0}
                max={100}
                placeholder="Cost Price Tax Rate"
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={<span style={boldLabelStyle}>Cost Price per Lens (Inc. Tax)</span>}
              name={["pricingDetails", "costPricePerLensIncTax"]}
              rules={[{ required: true, message: "Required" }]}
            >
              <InputNumber placeholder="Cost Price Inc. Tax" min={0} style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={<span style={boldLabelStyle}>Price List Grouping</span>}
              name={["pricingDetails", "priceListGrouping"]}
              rules={[{ required: true, message: "Required" }]}
            >
              <Input placeholder="Price List Grouping" />
            </Form.Item>
          </Col>
        </Row>

        {/* Supplier Details Section */}
        <Divider orientation="left" orientationMargin="0" style={{ margin: "40px 0 34px 0" }}>
          Supplier Details
        </Divider>
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item
              label={<span style={boldLabelStyle}>Supplier</span>}
              name={["supplierDetails", "supplier"]}
              rules={[{ required: true, message: "Required" }]}
            >
              <Input placeholder="Supplier" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={<span style={boldLabelStyle}>Supplier Product Code</span>}
              name={["supplierDetails", "supplierProductCode"]}
              rules={[{ required: true, message: "Required" }]}
            >
              <Input placeholder="Supplier Product Code" />
            </Form.Item>
          </Col>
        </Row>

        {/* Other Details Section */}
        <Divider orientation="left" orientationMargin="0" style={{ margin: "40px 0 34px 0" }}>
          Other Details
        </Divider>
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item
              label={<span style={boldLabelStyle}>Eye Talk Code</span>}
              name="eyeTalkCode"
              rules={[{ required: true, message: "Eye Talk Code is required" }]}
            >
              <Input placeholder="Eye Talk Code" />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              label={<span style={boldLabelStyle}>Health Fund Item No. 1 - 1 Lens</span>}
              name="healthFundItem1"
              rules={[{ required: true, message: "Health Fund Item No. 1 is required" }]}
            >
              <Input placeholder="Health Fund Item No. 1 - 1 Lens" />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              label={<span style={boldLabelStyle}>Health Fund Item No. 2 - 2 Lenses</span>}
              name="healthFundItem2"
              rules={[{ required: true, message: "Health Fund Item No. 2 is required" }]}
            >
              <Input placeholder="Health Fund Item No. 2 - 2 Lenses" />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              label={<span style={boldLabelStyle}>Include in Picklist?</span>}
              name="includeInPicklist"
              rules={[{ required: true, message: "Please specify if included in picklist" }]}
            >
              <Radio.Group>
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              label={<span style={boldLabelStyle}>Include in Barcode Listing?</span>}
              name="includeInBarcodeListing"
              rules={[{ required: true, message: "Please specify if included in barcode listing" }]}
            >
              <Radio.Group>
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              label={<span style={boldLabelStyle}>Preference?</span>}
              name="preference"
              rules={[{ required: true, message: "Please specify preference" }]}
            >
              <Radio.Group>
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              label={<span style={boldLabelStyle}>Category</span>}
              name="category"
              rules={[{ required: true, message: "Category is required" }]}
            >
              <Select placeholder="Select Category">
                <Option value="category1">Category 1</Option>
                <Option value="category2">Category 2</Option>
                <Option value="category3">Category 3</Option>
              </Select>
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              label={<span style={boldLabelStyle}>Color Treatment</span>}
              name="colorTreatment"
              rules={[{ required: true, message: "Color Treatment is required" }]}
            >
              <Input placeholder="Color Treatment" />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              label={<span style={boldLabelStyle}>Start Availability</span>}
              name="startAvailability"
              rules={[{ required: true, message: "Start Availability is required" }]}
            >
              <Input type="date" placeholder="Start Availability" />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              label={<span style={boldLabelStyle}>End Availability</span>}
              name="endAvailability"
              rules={[{ required: true, message: "End Availability is required" }]}
            >
              <Input type="date" placeholder="End Availability" />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              label={<span style={boldLabelStyle}>High Precision Compatibility?</span>}
              name="highPrecisionCompatibility"
              rules={[
                { required: true, message: "Please specify if high precision compatibility" },
              ]}
            >
              <Radio.Group>
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              label={<span style={boldLabelStyle}>Other Info/Notes</span>}
              name="otherInfo"
              rules={[{ required: false }]}
            >
              <Input.TextArea rows={4} placeholder="Other Info/Notes" />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item style={{ textAlign: "right", marginTop: "40px" }}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default LensCodeSpectacleForm;
