import styled from "@emotion/styled";
import { Button, Checkbox, Col, Radio, Row, Typography } from "antd";

export const LoginPageWrapper = styled(Row)`
  height: 100vh;
`;

export const LoginCard = styled(Col)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #f7f7f8;
`;

export const LoginFormContainer = styled.div`
  max-width: 380px;
  width: 100%;
`;

export const Title = styled(Typography.Title)`
  color: #263446;
  font-weight: 700;
  margin: 3px;
`;

export const DescriptionText = styled(Typography.Text)`
  color: rgba(0, 0, 0, 0.45);
`;

export const CustomRadioGroup = styled(Radio.Group)`
  width: 100%;
  margin: 30px 0;
  height: 38px;
`;

export const CustomRadio = styled(Radio.Button)`
  width: 33.3%;
  text-align: center;
`;

export const SubmitButton = styled(Button)`
  width: 100%;
  height: 38px;
`;

export const ForgotPasswordLink = styled(Typography.Link)`
  margin-left: auto;
`;

export const OuterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: #fff;
`;

export const InnerContainer = styled.div`
  width: 1076px;
  height: 600px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0px 72px 80px -48px #174252;
`;

export const LeftColumn = styled.div`
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #fff;

  h2 {
    margin-bottom: 24px;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
  }
`;

export const RightColumn = styled.div`
  height: 100%;
  background-color: #18b6da03;
`;
