import NotFound from "components/PageNotFound";
import { SubscriptionForm, SubscriptionPage } from "Scenes/Subscription";
import { PatientRecord, PatientRecordForm, PatientRecordDetailsPage } from "Scenes/PatientRecords";
import { AppointmentPage } from "Scenes/Appointment";
import ListTicketTypes from "Scenes/Support/components/SupportTicketType/ListTicketTypes";
import { HelpAndSupport } from "Scenes/Support";
import { ConsultationPage } from "Scenes/Consultation";
import { ResetPasswordPage } from "Scenes/PasswordRecovery";
import { ChangePasswordPage } from "Scenes/PasswordRecovery";
import { Dashboard } from "Scenes/Dashboard";
import { LayoutPage } from "Scenes/Layout";
import { LoginPage } from "Scenes/Auth/Login";
import ConsultationDetailsPage from "Scenes/Consultation/components/ConsultationDetails";
import SubscriptionCard from "Scenes/Subscription/components/SubscriptionCard";
import { SundryDetailsPage, SundryListPage, SundryForm } from "Scenes/Inventory/Sundry";
import { FrameListPage, FrameForm, FrameDetailsPage } from "Scenes/Inventory/Frame";
import { SupplierListPage, SupplierDetailsPage, SupplierForm } from "Scenes/MasterSetup/Supplier";
import { PaymentTypeList } from "Scenes/MasterSetup/PaymentType";
import { FrameGroupList } from "Scenes/MasterSetup/FrameGroup";
import { UserList } from "Scenes/UserManagement";
import {
  LensCodeSpectacleDetails,
  LensCodeSpectacleForm,
  LensCodeSpectacleList,
} from "Scenes/MasterSetup/LensSpectacle";
import { SupportTicketTypeList } from "Scenes/MasterSetup/SupportTicketType";
import {
  AdjustmentReport,
  FrameOrderingFromUsage,
  FrameStockReport,
  InvoiceReport,
  SundryStockReport,
} from "Scenes/Inventory/Reports";
import { FrameApprovalPage } from "Scenes/Inventory/FrameApporval";
import FrameBarcodeReportByPurchaseDate from "Scenes/Inventory/Reports/components/FrameBarcodeReportByPurchaseDate";
import LensBarcodeReport from "Scenes/Inventory/Reports/components/LensBarcodeReport";
import LensPriceListReport from "Scenes/Inventory/Reports/components/LensPriceListReport";
import { FrameStocktakePage } from "Scenes/Inventory/FrameStocktake";
import { PettyCashPage } from "Scenes/Financial/PettyCash";
import { FrameTypeList } from "Scenes/MasterSetup/FrameType";
import WorkingHoursConfig from "Scenes/MasterSetup/WorkingHours/components/WorkingHoursConfig";
import { AppointmentTypeForm, AppointmentTypeList } from "Scenes/MasterSetup/AppointmentType";
import { PractitionerSetup } from "Scenes/MasterSetup/PractitionerSetup";
import { BankingPage } from "Scenes/Financial/Banking";
import BulkBillingAccountsPage from "Scenes/Financial/BulkBillingAccounts";
import BulkBillingPaymentPage from "Scenes/Financial/BulkBillingPaymentTablePage";
import BulkBillingPaymentFormPage from "Scenes/Financial/BulkBillingPaymentFormPage";
import OutstandingBulkBillingAccounts from "Scenes/Financial/OutstandingBulkBillingAccounts";
import SupplierInvoiceCreditReport from "Scenes/Financial/SupplierInvoiceCreditReport";
import BankReconciliation from "Scenes/Financial/BankReconciliation";
import BillingSummary from "Scenes/Financial/BillingSummary";
import SSFBillingItemSummary from "Scenes/Financial/SSFBillingItemSummary";
import BillingSearchByItemNumber from "Scenes/Financial/BillingSearchByItemNumber";
import PaymentSummaryPage from "Scenes/Financial/PaymentSummaryPage";

export const RouteComponents = {
  LoginPage,
  ResetPasswordPage,
  ChangePasswordPage,
  Dashboard,
  AppointmentPage,
  PatientRecord,
  PatientRecordForm,
  SubscriptionForm,
  ConsultationPage,
  HelpAndSupport,
  NotFound,
  LayoutPage,
  SubscriptionPage,
  ListTicketTypes,
  ConsultationDetailsPage,
  SubscriptionCard,
  SundryListPage,
  SundryDetailsPage,
  SundryForm,
  FrameListPage,
  SupplierListPage,
  SupplierDetailsPage,
  SupplierForm,
  FrameForm,
  FrameDetailsPage,
  PaymentTypeList,
  FrameGroupList,
  UserList,
  PatientRecordDetailsPage,
  LensCodeSpectacleList,
  LensCodeSpectacleForm,
  LensCodeSpectacleDetails,
  SupportTicketTypeList,
  AdjustmentReport,
  InvoiceReport,
  FrameStockReport,
  SundryStockReport,
  FrameApprovalPage,
  FrameOrderingFromUsage,
  FrameBarcodeReportByPurchaseDate,
  LensBarcodeReport,
  LensPriceListReport,
  FrameStocktakePage,
  PettyCashPage,
  FrameTypeList,
  WorkingHoursConfig,
  AppointmentTypeList,
  AppointmentTypeForm,
  PractitionerSetup,
  BankingPage,
  BulkBillingAccountsPage,
  BulkBillingPaymentPage,
  BulkBillingPaymentFormPage,
  OutstandingBulkBillingAccounts,
  SupplierInvoiceCreditReport,
  BankReconciliation,
  BillingSummary,
  SSFBillingItemSummary,
  BillingSearchByItemNumber,
  PaymentSummaryPage,
};
