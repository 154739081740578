import React from "react";
import { Card } from "antd";
import Calendar from "./AppointmentCalendar";
import PageTemplate from "components/PageTemplate";
import AppointmentDetails from "./AppointmentDetails";
import CalendarHeader from "./CalendarControls";
import { AppointmentProvider } from "../context";
import AppointmentForm from "../form/AppointmentForm";

const AppointmentPageContent: React.FC = () => {
  return (
    <PageTemplate currentNode="Appointment">
      <Card
        style={{
          boxShadow: "0px 8px 40px -10px #00000014",
          borderRadius: "4px",
        }}
      >
        <CalendarHeader />
        <Calendar />
      </Card>
      <AppointmentForm />
      <AppointmentDetails />
    </PageTemplate>
  );
};

const AppointmentPage: React.FC = () => {
  return (
    <AppointmentProvider>
      <AppointmentPageContent />
    </AppointmentProvider>
  );
};

export default AppointmentPage;
