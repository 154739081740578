import React from "react";
import { Card, Form, Input, InputNumber, Button, Row, Col, DatePicker } from "antd";

const BulkBillingPaymentFormPage: React.FC = () => {
  const handleFinish = (values: any) => {
    console.log("Form values: ", values);
  };

  return (
    <Card
      style={{
        boxShadow: "0px 8px 40px -10px #00000014",
        borderRadius: "4px",
      }}
    >
      <Form layout="vertical" onFinish={handleFinish}>
        {/* Disabled Fields */}
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item label="Billing No. (Trans ID)" name="billingNo">
              <Input disabled placeholder="Auto-generated" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Billing Period" name="billingPeriod">
              <Input disabled placeholder="Auto-generated" />
            </Form.Item>
          </Col>
        </Row>

        {/* Enabled Fields */}
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item
              label="Date Sent"
              name="dateSent"
              rules={[{ required: true, message: "Please select the date" }]}
            >
              <DatePicker style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Total Billing $"
              name="totalBilling"
              rules={[{ required: true, message: "Please enter the total billing" }]}
            >
              <InputNumber style={{ width: "100%" }} placeholder="Enter amount" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Outstanding"
              name="outstanding"
              rules={[{ required: true, message: "Please enter the outstanding amount" }]}
            >
              <InputNumber style={{ width: "100%" }} placeholder="Enter amount" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item
              label="GST of Charges $"
              name="gstCharges"
              rules={[{ required: true, message: "Please enter GST charges" }]}
            >
              <InputNumber style={{ width: "100%" }} placeholder="Enter amount" disabled />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Total Payment $"
              name="totalPayment"
              rules={[{ required: true, message: "Please enter total payment" }]}
            >
              <InputNumber style={{ width: "100%" }} placeholder="Enter amount" disabled />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Total Payment Counts"
              name="paymentCounts"
              rules={[{ required: true, message: "Please enter total payment counts" }]}
            >
              <InputNumber style={{ width: "100%" }} placeholder="Enter count" disabled />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item
              label="Claim Numbers"
              name="claimNumbers"
              rules={[{ required: true, message: "Please enter claim numbers" }]}
            >
              <Input placeholder="Enter claim numbers" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Comments"
              name="comments"
              rules={[{ required: true, message: "Please enter comments" }]}
            >
              <Input.TextArea rows={4} placeholder="Enter comments" />
            </Form.Item>
          </Col>
        </Row>

        {/* Action Buttons */}
        <Row justify="end" gutter={16}>
          <Col>
            <Button>Online Claim</Button>
          </Col>
          <Col>
            <Button>Close Period</Button>
          </Col>
          <Col>
            <Button>Payment</Button>
          </Col>
          <Col>
            <Button type="primary">Transaction</Button>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default BulkBillingPaymentFormPage;
