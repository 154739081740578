import React from "react";
import { Card, Divider, Row, Col, Form, Input, Button, Select } from "antd";

const FrameBarcodeReportByPurchaseDate: React.FC = () => {
  const handleFinish = (values: any) => {
    console.log("Report values: ", values);
  };

  return (
    <>
      <Card
        style={{
          boxShadow: "0px 8px 40px -10px #00000014",
          borderRadius: "4px",
        }}
      >
        <Form layout="vertical" onFinish={handleFinish}>
          {/* Report Details Section */}
          <Divider orientation="left" orientationMargin="0" style={{ marginBottom: "34px" }}>
            Frame Barcode Report By Purchase Date
          </Divider>
          <Row gutter={24}>
            <Col span={8}>
              <Form.Item
                label="Frame Purchased From"
                name="framePurchasedFrom"
                rules={[{ required: true, message: "Required" }]}
              >
                <Input type="date" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Frame Purchased To"
                name="framePurchasedTo"
                rules={[{ required: true, message: "Required" }]}
              >
                <Input type="date" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Supplier Code"
                name="supplierCode"
                rules={[{ required: true, message: "Required" }]}
              >
                <Select placeholder="Select Supplier Code">
                  <Select.Option value="supplier1">Supplier 1</Select.Option>
                  <Select.Option value="supplier2">Supplier 2</Select.Option>
                  <Select.Option value="supplier3">Supplier 3</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={8}>
              <Form.Item
                label="Brand"
                name="brand"
                rules={[{ required: true, message: "Required" }]}
              >
                <Input placeholder="Enter Brand" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Frame Type"
                name="frameType"
                rules={[{ required: true, message: "Required" }]}
              >
                <Input placeholder="Enter Frame Type" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Frame Group"
                name="frameGroup"
                rules={[{ required: true, message: "Required" }]}
              >
                <Select placeholder="Select Frame Group">
                  <Select.Option value="group1">Group 1</Select.Option>
                  <Select.Option value="group2">Group 2</Select.Option>
                  <Select.Option value="group3">Group 3</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={8}>
              <Form.Item
                label="Frame Status"
                name="frameStatus"
                rules={[{ required: true, message: "Required" }]}
              >
                <Select placeholder="Select Frame Status">
                  <Select.Option value="available">Available</Select.Option>
                  <Select.Option value="unavailable">Unavailable</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Location"
                name="location"
                rules={[{ required: true, message: "Required" }]}
              >
                <Input placeholder="Enter Location" />
              </Form.Item>
            </Col>
          </Row>

          {/* Action Buttons */}
          <Row justify={"end"}>
            <div>
              <Button>Reset</Button>
              <Button
                type="primary"
                htmlType="submit"
                style={{ marginTop: "34px", marginLeft: "12px" }}
              >
                Generate Report
              </Button>
            </div>
          </Row>
        </Form>
      </Card>
    </>
  );
};

export default FrameBarcodeReportByPurchaseDate;
