import React from "react";
import { Card, Divider, Row, Col, Form, Input, Button, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";

const boldLabelStyle = { fontWeight: "bold", color: "#141414" };

const SupplierForm: React.FC = () => {
  const handleFinish = (values: any) => {
    console.log("Form values: ", values);
  };

  return (
    <Card
      style={{
        boxShadow: "0px 8px 40px -10px #00000014",
        borderRadius: "4px",
      }}
    >
      <Form layout="vertical" onFinish={handleFinish}>
        {/* Supplier Details Section */}
        <Divider orientation="left" orientationMargin="0" style={{ marginBottom: "34px" }}>
          Supplier Details
        </Divider>
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item
              label={<span style={boldLabelStyle}>Supplier Name</span>}
              name="supplierName"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input placeholder="Supplier Name" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={<span style={boldLabelStyle}>Supplier Code</span>}
              name="supplierCode"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input placeholder="Supplier Code" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={<span style={boldLabelStyle}>Alias</span>}
              name="alias"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input placeholder="Alias" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={<span style={boldLabelStyle}>ABN</span>}
              name="abn"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input placeholder="ABN" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={<span style={boldLabelStyle}>Contact Name</span>}
              name="contactName"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input placeholder="Contact Name" />
            </Form.Item>
          </Col>
        </Row>

        {/* Contact Details Section */}
        <Divider orientation="left" orientationMargin="0" style={{ margin: "40px 0 34px 0" }}>
          Contact Details
        </Divider>
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item
              label={<span style={boldLabelStyle}>Province</span>}
              name="province"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input placeholder="Province" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={<span style={boldLabelStyle}>District</span>}
              name="district"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input placeholder="District" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={<span style={boldLabelStyle}>Municipality</span>}
              name="municipality"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input placeholder="Municipality" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={<span style={boldLabelStyle}>Street/Tole</span>}
              name="street"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input placeholder="Street/Tole" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={<span style={boldLabelStyle}>Phone No.</span>}
              name="phone"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input placeholder="Phone No." />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={<span style={boldLabelStyle}>Email ID</span>}
              name="email"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input placeholder="Email ID" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={<span style={boldLabelStyle}>Fax No.</span>}
              name="fax"
              rules={[{ required: false }]}
            >
              <Input placeholder="Fax No." />
            </Form.Item>
          </Col>
        </Row>

        {/* Electronic Order Details Section */}
        <Divider orientation="left" orientationMargin="0" style={{ margin: "40px 0 34px 0" }}>
          Electronic Order Details
        </Divider>
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item
              label={<span style={boldLabelStyle}>E. Order</span>}
              name="eOrder"
              rules={[{ required: false }]}
            >
              <Input placeholder="E. Order" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={<span style={boldLabelStyle}>E. Order Key</span>}
              name="eOrderKey"
              rules={[{ required: false }]}
            >
              <Input placeholder="E. Order Key" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={<span style={boldLabelStyle}>E. Order Path</span>}
              name="eOrderPath"
              rules={[{ required: false }]}
            >
              <Input placeholder="E. Order Path" />
            </Form.Item>
          </Col>
        </Row>

        {/* Order Details Section */}
        <Divider orientation="left" orientationMargin="0" style={{ margin: "40px 0 34px 0" }}>
          Order Details
        </Divider>
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item
              label={<span style={boldLabelStyle}>Account No.</span>}
              name="accountNo"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input placeholder="Account No." />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={<span style={boldLabelStyle}>Eyetalk Name</span>}
              name="eyetalkName"
              rules={[{ required: false }]}
            >
              <Input placeholder="Eyetalk Name" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={<span style={boldLabelStyle}>Default Fitting Type</span>}
              name="defaultFittingType"
              rules={[{ required: false }]}
            >
              <Input placeholder="Default Fitting Type" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={<span style={boldLabelStyle}>Lab File</span>}
              name="labFile"
              rules={[{ required: false }]}
            >
              <Upload>
                <Button icon={<UploadOutlined />}>Upload Lab File</Button>
              </Upload>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={<span style={boldLabelStyle}>Download Lab File</span>}
              name="downloadLabFile"
              rules={[{ required: false }]}
            >
              <Upload>
                <Button icon={<UploadOutlined />}> Download Lab File</Button>
              </Upload>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={<span style={boldLabelStyle}>Import/Update Lenses</span>}
              name="importUpdateLenses"
              rules={[{ required: false }]}
            >
              <Upload>
                <Button icon={<UploadOutlined />}>Upload Lenses File</Button>
              </Upload>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={<span style={boldLabelStyle}>Notes</span>}
              name="notes"
              rules={[{ required: false }]}
            >
              <Input placeholder="Notes" />
            </Form.Item>
          </Col>
        </Row>

        <Row justify={"end"}>
          <div>
            <Button>Cancel</Button>
            <Button
              type="primary"
              htmlType="submit"
              style={{ marginTop: "34px", marginLeft: "12px" }}
            >
              Submit
            </Button>
          </div>
        </Row>
      </Form>
    </Card>
  );
};

export default SupplierForm;
