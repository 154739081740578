import React, { useState } from "react";
import PageTemplate from "components/PageTemplate";
import { useNavigate } from "react-router-dom";
import { Table, Button, Dropdown, Menu, Row, Modal, Form, Input, Card } from "antd";
import { MoreOutlined, EditOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { useFrameGroupService } from "services/FrameGroupService";
import useConfirmDelete from "hooks/useConfirmDelete";

const FrameGroupList = () => {
  const navigate = useNavigate();
  const { useGetAll, useDelete } = useFrameGroupService();
  const { mutateAsync: deleteFrameGroup, isPending } = useDelete();
  const { confirmDelete } = useConfirmDelete(deleteFrameGroup, isPending);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();

  const data = [
    {
      key: "1",
      frameGroupCode: "FG001",
      frameGroupName: "Metal Frames",
      status: "Active",
    },
    {
      key: "2",
      frameGroupCode: "FG002",
      frameGroupName: "Plastic Frames",
      status: "Active",
    },
  ];

  const handleMenuClick = (key: string, record: any) => {
    switch (key) {
      case "view":
        navigate(`/master-setup/frame-group/details/${record?.key}`);
        break;
      case "update":
        navigate(`/master-setup/frame-group/update/${record?.key}`);
        break;
      case "delete":
        record?.key && confirmDelete(record?.key);
        break;
      default:
        break;
    }
  };

  const actionMenu = (record: any) => (
    <Menu>
      <Menu.Item
        key="update"
        icon={<EditOutlined />}
        onClick={() => handleMenuClick("update", record)}
      >
        Update
      </Menu.Item>
      <Menu.Item
        key="delete"
        icon={<DeleteOutlined />}
        onClick={() => handleMenuClick("delete", record)}
        danger
      >
        Delete
      </Menu.Item>
    </Menu>
  );

  const columns = [
    {
      title: "SN",
      dataIndex: "key",
      render: (text: string) => <span>{text}</span>,
    },
    {
      title: "Frame Group Code",
      dataIndex: "frameGroupCode",
    },
    {
      title: "Frame Group Name",
      dataIndex: "frameGroupName",
    },
    {
      title: "Status",
      dataIndex: "status",
    },
    {
      title: "Action",
      render: (text: any, record: any) => (
        <Dropdown overlay={actionMenu(record)} trigger={["click"]}>
          <Button icon={<MoreOutlined />} />
        </Dropdown>
      ),
    },
  ];

  const handleAddFrameGroup = () => {
    form.resetFields(); // Reset form fields
    setIsModalVisible(true); // Show modal
  };

  const handleModalOk = () => {
    form.validateFields().then((values) => {
      // Handle form submission (create frame group logic here)
      console.log("Received values from form: ", values);
      setIsModalVisible(false); // Close modal
    });
  };

  const handleModalCancel = () => {
    setIsModalVisible(false); // Close modal
  };

  return (
    <PageTemplate>
      <Card
        style={{
          boxShadow: "0px 8px 40px -10px #00000014",
          borderRadius: "4px",
        }}
      >
        <Row justify="end">
          <Button type="primary" icon={<PlusOutlined />} onClick={handleAddFrameGroup}>
            Add Frame Group
          </Button>
        </Row>

        <div style={{ marginTop: "18px" }}>
          <Table columns={columns} dataSource={data} />
        </div>
      </Card>

      <Modal
        title="Add Frame Group"
        visible={isModalVisible}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="frameGroupCode"
            label="Frame Group Code"
            rules={[{ required: true, message: "Please enter frame group code!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="frameGroupName"
            label="Frame Group Name"
            rules={[{ required: true, message: "Please enter frame group name!" }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </PageTemplate>
  );
};

export default FrameGroupList;
