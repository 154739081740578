import React, { useState } from "react";
import { Row, Col, Button, Avatar, Typography, Card, Tabs, Descriptions } from "antd";
import { CalendarOutlined } from "@ant-design/icons";

const { TabPane } = Tabs;
const { Text } = Typography;

const dummyData = {
  patientName: "John Doe",
  gender: "Male",
  dateOfBirth: "20/12/1980",
  age: 43,
  gpDoctor: "Dr. Smith",
  ophthalmologist: "Dr. Jane",
  assistanceRequired: true,
  residentialAddress: "Kathmandu, Nepal",
  homePhone: "01-1234567",
  mobileNo: "09XXXXXXXX",
  workPhone: "01-7654321",
  email: "example@example.com",
  occupation: "Software Engineer",
  hobbies: "Reading, Traveling",
};

const PatientRecordDetailsPage: React.FC = () => {
  const renderAvatar = (size: number, initials: string = "DT") => (
    <Avatar
      size={size}
      style={{
        backgroundColor: "#C8EFF9",
        color: "#0C5C6E",
        fontWeight: "bold",
        marginTop: "18px",
      }}
    >
      {initials}
    </Avatar>
  );

  return (
    <Card
      style={{
        boxShadow: "0px 8px 40px -10px #00000014",
        borderRadius: "4px",
      }}
    >
      <div style={{ display: "flex", alignItems: "center", marginBottom: "24px", gap: "12px" }}>
        {renderAvatar(64)}
        <div>
          <Typography.Title level={3} style={{ marginBottom: 0 }}>
            Daniel Tu
          </Typography.Title>
          <Text type="secondary" style={{ display: "flex", alignItems: "center" }}>
            <CalendarOutlined style={{ marginRight: 4 }} />
            Sep 2, 2024
          </Text>
        </div>
      </div>

      <Card
        style={{
          border: "2px dotted #9CA3AF",
          backgroundColor: "#F3F4F6",

          borderStyle: "dotted",
          borderWidth: "2px",
          borderColor: "#9CA3AF",
        }}
      >
        <Descriptions
          layout="horizontal"
          column={3}
          labelStyle={{ fontWeight: "bold", color: "black" }}
        >
          <Descriptions.Item label="Patient Name">{dummyData.patientName}</Descriptions.Item>
          <Descriptions.Item label="Gender">{dummyData.gender}</Descriptions.Item>
          <Descriptions.Item label="Date of Birth">{dummyData.dateOfBirth}</Descriptions.Item>
          <Descriptions.Item label="Age">{dummyData.age}</Descriptions.Item>
          <Descriptions.Item label="GP Doctor">{dummyData.gpDoctor}</Descriptions.Item>
          <Descriptions.Item label="Ophthalmologist">{dummyData.ophthalmologist}</Descriptions.Item>
          <Descriptions.Item label="Assistance Required">
            {dummyData.assistanceRequired ? "Yes" : "No"}
          </Descriptions.Item>
          <Descriptions.Item label="Residential Address">
            {dummyData.residentialAddress}
          </Descriptions.Item>
          <Descriptions.Item label="Home Phone No.">{dummyData.homePhone}</Descriptions.Item>
          <Descriptions.Item label="Mobile No.">{dummyData.mobileNo}</Descriptions.Item>
          <Descriptions.Item label="Work Phone No.">{dummyData.workPhone}</Descriptions.Item>
          <Descriptions.Item label="Email">{dummyData.email}</Descriptions.Item>
          <Descriptions.Item label="Occupation">{dummyData.occupation}</Descriptions.Item>
          <Descriptions.Item label="Hobbies">{dummyData.hobbies}</Descriptions.Item>
        </Descriptions>
      </Card>
    </Card>
  );
};

export default PatientRecordDetailsPage;
