import { ROUTES } from "constants/routeConstants";

const getAllBaseRoutes = (routesObj: Record<string, any>): string[] => {
  let baseRoutes: string[] = [];

  Object.values(routesObj).forEach((route) => {
    if (typeof route === "string") {
      baseRoutes.push(route);
    } else if (typeof route === "function") {
      baseRoutes.push(route(":id"));
    } else if (typeof route === "object") {
      if (route.BASE) {
        baseRoutes.push(route.BASE);
      }
      baseRoutes = [...baseRoutes, ...getAllBaseRoutes(route)];
    }
  });

  return baseRoutes;
};

export const getParentRoute = (pathname: string): string | undefined => {
  const baseRoutes = getAllBaseRoutes(ROUTES);

  const parentRoute = baseRoutes
    .filter((route) => pathname.startsWith(route))
    .sort((a, b) => b.length - a.length)[0];

  return parentRoute || ROUTES.NOTFOUND;
};
