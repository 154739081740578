import { useApiService } from "hooks/useApiService";
import { QUERY_KEYS } from "constants/queryKeys";
import { SupplierInterface, SupplierResponseInterface } from "./types";
import { supplierApi } from "./supplierServiceApi";

export const useSupplierService = () => {
  return useApiService<SupplierResponseInterface, SupplierInterface>(
    QUERY_KEYS.SUPPLIER,
    supplierApi
  );
};
