import { useApiService } from "hooks/useApiService";
import { QUERY_KEYS } from "constants/queryKeys";
import { PaymentTypeInterface, PaymentTypeResponeInterface } from "./types";
import { paymentTypeApi } from "./paymentTypeServiceApi";

export const usePaymentTypeService = () => {
  return useApiService<PaymentTypeResponeInterface, PaymentTypeInterface>(
    QUERY_KEYS.PAYMENT_TYPE,
    paymentTypeApi
  );
};
