import { Modal } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { UseMutateFunction } from "@tanstack/react-query";
import { AxiosError } from "axios";

const { confirm } = Modal;

type DeleteMutationVariables = string;
type DeleteMutationResponse = void;

const useConfirmDelete = (
  deleteMutation: UseMutateFunction<
    DeleteMutationResponse,
    AxiosError<unknown, any>,
    DeleteMutationVariables
  >,
  isPending: boolean
) => {
  const confirmDelete = async (id: string) => {
    confirm({
      title: "Do you want to delete this item?",
      icon: <ExclamationCircleFilled />,
      content:
        "Once you delete this item, it will be removed permanently. Are you sure you want to proceed?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      okButtonProps: { loading: isPending },
      async onOk() {
        try {
          await deleteMutation(id);
        } catch (error) {}
      },
      onCancel() {},
    });
  };

  return {
    confirmDelete,
  };
};

export default useConfirmDelete;
