import { coreClient } from "utils/httpClient";
import { API_ENDPOINTS } from "constants/apiEndpoints";
import { FrameInterface, FrameResponseInterface } from "./types";

const apiClient = coreClient;

export const frameApi = {
  getAll: async (): Promise<FrameResponseInterface> => {
    const response = await apiClient.get<FrameResponseInterface>(API_ENDPOINTS.FRAME.GET_ALL);
    return response.data;
  },

  getById: async (id: string): Promise<FrameInterface> => {
    const response = await apiClient.get<{
      success: boolean;
      data: FrameInterface;
      message?: string;
    }>(API_ENDPOINTS.FRAME.READ(id));
    return response.data.data;
  },

  create: async (frameData: FrameInterface): Promise<FrameInterface> => {
    const response = await apiClient.post<FrameInterface>(API_ENDPOINTS.FRAME.CREATE, frameData);
    return response.data;
  },

  update: async (id: string, frameData: FrameInterface): Promise<FrameInterface> => {
    const response = await apiClient.put<FrameInterface>(API_ENDPOINTS.FRAME.UPDATE(id), frameData);
    return response.data;
  },

  delete: async (id: string) => {
    await apiClient.delete<void>(API_ENDPOINTS.FRAME.DELETE(id));
  },
};
