import { coreClient } from "utils/httpClient";
import { API_ENDPOINTS } from "constants/apiEndpoints";
import { UserInterface, UserResponseInterface } from "./types"; // Adjust the import based on your file structure

const apiClient = coreClient;

export const userServiceApi = {
  getAll: async (): Promise<UserResponseInterface> => {
    const response = await apiClient.get<UserResponseInterface>(API_ENDPOINTS.USER.GET_ALL);
    return response.data;
  },

  getById: async (id: string): Promise<UserInterface> => {
    const response = await apiClient.get<{
      success: boolean;
      data: UserInterface;
      message?: string;
    }>(API_ENDPOINTS.USER.READ(id));
    return response.data.data;
  },

  create: async (userData: UserInterface): Promise<UserInterface> => {
    const response = await apiClient.post<UserInterface>(API_ENDPOINTS.USER.CREATE, userData);
    return response.data;
  },

  update: async (id: string, userData: UserInterface): Promise<UserInterface> => {
    const response = await apiClient.put<UserInterface>(API_ENDPOINTS.USER.UPDATE(id), userData);
    return response.data;
  },

  delete: async (id: string) => {
    await apiClient.delete<void>(API_ENDPOINTS.USER.DELETE(id));
  },
};
