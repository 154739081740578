import React from "react";
import withFormHandler from "hoc/withFormHandler";
import DynamicFormBuilder from "components/DynamicFormBuilder";
import { useParams } from "react-router-dom";
import SubscriptionFormConfig from "./SubscriptionFormConfig";
import { useSubscriptionService } from "services/SubscriptionService";

interface SubscriptionConfigurationFormProps {
  id?: string;
}

const SubscriptionConfigurationForm = ({ id }: SubscriptionConfigurationFormProps) => {
  const { useCreate, useUpdate, useGetById } = useSubscriptionService();
  const FormHandler = withFormHandler(DynamicFormBuilder, {
    useCreate: useCreate,
    useUpdate: useUpdate,
    useFetchById: (id: string) => useGetById(id),
    onSuccess: () => {},
    onError: (error) => {},
    fieldsConfig: SubscriptionFormConfig,
    wrapInCard: true,
    redirectUrl: "subscription",
    id: id ? id : "",
  });
  return <FormHandler />;
};

const SubscriptionForm = () => {
  const { id } = useParams<{ id?: string }>();
  return (
    <React.Fragment>
      <SubscriptionConfigurationForm id={id} />
    </React.Fragment>
  );
};

export default SubscriptionForm;
