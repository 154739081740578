import { Event } from "types/event.type";

const today = new Date();
const startOfWeek = new Date(today.setDate(today.getDate() - today.getDay() + 1)); // Set to Monday of the current week

export const events: Event[] = [
  // Monday
  {
    id: "1",
    startTime: new Date(
      startOfWeek.getFullYear(),
      startOfWeek.getMonth(),
      startOfWeek.getDate(),
      9,
      0,
      0
    ),
    endTime: new Date(
      startOfWeek.getFullYear(),
      startOfWeek.getMonth(),
      startOfWeek.getDate(),
      10,
      0,
      0
    ),
    title: "Initial Consultation",
    allDay: false,
    status: "booked",
    patientName: "John Doe",
  },
  {
    id: "2",
    startTime: new Date(
      startOfWeek.getFullYear(),
      startOfWeek.getMonth(),
      startOfWeek.getDate(),
      10,
      30,
      0
    ),
    endTime: new Date(
      startOfWeek.getFullYear(),
      startOfWeek.getMonth(),
      startOfWeek.getDate(),
      11,
      30,
      0
    ),
    title: "Follow-up Check",
    allDay: false,
    status: "confirmed",
    patientName: "Jane Smith",
  },
  {
    id: "3",
    startTime: new Date(
      startOfWeek.getFullYear(),
      startOfWeek.getMonth(),
      startOfWeek.getDate(),
      12,
      0,
      0
    ),
    endTime: new Date(
      startOfWeek.getFullYear(),
      startOfWeek.getMonth(),
      startOfWeek.getDate(),
      13,
      0,
      0
    ),
    title: "Routine Check-up",
    allDay: false,
    status: "arrived",
    patientName: "Alice Johnson",
  },
  {
    id: "4",
    startTime: new Date(
      startOfWeek.getFullYear(),
      startOfWeek.getMonth(),
      startOfWeek.getDate(),
      14,
      0,
      0
    ),
    endTime: new Date(
      startOfWeek.getFullYear(),
      startOfWeek.getMonth(),
      startOfWeek.getDate(),
      15,
      0,
      0
    ),
    title: "Specialist Consultation",
    allDay: false,
    status: "pre-test",
    patientName: "Bob Brown",
  },
  {
    id: "5",
    startTime: new Date(
      startOfWeek.getFullYear(),
      startOfWeek.getMonth(),
      startOfWeek.getDate(),
      15,
      30,
      0
    ),
    endTime: new Date(
      startOfWeek.getFullYear(),
      startOfWeek.getMonth(),
      startOfWeek.getDate(),
      16,
      30,
      0
    ),
    title: "Eye Examination",
    allDay: false,
    status: "consult",
    patientName: "Charlie Green",
  },
  {
    id: "6",
    startTime: new Date(
      startOfWeek.getFullYear(),
      startOfWeek.getMonth(),
      startOfWeek.getDate(),
      17,
      0,
      0
    ),
    endTime: new Date(
      startOfWeek.getFullYear(),
      startOfWeek.getMonth(),
      startOfWeek.getDate(),
      18,
      0,
      0
    ),
    title: "Medication Review",
    allDay: false,
    status: "dispensing",
    patientName: "Diana Prince",
  },
  {
    id: "7",
    startTime: new Date(
      startOfWeek.getFullYear(),
      startOfWeek.getMonth(),
      startOfWeek.getDate(),
      18,
      30,
      0
    ),
    endTime: new Date(
      startOfWeek.getFullYear(),
      startOfWeek.getMonth(),
      startOfWeek.getDate(),
      19,
      30,
      0
    ),
    title: "Post-Consultation Review",
    allDay: false,
    status: "consult complete",
    patientName: "Eve Adams",
  },
  {
    id: "8",
    startTime: new Date(
      startOfWeek.getFullYear(),
      startOfWeek.getMonth(),
      startOfWeek.getDate(),
      20,
      0,
      0
    ),
    endTime: new Date(
      startOfWeek.getFullYear(),
      startOfWeek.getMonth(),
      startOfWeek.getDate(),
      21,
      0,
      0
    ),
    title: "Final Check",
    allDay: false,
    status: "departed",
    patientName: "Frank Castle",
  },

  // Tuesday
  {
    id: "9",
    startTime: new Date(
      startOfWeek.getFullYear(),
      startOfWeek.getMonth(),
      startOfWeek.getDate() + 1,
      10,
      0,
      0
    ),
    endTime: new Date(
      startOfWeek.getFullYear(),
      startOfWeek.getMonth(),
      startOfWeek.getDate() + 1,
      11,
      0,
      0
    ),
    title: "Initial Consultation",
    allDay: false,
    status: "booked",
    patientName: "Gina Hill",
  },

  // Wednesday
  {
    id: "10",
    startTime: new Date(
      startOfWeek.getFullYear(),
      startOfWeek.getMonth(),
      startOfWeek.getDate() + 2,
      11,
      0,
      0
    ),
    endTime: new Date(
      startOfWeek.getFullYear(),
      startOfWeek.getMonth(),
      startOfWeek.getDate() + 2,
      12,
      0,
      0
    ),
    title: "Follow-up Check",
    allDay: false,
    status: "confirmed",
    patientName: "Henry Ford",
  },

  // Thursday
  {
    id: "11",
    startTime: new Date(
      startOfWeek.getFullYear(),
      startOfWeek.getMonth(),
      startOfWeek.getDate() + 3,
      9,
      0,
      0
    ),
    endTime: new Date(
      startOfWeek.getFullYear(),
      startOfWeek.getMonth(),
      startOfWeek.getDate() + 3,
      10,
      0,
      0
    ),
    title: "Routine Check-up",
    allDay: false,
    status: "arrived",
    patientName: "Ivy Lane",
  },

  // Friday
  {
    id: "12",
    startTime: new Date(
      startOfWeek.getFullYear(),
      startOfWeek.getMonth(),
      startOfWeek.getDate() + 4,
      14,
      0,
      0
    ),
    endTime: new Date(
      startOfWeek.getFullYear(),
      startOfWeek.getMonth(),
      startOfWeek.getDate() + 4,
      15,
      0,
      0
    ),
    title: "Specialist Consultation",
    allDay: false,
    status: "pre-test",
    patientName: "Jack Daniels",
  },

  // Saturday
  {
    id: "13",
    startTime: new Date(
      startOfWeek.getFullYear(),
      startOfWeek.getMonth(),
      startOfWeek.getDate() + 5,
      13,
      0,
      0
    ),
    endTime: new Date(
      startOfWeek.getFullYear(),
      startOfWeek.getMonth(),
      startOfWeek.getDate() + 5,
      14,
      0,
      0
    ),
    title: "Eye Examination",
    allDay: false,
    status: "consult",
    patientName: "Kelly Marie",
  },

  // Sunday
  {
    id: "14",
    startTime: new Date(
      startOfWeek.getFullYear(),
      startOfWeek.getMonth(),
      startOfWeek.getDate() + 6,
      10,
      0,
      0
    ),
    endTime: new Date(
      startOfWeek.getFullYear(),
      startOfWeek.getMonth(),
      startOfWeek.getDate() + 6,
      11,
      0,
      0
    ),
    title: "Medication Review",
    allDay: false,
    status: "dispensing",
    patientName: "Liam Neeson",
  },
];
