import React from "react";
import { Card, Row, Col, Input, Button, Table } from "antd";

const OutstandingBulkBillingAccounts: React.FC = () => {
  const columns = [
    {
      title: "SN",
      dataIndex: "sn",
      key: "sn",
    },
    {
      title: "Bill No.",
      dataIndex: "billNo",
      key: "billNo",
    },
    {
      title: "Bill Type",
      dataIndex: "billType",
      key: "billType",
    },
    {
      title: "Bill Date",
      dataIndex: "billDate",
      key: "billDate",
    },
    {
      title: "Bill Amount $",
      dataIndex: "billAmount",
      key: "billAmount",
    },
    {
      title: "Outstanding Payment Amount $",
      dataIndex: "outstandingAmount",
      key: "outstandingAmount",
    },
  ];

  const data = [
    {
      key: 1,
      sn: 1,
      billNo: "INV-001",
      billType: "Type A",
      billDate: "2024-10-01",
      billAmount: 5000,
      outstandingAmount: 1500,
    },
    {
      key: 2,
      sn: 2,
      billNo: "INV-002",
      billType: "Type B",
      billDate: "2024-10-10",
      billAmount: 3000,
      outstandingAmount: 1000,
    },
    {
      key: 3,
      sn: 3,
      billNo: "INV-003",
      billType: "Type C",
      billDate: "2024-10-15",
      billAmount: 4500,
      outstandingAmount: 2000,
    },
  ];

  return (
    <Card
      style={{
        boxShadow: "0px 8px 40px -10px #00000014",
        borderRadius: "4px",
      }}
    >
      <Row justify="space-between" align="middle" style={{ marginBottom: "20px" }}>
        <Col span={8}>
          <Input.Search placeholder="Search..." enterButton />
        </Col>
        <Col>
          <Button type="primary">Print</Button>
        </Col>
      </Row>

      <Table columns={columns} dataSource={data} pagination={false} />
    </Card>
  );
};

export default OutstandingBulkBillingAccounts;
