import { useApiService } from "hooks/useApiService";
import { LensCodeInterface, LensCodeResponseInterface } from "./type"; // Update the import path as necessary
import { QUERY_KEYS } from "constants/queryKeys";
import { lensCodeServiceApi } from "./lensCodeServiceApi"; // Update the import path as necessary

export const useLensCodeService = () => {
  return useApiService<LensCodeResponseInterface, LensCodeInterface>(
    QUERY_KEYS.LENS_CODE, // Make sure this key is defined in your QUERY_KEYS
    lensCodeServiceApi
  );
};
