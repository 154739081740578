import React from "react";
import { Card, Divider, Row, Col, Form, Input, Button, Radio, Table, DatePicker } from "antd";
import InputNumber from "antd/lib/input-number";

const { TextArea } = Input;

const boldLabelStyle = { fontWeight: "bold", color: "#141414" };

const FrameForm: React.FC = () => {
  const costTableData = [
    { key: "1", priceFor: "Current List Price", excludingTax: "", includingTax: "" },
    { key: "2", priceFor: "Current Cost Price", excludingTax: "", includingTax: "" },
    { key: "3", priceFor: "Previous Cost Price", excludingTax: "", includingTax: "" },
    { key: "4", priceFor: "Average Cost Price", excludingTax: "", includingTax: "" },
    { key: "5", priceFor: "Retail Price", excludingTax: "", includingTax: "" },
  ];

  const costTableColumns = [
    {
      title: "Prices For",
      dataIndex: "priceFor",
      key: "priceFor",
      width: "33%",
      render: (text: string) => <span>{text}</span>,
    },
    {
      title: "Excluding Tax",
      dataIndex: "excludingTax",
      key: "excludingTax",
      width: "33%",
      render: (_: any, record: any) => (
        <Form.Item
          name={["costDetails", record.key, "excludingTax"]}
          rules={[{ required: true, message: "Required" }]}
        >
          <Input placeholder="Excluding Tax" />
        </Form.Item>
      ),
    },
    {
      title: "Including Tax",
      dataIndex: "includingTax",
      key: "includingTax",
      width: "33%",
      render: (_: any, record: any) => (
        <Form.Item
          name={["costDetails", record.key, "includingTax"]}
          rules={[{ required: true, message: "Required" }]}
        >
          <Input placeholder="Including Tax" />
        </Form.Item>
      ),
    },
  ];

  const handleFinish = (values: any) => {
    console.log("Form values: ", values);
  };

  return (
    <Card
      style={{
        boxShadow: "0px 8px 40px -10px #00000014",
        borderRadius: "4px",
      }}
    >
      <Form layout="vertical" onFinish={handleFinish}>
        {/* Frame Details */}
        <Divider orientation="left" orientationMargin="0" style={{ marginBottom: "34px" }}>
          Frame Details
        </Divider>
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item
              label={<span style={boldLabelStyle}>Frame Code</span>}
              name="frameCode"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input placeholder="Frame Code" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={<span style={boldLabelStyle}>Brand</span>}
              name="brand"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input placeholder="Brand" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={<span style={boldLabelStyle}>Display Stock</span>}
              name="displayStock"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input placeholder="Display Stock" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={<span style={boldLabelStyle}>Model</span>}
              name="model"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input placeholder="Model" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={<span style={boldLabelStyle}>Color Code</span>}
              name="colorCode"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input placeholder="Color Code" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={<span style={boldLabelStyle}>Frame Status</span>}
              name="frameStatus"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input placeholder="Frame Status" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={<span style={boldLabelStyle}>Vision Bar Code</span>}
              name="visionBarCode"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input placeholder="Vision Bar Code" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={<span style={boldLabelStyle}>Location</span>}
              name="location"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input placeholder="Location" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={<span style={boldLabelStyle}>Frame Size</span>}
              name="frameSize"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input placeholder="Frame Size" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={<span style={boldLabelStyle}>Temple</span>}
              name="temple"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input placeholder="Temple" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={<span style={boldLabelStyle}>Depthe</span>}
              name="degree"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input placeholder="Degree" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={<span style={boldLabelStyle}>Diagonal</span>}
              name="diagonal"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input placeholder="Diagonal" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={<span style={boldLabelStyle}>Frame Type</span>}
              name="frameType"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input placeholder="Frame Type" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={<span style={boldLabelStyle}>BC</span>}
              name="bc"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input placeholder="BC" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={<span style={boldLabelStyle}>Frame Group</span>}
              name="fittingGroup"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input placeholder="Fitting Group" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={<span style={boldLabelStyle}>Is Prescriptable?</span>}
              name="isPrescriptable"
              rules={[{ required: true, message: "Required" }]}
            >
              <Radio.Group>
                <Radio value="yes">Yes</Radio>
                <Radio value="no">No</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={<span style={boldLabelStyle}>Is Imported?</span>}
              name="isImported"
              rules={[{ required: true, message: "Required" }]}
            >
              <Radio.Group>
                <Radio value="yes">Yes</Radio>
                <Radio value="no">No</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={<span style={boldLabelStyle}>Color Description</span>}
              name="colorDescription"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input.TextArea rows={4} placeholder="Color Description" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={<span style={boldLabelStyle}>Frame Description</span>}
              name="frameDescription"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input.TextArea rows={4} placeholder="Frame Description" />
            </Form.Item>
          </Col>
        </Row>

        {/* Supplier Details */}
        <Divider orientation="left" orientationMargin="0" style={{ margin: "40px 0 34px 0" }}>
          Supplier Details
        </Divider>
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item
              label={<span style={boldLabelStyle}>Supplier</span>}
              name="supplier"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input placeholder="Supplier" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={<span style={boldLabelStyle}>Supplier Bar Code</span>}
              name="supplierBarCode"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input placeholder="Supplier Bar Code" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={<span style={boldLabelStyle}>Supplier Stock Code</span>}
              name="supplierStockCode"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input placeholder="Supplier Stock Code" />
            </Form.Item>
          </Col>
        </Row>

        {/* Re-order Details */}
        <Divider orientation="left" orientationMargin="0" style={{ margin: "40px 0 34px 0" }}>
          Re-order Details
        </Divider>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item
              label={<span style={boldLabelStyle}>Order from lab?</span>}
              name="reOrderRequired"
              rules={[{ required: true, message: "Required" }]}
            >
              <Radio.Group>
                <Radio value="yes">Yes</Radio>
                <Radio value="no">No</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Re-Order When Less Than"
              name="reorderWhenLessThan"
              rules={[{ required: true, message: "Please input the threshold quantity!" }]}
            >
              <Input min={1} />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              label="Re-Order Qty"
              name="reorderQty"
              rules={[{ required: true, message: "Please input the re-order quantity!" }]}
            >
              <Input min={1} />
            </Form.Item>
          </Col>

          <Col span={16}>
            <Form.Item
              label="Re-Order Note"
              name="reorderNote"
              rules={[{ required: true, message: "Please input a note!" }]}
            >
              <Input.TextArea rows={4} />
            </Form.Item>
          </Col>
        </Row>

        {/* Cost Details */}
        <Divider orientation="left" orientationMargin="0" style={{ margin: "40px 0 34px 0" }}>
          Cost Details
        </Divider>

        <Row gutter={24}>
          <Col span={8}>
            <Form.Item
              label={<span style={boldLabelStyle}>Cost Tax</span>}
              name="costPrice"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input placeholder="Cost Tax" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={<span style={boldLabelStyle}>Discount on Current List Price(%)</span>}
              name="taxRate"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input placeholder="Tax Rate" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={<span style={boldLabelStyle}>Depreciation Rate(%)</span>}
              name="discount"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input placeholder="Discount" />
            </Form.Item>
          </Col>
        </Row>
        <Table
          pagination={false}
          bordered
          columns={costTableColumns}
          dataSource={costTableData}
          style={{ marginBottom: "40px" }}
        />

        <Divider orientation="left" orientationMargin="0" style={{ margin: "40px 0 34px 0" }}>
          Sales & Purchase Details
        </Divider>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              label="Return By"
              name="returnBy"
              rules={[{ required: true, message: "Please select the return date!" }]}
            >
              <DatePicker style={{ width: "100%" }} />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              label="Last Sale"
              name="lastSale"
              rules={[{ required: true, message: "Please select the last sale date!" }]}
            >
              <DatePicker style={{ width: "100%" }} />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              label="First Purchase"
              name="firstPurchase"
              rules={[{ required: true, message: "Please select the first purchase date!" }]}
            >
              <DatePicker style={{ width: "100%" }} />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              label="Last Purchase"
              name="lastPurchase"
              rules={[{ required: true, message: "Please select the last purchase date!" }]}
            >
              <DatePicker style={{ width: "100%" }} />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              label="Last Invoice No."
              name="lastInvoiceNo"
              rules={[{ required: true, message: "Please input the last invoice number!" }]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              label="Available From"
              name="availableFrom"
              rules={[{ required: true, message: "Please select the available from date!" }]}
            >
              <DatePicker style={{ width: "100%" }} />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              label="Available Till"
              name="availableTill"
              rules={[{ required: true, message: "Please select the available till date!" }]}
            >
              <DatePicker style={{ width: "100%" }} />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="end">
          <Button type="primary" htmlType="submit">
            Save Frame
          </Button>
        </Row>
      </Form>
    </Card>
  );
};

export default FrameForm;
