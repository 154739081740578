import { SubscriptionPackageInterface, TaxTypeEnum } from "services/SubscriptionService";

export const subscriptionPackages: SubscriptionPackageInterface[] = [
  {
    _id: "64e92c91f2d5a0a1b2c3d4e5", // Example string ID
    purchaseDisable: false,
    name: "Basic Plan",
    description: "A basic subscription plan with essential features.",
    allowUserType: ["individual"],
    allowBooking: true,
    trial: {
      enable: true,
      days: 7,
      text: "7-day free trial",
    },
    annualCost: {
      enable: true,
      cost: 99.99,
      taxRate: 7.5,
      discountPercent: 10,
      taxType: TaxTypeEnum.EXCLUSIVE, // Assuming TaxTypeEnum includes "VAT"
      flatPropertyCost: 5.0,
    },
    monthlyCost: {
      enable: true,
      cost: 9.99,
      taxRate: 7.5,
      discountPercent: 5,
      taxType: TaxTypeEnum.EXCLUSIVE, // Assuming TaxTypeEnum includes "VAT"
      flatPropertyCost: 1.0,
    },
    storage: {
      base: 50, // Assuming storage is in GB
    },
    plans: [
      {
        planName: "Basic Feature Set",
        allowed: true,
      },
    ],
    subtitle: "Affordable and essential",
    isDeleted: false,
    isSeed: false,
    isFreeForever: false,
    canDelete: true,
  },
  {
    _id: "64e92c91f2d5a0a1b2c3d4e6", // Example string ID
    purchaseDisable: false,
    name: "Premium Plan",
    description: "A premium subscription plan with advanced features and priority support.",
    allowUserType: ["individual", "business"],
    allowBooking: true,
    trial: {
      enable: true,
      days: 14,
      text: "14-day free trial",
    },
    annualCost: {
      enable: true,
      cost: 199.99,
      taxRate: 7.5,
      discountPercent: 15,
      taxType: TaxTypeEnum.EXCLUSIVE, // Assuming TaxTypeEnum includes "VAT"
      flatPropertyCost: 10.0,
    },
    monthlyCost: {
      enable: true,
      cost: 19.99,
      taxRate: 7.5,
      discountPercent: 10,
      taxType: TaxTypeEnum.EXCLUSIVE, // Assuming TaxTypeEnum includes "VAT"
      flatPropertyCost: 2.0,
    },
    storage: {
      base: 100, // Assuming storage is in GB
    },
    plans: [
      {
        planName: "Premium Feature Set",
        allowed: true,
      },
    ],
    subtitle: "Advanced features and support",
    isDeleted: false,
    isSeed: false,
    isFreeForever: false,
    canDelete: true,
  },
  {
    _id: "64e92c91f2d5a0a1b2c3d4e7", // Example string ID
    purchaseDisable: false,
    name: "Enterprise Plan",
    description: "An enterprise-level subscription with all features and dedicated support.",
    allowUserType: ["business"],
    allowBooking: true,
    trial: {
      enable: false,
      days: 0,
      text: "",
    },
    annualCost: {
      enable: true,
      cost: 499.99,
      taxRate: 7.5,
      discountPercent: 20,
      taxType: TaxTypeEnum.EXCLUSIVE, // Assuming TaxTypeEnum includes "VAT"
      flatPropertyCost: 25.0,
    },
    monthlyCost: {
      enable: true,
      cost: 49.99,
      taxRate: 7.5,
      discountPercent: 15,
      taxType: TaxTypeEnum.EXCLUSIVE, // Assuming TaxTypeEnum includes "VAT"
      flatPropertyCost: 5.0,
    },
    storage: {
      base: 500, // Assuming storage is in GB
    },
    plans: [
      {
        planName: "Enterprise Feature Set",
        allowed: true,
      },
    ],
    subtitle: "All-inclusive enterprise solution",
    isDeleted: false,
    isSeed: false,
    isFreeForever: false,
    canDelete: true,
  },
];
