/** @jsxImportSource @emotion/react */
import React from "react";
import { Menu, Dropdown, Avatar } from "antd";
import { LogoutOutlined, UserOutlined, SettingOutlined, KeyOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "constants/routeConstants";
import styled from "@emotion/styled";

const StyledAvatar = styled(Avatar)`
  cursor: pointer;
  background: linear-gradient(135deg, #0072ff, #00c6ff); /* Elegant gradient */
  color: #fff;
  border: 2px solid #fff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: background 0.3s, box-shadow 0.3s;

  &:hover {
    background: linear-gradient(135deg, #005bb5, #0090b3); /* Darker gradient on hover */
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
  }
`;

const StyledMenu = styled(Menu)`
  border-radius: 8px; /* Softer corners */
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
  background: #ffffff; /* Clean white background */
  font-family: "Roboto", sans-serif; /* Modern font */
  font-size: 14px;
  font-weight: 500;
  border: 1px solid #e1e1e1;

  .ant-dropdown-menu-item {
    padding: 10px 16px; /* Comfortable padding */
    transition: background-color 0.2s ease;

    &:hover {
      background-color: #f0f4ff; /* Light hover effect */
    }
  }
`;

const MenuItemIcon = styled.div`
  color: #555; /* Updated icon color */
  margin-right: 12px; /* Space between icon and text */
  font-size: 16px; /* Adjusted icon size for consistency */
  display: inline-flex;
  align-items: center;
`;

const MenuItemText = styled.span`
  color: #333; /* Darker text color for readability */
  vertical-align: middle;
`;

const StyledDivider = styled(Menu.Divider)`
  margin: 8px 0;
  border-top: 1px solid #e1e1e1;
`;

const DangerMenuItem = styled(Menu.Item)`
  color: #ff4d4f; /* Red color for logout button */
  background-color: transparent;

  &:hover {
    background-color: #ffeaea; /* Light red background on hover */
    color: #d30000; /* Darker red for hover text */
  }
`;

const DropdownMenu: React.FC = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    navigate("/signin");
    // authService.logout();
    // window.location.reload();
  };

  const handleChangePassword = () => {
    navigate(ROUTES.AUTH_ROUTES.CHANGE_PASSWORD);
  };

  const menu = (
    <StyledMenu>
      <Menu.Item key="profile">
        <MenuItemIcon>
          <UserOutlined />
        </MenuItemIcon>
        <MenuItemText>Profile</MenuItemText>
      </Menu.Item>
      <Menu.Item key="settings">
        <MenuItemIcon>
          <SettingOutlined />
        </MenuItemIcon>
        <MenuItemText>Settings</MenuItemText>
      </Menu.Item>
      <Menu.Item key="password" onClick={handleChangePassword}>
        <MenuItemIcon>
          <KeyOutlined />
        </MenuItemIcon>
        <MenuItemText>Change Password</MenuItemText>
      </Menu.Item>
      <StyledDivider />
      <DangerMenuItem key="logout" onClick={handleLogout}>
        <MenuItemIcon>
          <LogoutOutlined />
        </MenuItemIcon>
        <MenuItemText>Logout</MenuItemText>
      </DangerMenuItem>
    </StyledMenu>
  );

  return (
    <Dropdown overlay={menu} trigger={["click"]}>
      <StyledAvatar icon={<UserOutlined />} />
    </Dropdown>
  );
};

export default DropdownMenu;
