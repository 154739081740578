import PageTemplate from "components/PageTemplate";
import { ROUTES } from "constants/routeConstants";
import { useNavigate } from "react-router-dom";
import { Table, Button, Dropdown, Menu, Row, Card } from "antd";
import {
  MoreOutlined,
  EyeOutlined,
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import useConfirmDelete from "hooks/useConfirmDelete";
import { useFrameService } from "services/FrameService";

const FrameListPage = () => {
  const navigate = useNavigate();
  const { useGetAll, useDelete } = useFrameService();
  // const { data: frameData, isLoading, refetch } = useGetAll();
  const { mutateAsync: deleteFrame, isPending } = useDelete();
  const { confirmDelete } = useConfirmDelete(deleteFrame, isPending);

  const data = [
    {
      key: "1",
      frameCode: "FRM001",
      model: "Model A",
      brand: "Brand A",
      supplier: "Supplier X",
      color: "Red",
      size: "Medium",
      continue: "Yes",
      stock: 30,
    },
    {
      key: "2",
      frameCode: "FRM002",
      model: "Model B",
      brand: "Brand B",
      supplier: "Supplier Y",
      color: "Blue",
      size: "Large",
      continue: "No",
      stock: 15,
    },
  ];

  const handleMenuClick = (key: string, record: any) => {
    switch (key) {
      case "view":
        navigate(`/inventory/frame/details/${record?.key}`);
        break;
      case "update":
        navigate(`/inventory/frame/update/${record?.key}`);
        break;
      case "delete":
        record?.key && confirmDelete(record?.key);
        break;
      default:
        break;
    }
  };

  const actionMenu = (record: any) => (
    <Menu>
      <Menu.Item key="view" icon={<EyeOutlined />} onClick={() => handleMenuClick("view", record)}>
        View details
      </Menu.Item>
      <Menu.Item
        key="update"
        icon={<EditOutlined />}
        onClick={() => handleMenuClick("update", record)}
      >
        Update frame
      </Menu.Item>
      <Menu.Item
        key="delete"
        icon={<DeleteOutlined />}
        onClick={() => handleMenuClick("delete", record)}
        danger
      >
        Delete frame
      </Menu.Item>
    </Menu>
  );

  const columns = [
    {
      title: "SN",
      dataIndex: "key",
      render: (text: string) => <span>{text}</span>,
    },
    {
      title: "Frame Code",
      dataIndex: "frameCode",
    },
    {
      title: "Model",
      dataIndex: "model",
    },
    {
      title: "Brand",
      dataIndex: "brand",
    },
    {
      title: "Supplier",
      dataIndex: "supplier",
    },
    {
      title: "Color",
      dataIndex: "color",
    },
    {
      title: "Size",
      dataIndex: "size",
    },
    {
      title: "Continue",
      dataIndex: "continue",
    },
    {
      title: "Stock",
      dataIndex: "stock",
    },
    {
      title: "Action",
      render: (text: any, record: any) => (
        <Dropdown overlay={actionMenu(record)} trigger={["click"]}>
          <Button icon={<MoreOutlined />} />
        </Dropdown>
      ),
    },
  ];

  return (
    <PageTemplate>
      <Card
        style={{
          boxShadow: "0px 8px 40px -10px #00000014",
          borderRadius: "4px",
        }}
      >
        <Row justify="end">
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => navigate(ROUTES.INVENTORY.FRAME.CREATE)}
          >
            Add Frame
          </Button>
        </Row>

        <div style={{ marginTop: "18px" }}>
          <Table columns={columns} dataSource={data} />
        </div>
      </Card>
    </PageTemplate>
  );
};

export default FrameListPage;
