import React, { useState } from "react";
import {
  Card,
  Row,
  Col,
  Menu,
  Divider,
  Button,
  Table,
  Form,
  Input,
  Select,
  Radio,
  Checkbox,
  Alert,
} from "antd";
import icons from "icons";

// Define reusable columns for the table

const { Scan } = icons;
const columns = [
  {
    title: "SN",
    dataIndex: "sn",
    key: "sn",
  },
  {
    title: "Frame Code",
    dataIndex: "frameCode",
    key: "frameCode",
  },
  {
    title: "Quantity",
    dataIndex: "quantity",
    key: "quantity",
  },
  {
    title: "Quantity Expected",
    dataIndex: "quantityExpected",
    key: "quantityExpected",
  },
  {
    title: "Quantity Difference",
    dataIndex: "quantityDifference",
    key: "quantityDifference",
  },
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
  },
  {
    title: "Barcode",
    dataIndex: "barcode",
    key: "barcode",
  },
];

// Define reusable data source for all menu items
const varianceDataSource = [
  {
    key: "1",
    sn: 1,
    frameCode: "FRAME001",
    quantity: 5,
    quantityExpected: 10,
    quantityDifference: -5,
    description: "Defective frame",
    barcode: "BC001",
  },
  {
    key: "2",
    sn: 2,
    frameCode: "FRAME002",
    quantity: 15,
    quantityExpected: 15,
    quantityDifference: 0,
    description: "In stock",
    barcode: "BC002",
  },
  {
    key: "3",
    sn: 3,
    frameCode: "FRAME003",
    quantity: 8,
    quantityExpected: 12,
    quantityDifference: -4,
    description: "Missing",
    barcode: "BC003",
  },
];

// FrameStocktakePage component
const FrameStocktakePage: React.FC = () => {
  const [selectedKey, setSelectedKey] = useState("startNewStocktake");

  const handleMenuClick = (e: any) => {
    setSelectedKey(e.key);
  };

  const renderContent = () => {
    switch (selectedKey) {
      case "startNewStocktake":
        return (
          <Card style={{ height: "calc(100vh - 138px)", overflowY: "auto" }}>
            <Divider orientation="left" orientationMargin="0" style={{ marginBottom: "34px" }}>
              Start a New Stocktake for Frames
            </Divider>
            <Form layout="vertical">
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item>
                    <Checkbox>Apply to all frames?</Checkbox>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="Supplier Code">
                    <Select placeholder="Select Supplier Code">
                      <Select.Option value="supplier1">Supplier 1</Select.Option>
                      <Select.Option value="supplier2">Supplier 2</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="Brand">
                    <Input placeholder="Brand" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="Frame Type">
                    <Select placeholder="Select Frame Type">
                      <Select.Option value="type1">Type 1</Select.Option>
                      <Select.Option value="type2">Type 2</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="Frame Group">
                    <Select placeholder="Select Frame Group">
                      <Select.Option value="group1">Group 1</Select.Option>
                      <Select.Option value="group2">Group 2</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="Frame Status">
                    <Select placeholder="Select Frame Status">
                      <Select.Option value="status1">Status 1</Select.Option>
                      <Select.Option value="status2">Status 2</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="Frame Location">
                    <Input placeholder="Location" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16} style={{ marginTop: "20px" }} justify={"end"}>
                <Button type="default">Reset</Button>
                <Button type="primary" style={{ marginLeft: "12px" }}>
                  Submit
                </Button>
              </Row>
            </Form>
            <Alert
              message="Warning"
              description="This will erase the stocktake quantity that you previously scanned."
              type="warning"
              showIcon
              style={{ marginTop: "20px" }}
            />
          </Card>
        );
      // Add other cases as needed
      case "frameStocktakeVarianceReport":
        return (
          <Card
            style={{
              height: "calc(100vh - 138px)",
              overflowY: "auto",
            }}
          >
            <Divider orientation="left" orientationMargin="0" style={{ marginBottom: "34px" }}>
              Frame Due for Return by Patients
            </Divider>
            <Row justify="end" style={{ marginBottom: "20px" }}>
              <Button type="primary">Print</Button>
            </Row>
            <Table
              dataSource={varianceDataSource} // Use the same data source for all menu items
              columns={columns}
            />
          </Card>
        );

      case "scanFrames":
        return (
          <div style={{ textAlign: "center", padding: "20px" }}>
            <Scan />
            <p>Stocktake was started 129 days ago. Do you want to continue scanning frames?</p>
            <div style={{ display: "flex", justifyContent: "center", gap: "10px" }}>
              <Button>Cancel</Button>
              <Button type="primary">Yes, start scanning</Button>
            </div>
          </div>
        );
      default:
        return (
          <Card style={{ height: "calc(100vh - 138px)", overflowY: "auto" }}>
            Select a menu item to see content.
          </Card>
        );
    }
  };

  return (
    <Row gutter={24} style={{ height: "100vh" }}>
      <Col span={8}>
        <Card
          style={{
            boxShadow: "0px 8px 40px -10px #00000014",
            borderRadius: "4px",
            height: "calc(100vh - 138px)",
          }}
        >
          <Menu
            mode="inline"
            selectedKeys={[selectedKey]}
            onClick={handleMenuClick}
            style={{ border: "none" }}
          >
            <Menu.Item
              key="startNewStocktake"
              style={
                selectedKey === "startNewStocktake"
                  ? { backgroundColor: "#18B6DA", color: "#fff" }
                  : {}
              }
            >
              Start a New Stocktake for Frames
            </Menu.Item>
            <Menu.Item
              key="scanFrames"
              style={
                selectedKey === "scanFrames" ? { backgroundColor: "#18B6DA", color: "#fff" } : {}
              }
            >
              Scan Frames
            </Menu.Item>
            <Menu.Item
              key="frameStocktakeProgressReport"
              style={
                selectedKey === "frameStocktakeProgressReport"
                  ? { backgroundColor: "#18B6DA", color: "#fff" }
                  : {}
              }
            >
              Frame Stocktake Progress Report
            </Menu.Item>
            <Menu.Item
              key="frameStocktakeVarianceReport"
              style={
                selectedKey === "frameStocktakeVarianceReport"
                  ? { backgroundColor: "#18B6DA", color: "#fff" }
                  : {}
              }
            >
              Frame Stocktake Variance Report
            </Menu.Item>
            <Menu.Item
              key="proceduresForMemoryScanner"
              style={
                selectedKey === "proceduresForMemoryScanner"
                  ? { backgroundColor: "#18B6DA", color: "#fff" }
                  : {}
              }
            >
              Procedures for Memory Scanner
            </Menu.Item>
            <Menu.Item
              key="downloadStoredDataFromMemoryScanner"
              style={
                selectedKey === "downloadStoredDataFromMemoryScanner"
                  ? { backgroundColor: "#18B6DA", color: "#fff" }
                  : {}
              }
            >
              Download Stored Data from Memory Scanner
            </Menu.Item>
          </Menu>
        </Card>
      </Col>
      <Col span={16}>{renderContent()}</Col>
    </Row>
  );
};

export default FrameStocktakePage;
