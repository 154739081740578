import { authClient } from "utils/httpClient";
import { API_ENDPOINTS } from "constants/apiEndpoints";
import { LensCodeInterface, LensCodeResponseInterface } from "./type";

const apiClient = authClient;

export const lensCodeServiceApi = {
  // Get all lens codes
  getAll: async (): Promise<LensCodeResponseInterface> => {
    try {
      const response = await apiClient.get<LensCodeResponseInterface>(API_ENDPOINTS.LENS_CODE.BASE);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  // Get a lens code by ID
  getById: async (id: string): Promise<LensCodeInterface> => {
    try {
      const response = await apiClient.get<{
        success: boolean;
        data: LensCodeInterface;
        message?: string;
      }>(API_ENDPOINTS.LENS_CODE.READ(id));
      return response.data.data;
    } catch (error) {
      throw error;
    }
  },

  // Create a new lens code
  create: async (lensCodeData: LensCodeInterface): Promise<LensCodeInterface> => {
    try {
      const response = await apiClient.post<LensCodeInterface>(
        API_ENDPOINTS.LENS_CODE.CREATE,
        lensCodeData
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  // Update an existing lens code
  update: async (id: string, lensCodeData: LensCodeInterface): Promise<LensCodeInterface> => {
    try {
      const response = await apiClient.put<LensCodeInterface>(
        API_ENDPOINTS.LENS_CODE.UPDATE(id),
        lensCodeData
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  // Delete a lens code
  delete: async (id: string): Promise<void> => {
    try {
      await apiClient.delete<void>(API_ENDPOINTS.LENS_CODE.DELETE(id));
    } catch (error) {
      throw error;
    }
  },
};
