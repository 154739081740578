/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from "react";
import { Layout, Breadcrumb, Badge, Button } from "antd";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { StyledContent, OuterHeader, InnerHeader, StyledDivider, ToggleIcon } from "../styles";
import { getParentRoute } from "utils/url-formatter";
import { Bell, SquaresFour } from "@phosphor-icons/react";
import icons from "icons";
import { routeTitles } from "utils/breadcrumbs";
import styled from "@emotion/styled";
import { ArrowLeftOutlined } from "@ant-design/icons";
import DropdownMenu from "./DropdownMenu";
import AppMenu from "./Menu";

const StyledBreadCrumb = styled(Breadcrumb)`
  &.ant-breadcrumb {
    color: rgba(0, 0, 0, 1);
  }
  &.ant-breadcrumb li:last-child {
    color: #09669a;
    font-weight: 700;
    font-size: 16px;
  }
`;

const { Sider } = Layout;

const capitalize = (word: string) => word.charAt(0).toUpperCase() + word.slice(1);

const MainLayout: React.FC = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [selectedKeys, setSelectedKeys] = useState<string>("");
  const [openKeys, setOpenKeys] = React.useState<string[]>([]);
  const navigate = useNavigate();
  const location = useLocation();
  const { Logo } = icons;

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const handleSelect = (e: any) => {
    navigate(e.key);
  };

  function handleOpenChange(openKeys: string[]) {
    setOpenKeys(openKeys);
  }

  useEffect(() => {
    const pathname = location.pathname;
    const parentRoute = getParentRoute(pathname);
    setSelectedKeys(parentRoute ?? "");

    const isConsultationOrAppointmentRoute =
      /^\/consultation\/\d+/.test(pathname) || pathname.startsWith("/appointment");
    setCollapsed(isConsultationOrAppointmentRoute);

    if (pathname.startsWith("/inventory")) {
      setOpenKeys(["/inventory"]);
    }

    if (pathname.startsWith("/master-setup")) {
      setOpenKeys(["/master-setup"]);
    }
  }, [location]);

  const generateBreadcrumbs = () => {
    const pathname = location.pathname;
    const pathSegments = pathname.split("/").filter((segment) => segment && !/\d/.test(segment));

    const getDynamicTitle = (segment: string, index: number, segments: string[]) => {
      const pathToSegment = `/${segments.slice(0, index + 1).join("/")}`;
      return routeTitles[pathToSegment] || capitalize(segment);
    };

    return (
      <StyledBreadCrumb separator=">">
        <Breadcrumb.Item>
          <SquaresFour
            size={20}
            color="#4B5563"
            weight="fill"
            style={{ cursor: "pointer" }}
            onClick={() => navigate("/")}
          />
        </Breadcrumb.Item>
        {pathSegments.map((segment, index) => {
          const title = getDynamicTitle(segment, index, pathSegments);
          const pathToSegment = `/${pathSegments.slice(0, index + 1).join("/")}`;
          return <Breadcrumb.Item key={pathToSegment}>{title || segment}</Breadcrumb.Item>;
        })}
      </StyledBreadCrumb>
    );
  };

  const shouldShowGoBackButton = () => {
    const { pathname } = location;
    return (
      pathname.includes("create") || pathname.includes("update") || pathname.includes("details")
    );
  };

  // Check if headers should be hidden and set padding-top conditionally
  const isConsultationRoute = /^\/consultation\/\d+/.test(location.pathname);

  const ConditionalStyledContent = styled(StyledContent)`
    padding-top: ${isConsultationRoute ? "0px" : "18px"};
  `;

  return (
    <Layout style={{ height: "100vh" }}>
      {!isConsultationRoute && (
        <OuterHeader
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "0 16px",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <Logo />
            <span style={{ marginLeft: "8px", fontWeight: "600", fontSize: "17px" }}>
              Kyama Vision
            </span>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Badge>
              <Bell size={24} style={{ marginRight: "16px", cursor: "pointer", color: "white" }} />
            </Badge>
            <DropdownMenu />
          </div>
        </OuterHeader>
      )}

      <Layout>
        <Sider
          trigger={null}
          collapsible
          collapsed={collapsed}
          onCollapse={toggleCollapsed}
          style={{
            background: "#fff",
            border: "1px solid #e5e7eb",
            padding: "18px 8px",
            overflowY: "auto",
            height: "100%",
          }}
          width={250}
          className="sider-scrollbar"
        >
          <AppMenu
            onSelect={handleSelect}
            selectedKeys={selectedKeys}
            openKeys={openKeys}
            onOpenChange={handleOpenChange}
          />
        </Sider>

        <Layout>
          {!isConsultationRoute && (
            <InnerHeader>
              <div style={{ display: "flex", alignItems: "center", paddingLeft: "16px" }}>
                <ToggleIcon style={{ fontSize: "18px" }} onClick={toggleCollapsed} />{" "}
                <StyledDivider type="vertical" />
                {generateBreadcrumbs()}
              </div>

              {shouldShowGoBackButton() && (
                <Button
                  onClick={() => navigate(-1)}
                  style={{ marginRight: "30px" }}
                  icon={<ArrowLeftOutlined />}
                >
                  Go Back
                </Button>
              )}
            </InnerHeader>
          )}

          <ConditionalStyledContent>
            <Outlet />
          </ConditionalStyledContent>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default MainLayout;
