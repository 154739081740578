import React from "react";
import { Form, Button, Space, Typography } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useResetPassword } from "services/ResetPasswordService/useResetPassword";
import MultiStepForm from "components/MultiStepForm";
import EmailStep from "./components/EmailStep";
import OtpStep from "./components/OtpStep";
import PasswordStep from "./components/PasswordStep";
import AuthPageLayout from "components/AuthLayout";

const ResetPasswordPage: React.FC = () => {
  const { requestOtpMutation, verifyOtpMutation, resetPasswordMutation } = useResetPassword();
  const [form] = Form.useForm();

  const steps = [
    { title: "Enter Email", content: <EmailStep /> },
    { title: "Enter OTP", content: <OtpStep /> },
    { title: "Change Password", content: <PasswordStep /> },
  ];

  const handleSubmit = async (values: any) => {
    const data = {
      email: values.email,
      otp: values.otp,
      password: values.password,
    };
    await resetPasswordMutation.mutateAsync(data);
  };

  const handleRequestOtp = async () => {
    try {
      const values = await form.validateFields(["email"]);
      const email: string = values.email;
      await requestOtpMutation.mutateAsync(email);
    } catch (error) {
      console.error(error);
    }
  };

  const handleVerifyOtp = async () => {
    try {
      const values = await form.validateFields(["email", "otp"]);
      const data = {
        email: values.email,
        otp: values.otp,
        otpType: "RESET_PASSWORD",
      };
      await verifyOtpMutation.mutateAsync(data);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <AuthPageLayout
      withBackButton={true}
      title="Forgot Password?"
      description="Please enter your email associated to your account, we'll send your an OTP in your email."
      form={
        <>
          <MultiStepForm
            form={form}
            steps={steps}
            onSubmit={handleSubmit}
            requestOtp={handleRequestOtp}
            verifyOtp={handleVerifyOtp}
            requestLoading={requestOtpMutation.isPending}
            verifyLoading={verifyOtpMutation.isPending}
          />
        </>
      }
    />
  );
};

export default ResetPasswordPage;
