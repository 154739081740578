import React from "react";
import { Card, Divider, Table } from "antd";

const BankReconciliation: React.FC = () => {
  const reportData = [
    {
      key: 1,
      sn: 1,
      bankDate: "11-10-2024",
      check: "CHK001",
      transactionDate: "10-10-2024",
      description: "Sample transaction",
      debit: "$500",
      credit: "$0",
      balance: "$500",
    },
    {
      key: 2,
      sn: 2,
      bankDate: "12-10-2024",
      check: "CHK002",
      transactionDate: "11-10-2024",
      description: "Another transaction",
      debit: "$0",
      credit: "$300",
      balance: "$200",
    },
    // Add more rows as necessary
  ];

  const columns = [
    { title: "SN", dataIndex: "sn", key: "sn" },
    { title: "Bank Date", dataIndex: "bankDate", key: "bankDate" },
    { title: "Check", dataIndex: "check", key: "check" },
    { title: "Transaction Date", dataIndex: "transactionDate", key: "transactionDate" },
    { title: "Description", dataIndex: "description", key: "description" },
    { title: "Debit$", dataIndex: "debit", key: "debit" },
    { title: "Credit$", dataIndex: "credit", key: "credit" },
    { title: "Balance", dataIndex: "balance", key: "balance" },
  ];

  return (
    <Card
      style={{
        boxShadow: "0px 8px 40px -10px #00000014",
        borderRadius: "4px",
        marginTop: "24px",
      }}
    >
      <Table columns={columns} dataSource={reportData} pagination={false} />
    </Card>
  );
};

export default BankReconciliation;
